import { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { toast } from 'react-toastify';
import {
  Stack,
  Button,
  CircularProgress,
  Checkbox,
  MenuItem,
  Typography,
  IconButton,
  FormControl,
  Select,
  OutlinedInput,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Box,
  Divider,
  FormControlLabel,
} from '@mui/material';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import Iconify from '../iconify/Iconify';
import api from 'src/services/BaseService';
import { tokenService } from 'src/services';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

CreateWorkOrderModal.propTypes = {
  openCreateModal: PropTypes.bool,
  onCreateModal: PropTypes.func,
  customer: PropTypes.object,
  locations: PropTypes.array,
  branches: PropTypes.array,
  storeLocationID: PropTypes.string,
};

export default function CreateWorkOrderModal({
  openCreateModal,
  onCreateModal,
  customer,
  locations,
  branches,
  storeLocationID,
  handleClickOpen,
}) {
  const defaultStoreLocationID = storeLocationID ? storeLocationID : null;
  const sentToEasyPoint = localStorage.getItem('easypointID');
  // const vehicles = JSON.parse(localStorage.getItem('mallsTransportationTools'));
  const [vehicles, setVehicles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedCustomer, setSelectedCustomer] = useState();
  const [selectedCustomerTitle, setSelectedCustomerTitle] = useState();
  const [selectedCar, setSelectedCar] = useState();
  const [status, setStatus] = useState(
    tokenService.getUser()?.accountCategory === 'store' ? 2 : 1
  );
  const [boxNumber, setBoxNumber] = useState();
  const [cargoList, setCargoList] = useState([]);
  const [mlDirections, setMlDirections] = useState([]);
  const [direction, setDirection] = useState();
  const [openCargoSelect, setOpenCargoSelect] = useState(false);
  const [multipleTransport, setMultipleTransport] = useState(false);
  const [from, setFrom] = useState('');
  const [to, setTo] = useState('');

  function createNewJob() {
    if (cargoList?.length === 0) {
      toast.error('Koli adedi ve kargo adedi aynı anda sıfır olmamalıdır.');
    } else {
      setLoading(true);
      const objArray = [];
      cargoList?.forEach((cargo) => {
        const cargoObj = {
          customer:
            tokenService.getUser()?.accountCategory === 'store'
              ? customer?.customer?.id
              : selectedCustomer.branchID,
          type:
            cargo.cargoName === 'Transfer/Merkez Aracı (Koli)'
              ? 'accept-goods'
              : 'post-office',
          transportationTool: selectedCar,
          orderInventory: +cargo.cargoNumber,
          direction: direction.handle,
          shipmentFirm:
            cargo.cargoName === 'Transfer/Merkez Aracı (Koli)'
              ? null
              : cargo.cargoName,
          isMultipleTransportation: multipleTransport,
          isOnlyCreateJob: true,
          status:
            direction.handle === 'StoreToMallLogistics' ||
            direction.handle === 'StoreToStore'
              ? 12
              : 5,
          from:
            tokenService.getUser()?.accountCategory === 'store'
              ? direction.handle === 'StoreToMallLogistics'
                ? defaultStoreLocationID
                : direction.handle === 'MallLogisticsToStore'
                ? sentToEasyPoint
                : from
              : from,
          to:
            tokenService.getUser()?.accountCategory === 'store'
              ? direction.handle === 'StoreToMallLogistics'
                ? sentToEasyPoint
                : direction.handle === 'MallLogisticsToStore'
                ? defaultStoreLocationID
                : to
              : to,
          sentToEasyPoint: sentToEasyPoint,
          isPhysicalBarcode: false,
        };
        objArray.push(cargoObj);
      });

      console.log('son data: ', objArray);
      api
        .post('mall-logistics/create', objArray)
        .then((response) => {
          // console.log('response: ', response.data);
          if (response.data.status) {
            setStatus([1]);
            setSelectedCar(null);
            setBoxNumber(null);
            setCargoList([]);
            setDirection(null);
            onCreateModal();
            toast.success('Başarıyla oluşturuldu.');
            window.location.reload();
          } else {
            console.log('response data: ', response.data);
            toast.error('İş emri oluşturulurken bir hata meydana geldi.');
          }
        })
        .catch((error) => {
          toast.error('İş emri oluşturulurken bir hata meydana geldi.');
          console.log(error);
        })
        .finally(() => setLoading(false));
    }
  }

  const generatePermutations = (locations) => {
    const permutations = [];
    // console.log('generatePermutations run, locations: ', locations);
    if (locations?.length >= 1) {
      for (let i = 0; i < locations?.length; i++) {
        permutations.push({
          title: `${locations[i].title} → Mal Kabule`,
          handle: 'StoreToMallLogistics',
          type: 'new',
          from: locations[i].locationID,
          to: sentToEasyPoint,
        });
        permutations.push({
          title: `Mal Kabulden → ${locations[i].title}`,
          handle: 'MallLogisticsToStore',
          type: 'new',
          from: sentToEasyPoint,
          to: locations[i].locationID,
        });
        for (let j = 0; j < locations?.length; j++) {
          if (i !== j) {
            permutations.push({
              title: `${locations[i].title} → ${locations[j].title}`,
              handle: 'StoreToStore',
              type: 'new',
              from: locations[i].locationID,
              to: locations[j].locationID,
            });
          }
        }
      }
    }
    // console.log('permutations: ', permutations);
    setMlDirections(permutations);
  };

  const handleClose = (event, reason) => {
    if (loading) {
      return;
    } else {
      if (reason && reason == 'backdropClick') {
        setStatus(1);
        setSelectedCar(null);
        setBoxNumber(null);
        setCargoList([]);
        setDirection(null);
        onCreateModal();
      }
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      event?.target?.cargonumber?.value === '' ||
      event?.target?.cargotitle?.value === ''
    ) {
      toast.info('Lütfen boş kısım bırakmayınız.');
    } else {
      let cargos = [];
      cargos = cargos.concat(cargoList);
      cargos.push({
        cargoName: event?.target?.cargotitle?.value,
        cargoNumber: event?.target?.cargonumber?.value,
      });

      setCargoList(cargos);
      setOpenCargoSelect(false);
    }
  };

  useEffect(() => {
    const _vehicles = JSON.parse(
      localStorage.getItem('mallsTransportationTools')
    );
    if (_vehicles === false) {
      const mall = localStorage.getItem('mallID');
      api
        .post('flow/malls-get-default-transportation-tools', {
          mallID: mall,
        })
        .then((res) => {
          // console.log(res.data);
          if (res.data.status) {
            setVehicles(res.data.result);
          } else {
            toast.error('İş oluşturmada bir hata meydana geldi.');
            onCreateModal();
          }
        })
        .catch((err) => {
          // console.log(err);
          toast.error('İş oluşturmada bir hata meydana geldi.');
          onCreateModal();
        });
    } else {
      setVehicles(_vehicles);
    }
    // if (
    //   customer.customer.branchID &&
    //   tokenService.getUser()?.accountCategory === 'store'
    // ) {
    //   setSelectedCustomer(customer?.customer?.branchID);
    // } else {
    //   setSelectedCustomer();
    // }
  }, []);

  return (
    <>
      <Dialog
        open={openCreateModal}
        onClose={handleClose}
        fullWidth
        maxWidth="md"
      >
        <DialogTitle>
          <Stack alignItems="center" spacing={2} sx={{ mt: 1, padding: 2.5 }}>
            <Breadcrumbs separator="›" aria-label="breadcrumb">
              {status === 1 && (
                <Link
                  sx={{ textDecoration: 'none', cursor: 'pointer' }}
                  color="inherit"
                  onClick={() => setStatus(1)}
                >
                  <Typography
                    className="my-second-step"
                    underline="hover"
                    key="1"
                    color="inherit"
                    variant={status === 1 ? 'h6' : 'p'}
                  >
                    Müşteri
                  </Typography>
                </Link>
              )}
              {status === 2 && (
                <Link
                  sx={{ textDecoration: 'none', cursor: 'pointer' }}
                  color="inherit"
                  onClick={() => setStatus(2)}
                >
                  <Typography
                    underline="hover"
                    key="2"
                    color="inherit"
                    href="/material-ui/getting-started/installation/"
                    variant={status === 2 ? 'h6' : 'p'}
                  >
                    Araç Seçiniz
                  </Typography>
                </Link>
              )}

              {/* {status === 3 && (
                <Link
                  sx={{ textDecoration: 'none', cursor: 'pointer' }}
                  color="inherit"
                  onClick={() => setStatus(3)}
                >
                  <Typography
                    key="3"
                    color="inherit"
                    variant={status === 3 ? 'h6' : 'p'}
                  >
                    Koli Adedi Giriniz
                  </Typography>
                </Link>
              )} */}

              {status === 3 && (
                <Link
                  sx={{ textDecoration: 'none', cursor: 'pointer' }}
                  color="inherit"
                  onClick={() => setStatus(4)}
                >
                  <Typography
                    key="3"
                    color="inherit"
                    variant={status === 4 ? 'h6' : 'p'}
                  >
                    Kargo Firması ve Adet Giriniz
                  </Typography>
                </Link>
              )}

              {status === 4 && (
                <Link
                  sx={{ textDecoration: 'none', cursor: 'pointer' }}
                  color="inherit"
                  onClick={() => setStatus(5)}
                >
                  <Typography
                    key="3"
                    color="inherit"
                    variant={status === 5 ? 'h6' : 'p'}
                  >
                    Yön Seçiniz
                  </Typography>
                </Link>
              )}

              {status === 5 && (
                <Typography
                  key="3"
                  color="inherit"
                  variant={status === 6 ? 'h6' : 'p'}
                >
                  Özet
                </Typography>
              )}
            </Breadcrumbs>
          </Stack>
        </DialogTitle>
        <DialogContent>
          <Stack alignItems="center" spacing={2} sx={{ mt: 1, padding: 2.5 }}>
            {status === 1 && (
              <FormControl
                sx={{ width: 300, padding: 2.5 }}
                className="my-k-step"
              >
                {tokenService.getUser()?.accountCategory === 'store' ? (
                  <Typography style={{ textAlign: 'center' }}>
                    {customer?.customer?.title}
                  </Typography>
                ) : (
                  <Select
                    displayEmpty
                    value={selectedCustomerTitle}
                    input={<OutlinedInput />}
                    onChange={(event) => {
                      // handleClickOpen();
                      setSelectedCustomerTitle(event.target.value);
                    }}
                    renderValue={(selected) => {
                      if (!selected) {
                        return <em>Müşteriler</em>;
                      }

                      return selectedCustomerTitle;
                    }}
                    MenuProps={MenuProps}
                    inputProps={{ 'aria-label': 'Without label' }}
                  >
                    {branches?.map((branch, index) => (
                      <MenuItem
                        onClick={() => {
                          setSelectedCustomerTitle(branch.title);
                          setSelectedCustomer(branch);
                          console.log('menu item tıklandı: ', branch);
                          generatePermutations(branch.locations);
                        }}
                        key={index}
                        value={branch.branchID}
                      >
                        {branch?.title}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              </FormControl>
            )}

            {status === 2 && (
              <>
                <FormControl sx={{ width: 300, padding: 2.5 }}>
                  <ToggleButtonGroup
                    color="primary"
                    size="large"
                    orientation="vertical"
                    value={selectedCar}
                    exclusive
                    onChange={(event, nextView) => {
                      setSelectedCar(nextView);
                    }}
                  >
                    {vehicles?.length > 0 &&
                      vehicles?.map((vehicle, index) => {
                        return (
                          <ToggleButton
                            key={index}
                            value={
                              vehicle === 'Koli/Kargo (Ring)' ? 'Ring' : vehicle
                            }
                            aria-label="list"
                          >
                            {vehicle}
                          </ToggleButton>
                        );
                      })}

                    {/* <ToggleButton value=" Küçük Araç" aria-label="small">
                      Küçük Araç
                    </ToggleButton>
                    <ToggleButton value=" Askılı" aria-label="quilt">
                      Askılı
                    </ToggleButton>
                    <ToggleButton value="Palet" aria-label="palet">
                      Palet
                    </ToggleButton>
                    <ToggleButton value="Ring" aria-label="ring">
                      Koli/Paket (Ring)
                    </ToggleButton> */}
                  </ToggleButtonGroup>
                </FormControl>

                <FormControlLabel
                  sx={{ marginTop: 2, justifyContent: 'center' }}
                  control={
                    <Checkbox
                      onChange={(e) => setMultipleTransport(e.target.checked)}
                    />
                  }
                  label="Çoklu Gönderim"
                />
              </>
            )}

            {/* {status === 3 && (
              <FormControl sx={{ width: 300, padding: 2.5 }}>
                <TextField
                  id="selectcargonum"
                  label="Koli Adedi"
                  type="number"
                  value={boxNumber}
                  onChange={(event) => setBoxNumber(event.target.value)}
                />
              </FormControl>
            )} */}

            {status === 3 && (
              <>
                <FormControl sx={{ width: 500, padding: 2.5 }}>
                  {cargoList &&
                    cargoList.length > 0 &&
                    cargoList.map((x, index) => (
                      <Stack
                        key={index}
                        direction="row"
                        alignItems="center"
                        justifyContent="space-between"
                      >
                        <Typography color="inherit" variant="h6">
                          {x.cargoName}
                        </Typography>

                        <Box
                          display="flex"
                          justifyContent="center"
                          alignItems="center"
                        >
                          <FormControl sx={{ width: 200, padding: 2.5 }}>
                            <TextField
                              id="outlined-number"
                              label="Adet"
                              type="number"
                              value={x?.cargoNumber}
                              onChange={(event) => {}}
                            />
                          </FormControl>

                          <Button
                            color="error"
                            onClick={() => {
                              let cargos = [];
                              cargos = cargos.concat(cargoList);
                              cargos.splice(index, 1);
                              setCargoList(cargos);
                            }}
                          >
                            Kaldır
                          </Button>
                        </Box>
                      </Stack>
                    ))}
                </FormControl>

                <IconButton
                  color="inherit"
                  size="large"
                  onClick={() => setOpenCargoSelect(true)}
                >
                  <Iconify icon={'simple-line-icons:plus'} width="50" />
                </IconButton>
                <Typography color="inherit">
                  Toplam Adet:{' '}
                  <strong>
                    {cargoList.reduce((total, x) => total + +x.cargoNumber, 0)}
                  </strong>
                </Typography>
              </>
            )}

            {status === 4 && (
              <FormControl sx={{ width: 400, padding: 2.5 }}>
                <ToggleButtonGroup
                  color="primary"
                  size="large"
                  orientation="vertical"
                  value={direction}
                  exclusive
                  onChange={(event, nextView) => {
                    setDirection(nextView);
                  }}
                >
                  {tokenService.getUser()?.accountCategory === 'mall-logistics'
                    ? mlDirections.map((x, index) => (
                        <ToggleButton
                          onClick={() => {
                            // console.log(x);
                            setFrom(x.from);
                            setTo(x.to);
                          }}
                          key={index}
                          value={x}
                          aria-label="list"
                        >
                          {x?.title}
                        </ToggleButton>
                      ))
                    : locations.map((x, index) => (
                        <ToggleButton
                          onClick={() => {
                            // console.log(x);
                            setFrom(x.from);
                            setTo(x.to);
                          }}
                          key={index}
                          value={x}
                          aria-label="list"
                        >
                          {x?.title}
                        </ToggleButton>
                      ))}
                </ToggleButtonGroup>
              </FormControl>
            )}

            {status === 5 && (
              <FormControl sx={{ width: 600, padding: 2.5 }}>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  mb={1}
                >
                  <Typography color="inherit" variant="h6">
                    Müşteri:
                  </Typography>

                  <Typography color="inherit" variant="p">
                    {customer?.customer.title}
                  </Typography>
                </Stack>
                <Divider />
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  mt={1}
                  mb={1}
                >
                  <Typography color="inherit" variant="h6">
                    Yön:
                  </Typography>

                  <Typography color="inherit" variant="p">
                    {direction?.title}
                  </Typography>
                </Stack>
                <Divider />
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  mt={1}
                  mb={1}
                >
                  <Typography color="inherit" variant="h6">
                    Taşıma Aracı:
                  </Typography>

                  <Typography color="inherit" variant="p">
                    {selectedCar === 'Ring' ? 'Koli/Paket (Ring)' : selectedCar}
                  </Typography>
                </Stack>
                <Divider />
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  mt={1}
                  mb={1}
                >
                  <Typography color="inherit" variant="h6">
                    Toplam Adet:
                  </Typography>
                  <Typography color="inherit" variant="p">
                    {cargoList.reduce(
                      (sum, cargo) =>
                        sum + (cargo.cargoNumber ? +cargo.cargoNumber : 0),
                      0
                    )}
                  </Typography>

                  {/* {cargoList && cargoList.length >= 1 ? (
                    <Typography color="inherit" variant="p">
                      {cargoList.reduce(
                        (sum, cargo) =>
                          sum + (cargo.cargoNumber ? +cargo.cargoNumber : 0),
                        0
                      ) + (boxNumber ? +boxNumber : 0)}
                    </Typography>
                  ) : (
                    <Typography color="inherit" variant="p">
                      {boxNumber ? +boxNumber : 0}
                    </Typography>
                  )} */}
                </Stack>
                {/* <Divider />
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  mt={1}
                  mb={1}
                >
                  <Typography color="inherit" variant="h6">
                    Koli Adedi:
                  </Typography>

                  <Typography color="inherit" variant="p">
                    {boxNumber}
                  </Typography>
                </Stack> */}
                <Divider />
                {cargoList.map((x, index) => (
                  <Stack
                    key={index}
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                    mt={1}
                    mb={1}
                  >
                    <div>
                      <span>{x.cargoName}</span>
                      <Typography color="inherit" variant="h6"></Typography>
                    </div>

                    <div>
                      <span>{x?.cargoNumber}</span>
                      <Typography color="inherit" variant="p"></Typography>
                    </div>
                  </Stack>
                ))}
                <Divider />

                {/* <FormControlLabel
                  sx={{ marginTop: 2, justifyContent: 'center' }}
                  control={<Checkbox defaultChecked />}
                  label="Üzerime zimmetlensin"
                /> */}
              </FormControl>
            )}
          </Stack>
        </DialogContent>
        <DialogActions sx={{ justifyContent: 'center', mb: 2 }}>
          <Button
            variant="contained"
            size="large"
            color="ochre"
            disabled={loading}
            onClick={() => {
              if (
                status === 2 &&
                tokenService.getUser()?.accountCategory === 'store'
              )
                onCreateModal();
              else if (status !== 1) setStatus(status - 1);
              else onCreateModal();
            }}
          >
            Geri Dön
          </Button>
          {status === 5 ? (
            <Button
              variant="contained"
              size="large"
              disabled={
                selectedCar === null ||
                selectedCar === '' ||
                selectedCar === undefined ||
                direction === null ||
                direction === '' ||
                direction === undefined ||
                loading
              }
              onClick={() => {
                createNewJob();
              }}
            >
              {loading ? (
                <CircularProgress size={24} color="inherit" />
              ) : (
                'Oluştur'
              )}
            </Button>
          ) : (
            <Button
              variant="contained"
              disabled={
                (tokenService.getUser()?.accountCategory === 'mall-logistics' &&
                  (selectedCustomer === null ||
                    selectedCustomer === undefined ||
                    selectedCustomer === '')) ||
                (status === 2 &&
                  (selectedCar === null ||
                    selectedCar === '' ||
                    selectedCar === undefined))
              }
              size="large"
              onClick={() => {
                // console.log('status', status);
                setStatus(status + 1);
              }}
            >
              Devam Et
            </Button>
          )}
        </DialogActions>
      </Dialog>

      <Dialog open={openCargoSelect} onClose={() => setOpenCargoSelect(false)}>
        <form onSubmit={handleSubmit}>
          <DialogTitle>Gönderi Seçin</DialogTitle>
          <DialogContent>
            <FormControl sx={{ width: 300, padding: 2.5 }}>
              <Select
                displayEmpty
                id="cargotitle"
                name="cargotitle"
                input={<OutlinedInput />}
                renderValue={(selected) => {
                  if (!selected) {
                    return <em>Gönderi Tipi</em>;
                  }

                  return selected;
                }}
                MenuProps={MenuProps}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                <MenuItem value="Transfer/Merkez Aracı (Koli)">
                  Transfer/Merkez Aracı (Koli)
                </MenuItem>
                <MenuItem value="MNG Kargo">MNG Kargo</MenuItem>
                <MenuItem value="Sürat Kargo">Sürat Kargo</MenuItem>
                <MenuItem value="Aras Kargo">Aras Kargo</MenuItem>
                <MenuItem value="PTT Kargo">PTT Kargo</MenuItem>
                <MenuItem value="UPS">UPS</MenuItem>
                <MenuItem value="Yurtiçi Kargo">Yurtiçi Kargo</MenuItem>
                <MenuItem value="Diğer">Diğer (Kargo)</MenuItem>
              </Select>
            </FormControl>

            <FormControl sx={{ width: 200, padding: 2.5 }}>
              <TextField id="cargonumber" name="cargonumber" label="Adet" />
            </FormControl>
          </DialogContent>
          <DialogActions>
            <Button type="submit">Kaydet</Button>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
