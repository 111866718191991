import {
  Button,
  Backdrop,
  CircularProgress,
  Stack,
  Typography,
  Card,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormControl,
  RadioGroup,
  Radio,
  IconButton,
  Tooltip,
  Box,
  Modal
} from '@mui/material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import api from 'src/services/BaseService';
import { saveAs } from 'file-saver';
import { Document, Packer, Paragraph, ImageRun, UnderlineType, AlignmentType, TextRun, TableRow, TableCell, WidthType, BorderStyle, Table, VerticalAlign } from "docx";
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

export default function SettingsPage() {
  const customer = useSelector((state) => state.customerReducer);
  const [branches, setBranches] = useState([]);
  const [isBackdropOpen, setIsBackdropOpen] = useState(false);
  const [fileName, setFileName] = useState("");
  const [successMessageOpen, setSuccessMessageOpen] = useState(false);
  const [checkedItems, setCheckedItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectedType, setSelectedType] = useState("");
  const [showCheckMessage, setShowCheckMessage] = useState(false);
  const [checkMessage, setCheckMessage] = useState("Bu işlemi tamamlarsanız QR Kodunuz yenilenecektir ve eski QR kodunuz kullanılmaz hale gelecektir. Bu işlem geri çevrilemez, bu işlemi tamamlamak istediğinize emin misiniz?");
  const [checkMessageTitle, setCheckMessageTitle] = useState("");
  const [showResultBox, setShowResultBox] = useState(false);
  const [refreshID, setRefreshID] = useState("");
  const [refresLoading, setRefresLoading] = useState(false);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [progress, setProgress] = useState(0);
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  useEffect(() => {
    if (showCheckMessage) {
      setIsButtonDisabled(true);
      setProgress(0);

      const timer = setInterval(() => {
        setProgress((prevProgress) => {
          if (prevProgress >= 99.99) {
            clearInterval(timer);
            setIsButtonDisabled(false);
            return 100;
          }
          return prevProgress + 3.333;
        });
      }, 100);

      return () => clearInterval(timer);
    }
    else {
      setIsButtonDisabled(true);
      setProgress(0);
    }
  }, [showCheckMessage]);

  const handleSelect = (branch) => {
    const currentIndex = checkedItems.findIndex(item => item.id === branch.id);
    const newChecked = [...checkedItems];

    if (currentIndex === -1) {
      newChecked.push(branch);
    } else {
      newChecked.splice(currentIndex, 1);
    }
    setCheckedItems(newChecked);
  };

  const handleOpenCheckMessage = (id) => {
    setShowCheckMessage(true)
    setRefreshID(id)
  }

  const renewQRCode = () => {
    setCheckedItems([])
    setRefresLoading(true)
    if (selectedType === "") {
      api
        .post('corporations/update-static-qr', {
          branchID: refreshID
        })
        .then((res) => {
          if (res.data.status) {
            setCheckMessage("QR Kodu başarılı bir şekilde yenilendi.")
            setCheckMessageTitle("Başarılı")
            fetchData()
            setRefresLoading(false)
            setShowCheckMessage(false)
            setShowResultBox(true)
          }
          else {
            setCheckMessage("QR Kod Yenilenirken bir hata oluştu.")
            setCheckMessageTitle("Hata Meydana Geldi")
            setRefresLoading(false)
            setShowCheckMessage(false)
            setShowResultBox(true)
          }
        })
        .catch((err) => {
          console.log("QR Renew ERR:", err);
        });
    }
    else {
      api
        .post('locations/update-static-qr', {
          locationID: refreshID
        })
        .then((res) => {
          if (res.data.status) {
            setCheckMessage("QR Kodu başarılı bir şekilde yenilendi.")
            setCheckMessageTitle("Başarılı")
            fetchData()
            setRefresLoading(false)
            setShowCheckMessage(false)
            setShowResultBox(true)
          }
          else {
            setCheckMessage("QR Kod Yenilenirken bir hata oluştu.")
            setCheckMessageTitle("Hata Meydana Geldi")
            setRefresLoading(false)
            setShowCheckMessage(false)
            setShowResultBox(true)
          }
        })
        .catch((err) => {
          console.log("QR Renew ERR:", err);
        });
    }
  }

  const handleSelectAll = () => {
    if (selectAll) {
      setCheckedItems([]);
    } else {
      setCheckedItems(branches);
    }
    setSelectAll(!selectAll);
  };

  useEffect(() => {
    if (checkedItems.length < branches.length) {
      setSelectAll(false);
    } else if (checkedItems.length === branches.length) {
      setSelectAll(true);
    }
  }, [checkedItems, branches]);

  const fillEmptyBranchQR = async (branch) => {
    try {
      const res = await api.post('corporations/get-static-qr', {
        branchID: branch.branchID
      });
      if (res.data.status) {

        return res.data.result;
      }
    } catch (err) {
      console.log(err);
    }
    return branch.locationStaticQR; // Eğer QR kod alınamazsa mevcut değeri döndür
  };

  const fillEmptyLocationQR = async (location) => {
    try {
      const res = await api.post('locations/get-static-qr', {
        locationID: location.locationID
      });
      if (res.data.status) {
        return res.data.result;
      }
    } catch (err) {
      console.log(err);
    }
    return location.locationStaticQR; // Eğer QR kod alınamazsa mevcut değeri döndür
  };

  const fetchData = async () => {
    if (customer) {
      let fileTitle = "";
      if (selectedType === "") {
        fileTitle = customer?.customer?.title + " Master QR Listesi.docx";
      } else if (selectedType === "store") {
        fileTitle = customer?.customer?.title + " Mağaza QR Listesi.docx";
      } else if (selectedType === "warehouse") {
        fileTitle = customer?.customer?.title + " Depo QR Listesi.docx";
      } else if (selectedType === "office") {
        fileTitle = customer?.customer?.title + " Ofis QR Listesi.docx";
      }
      setFileName(fileTitle);

      try {
        const res = await api.post('flow/get-malls-branches', {
          mallID: customer?.customer?.id,
          withLocations: true
        });


        if (res.data.status) {
          let mappedBranches = await Promise.all(
            res.data.result.map(async (x) => {
              const locationStaticQR = x.locationStaticQR || await fillEmptyBranchQR(x);
              return {
                id: x.branchID,
                title: x.title,
                locationStaticQR,
                locationID: x.locations[0]?.locationID,
                companyLegalTitle: x.companyLegalTitle,
                locations: x.locations,
              };
            })
          );

          const sortedBranches = mappedBranches.sort((a, b) => a.title.localeCompare(b.title));

          const allLocationsWithCombinedTitles = await Promise.all(
            sortedBranches.flatMap(async (item) => {
              return await Promise.all(
                item.locations.map(async (location) => {
                  const locationStaticQR = location.locationStaticQR || await fillEmptyLocationQR(location);
                  return {
                    ...location,
                    title: `${item.title} [${location.title}]`,
                    companyLegalTitle: item.companyLegalTitle,
                    locationStaticQR,
                    id: location.locationID,
                    type: location.type
                  };
                })
              );
            })
          );


          const stores = allLocationsWithCombinedTitles.flat().sort((a, b) => a.title.localeCompare(b.title)).filter(x => x.type === "store");
          const warehouses = allLocationsWithCombinedTitles.flat().sort((a, b) => a.title.localeCompare(b.title)).filter(x => x.type === "warehouse");
          const offices = allLocationsWithCombinedTitles.flat().sort((a, b) => a.title.localeCompare(b.title)).filter(x => x.type === "office");

          if (selectedType === "") {
            setBranches(sortedBranches);
          } else if (selectedType === "store") {
            setBranches(stores);
          } else if (selectedType === "warehouse") {
            setBranches(warehouses);
          } else if (selectedType === "office") {
            setBranches(offices);
          }


        } else {
          setBranches([]);
        }

      } catch (err) {
        console.log(err);
        setBranches([]);
      }
    }
  };

  useEffect(() => {
    fetchData()
  }, [customer, selectedType]);

  const exportToWord = async () => {
    setIsBackdropOpen(true);
    if (checkedItems.length === 0) {
      console.error("No branches to process.");
      return;
    }

    const paragraphs = [];
    const itemsPerPage = 3;
    const totalItems = checkedItems.length;

    for (let batchStart = 0; batchStart < totalItems; batchStart += itemsPerPage) {
      const isLastBatch = batchStart + itemsPerPage >= totalItems;
      const batchEnd = isLastBatch ? totalItems : batchStart + itemsPerPage;

      for (let index = batchStart; index < batchEnd; index++) {
        const branch = checkedItems[index];
        try {
          const response = await fetch(branch.locationStaticQR);
          if (!response.ok) {
            throw new Error(`QR Code is not ok`);
          }
          const imageBlob = await response.blob();

          // Create a table row with two cells
          const tableRow = new TableRow({
            children: [
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new ImageRun({
                        data: imageBlob,
                        transformation: {
                          width: 226.77, // 6 cm in pixels
                          height: 226.77 // 6 cm in pixels
                        }
                      })
                    ],
                    alignment: AlignmentType.CENTER,
                    spacing: {
                      after: 0,
                    },
                  })
                ],
                width: {
                  size: 45,
                  type: WidthType.PERCENTAGE,
                },
                verticalAlign: VerticalAlign.CENTER,
                borders: {
                  right: {
                    style: BorderStyle.SINGLE,
                    size: 2,
                    color: "D3D3D3", // Gray color for the divider
                  },
                  left: {
                    style: BorderStyle.NONE,
                    size: 2,
                    color: "D3D3D3", // Gray color for the divider
                  },
                  top: {
                    style: BorderStyle.NONE,
                    size: 2,
                    color: "D3D3D3", // Gray color for the divider
                  },
                  bottom: {
                    style: BorderStyle.NONE,
                    size: 2,
                    color: "D3D3D3", // Gray color for the divider
                  }
                },
              }),
              new TableCell({
                children: [
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: branch.title,
                        bold: true,
                        size: 42,
                        font: {
                          name: "Trebuchet MS",
                        },
                      })
                    ],
                    alignment: AlignmentType.CENTER,
                    spacing: {
                      after: 100, // Add space after the company legal title
                    },
                  }),
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: branch.companyLegalTitle,
                        size: 24,
                        color: "000000",
                        font: {
                          name: "Trebuchet MS",
                        },
                      }),
                    ],
                    alignment: AlignmentType.CENTER,
                    spacing: {
                      after: 600, // Add space after the company legal title
                    },
                  }),
                  new Paragraph({
                    children: [
                      new TextRun({
                        text: "panel.malllogistics.com",
                        size: 20,
                        bold: false,
                        color: "9c9c9c",
                        font: {
                          name: "Trebuchet MS",
                        },
                      }),
                    ],
                    alignment: AlignmentType.CENTER,
                    spacing: {
                      after: 600, // Add space after the website
                    },
                  }),
                ],
                width: {
                  size: 75,
                  type: WidthType.PERCENTAGE,
                },
                borders: {
                  left: {
                    style: BorderStyle.NONE,
                    size: 2,
                    color: "D3D3D3", // Gray color for the divider
                  },
                  top: {
                    style: BorderStyle.NONE,
                    size: 2,
                    color: "D3D3D3", // Gray color for the divider
                  },
                  bottom: {
                    style: BorderStyle.NONE,
                    size: 2,
                    color: "D3D3D3", // Gray color for the divider
                  },
                  right: {
                    style: BorderStyle.NONE,
                    size: 2,
                    color: "D3D3D3", // Gray color for the divider
                  }
                },
                verticalAlign: VerticalAlign.CENTER,
              }),
            ],
          });

          // Add the table row to paragraphs
          paragraphs.push(new Table({
            rows: [tableRow],
            width: {
              size: 100,
              type: WidthType.PERCENTAGE,
            },
          }));

          // Add spacing between items
          if (index < batchEnd - 1) {
            paragraphs.push(new Paragraph({
              children: [
                new TextRun({
                  text: "",
                  break: 1 // Add extra space between items
                })
              ],
              spacing: {
                after: 1200, // Add space after each item except the last on the page
              },
            }));
          }
        } catch (error) {
          // Handle errors and add them to paragraphs
          paragraphs.push(
            new Paragraph({
              children: [
                new TextRun({
                  text: branch.title + " " + error.message,
                  bold: true,
                  size: 52,
                  color: 'fc031c',
                  underline: {
                    type: UnderlineType.SINGLE,
                    color: "fc031c"
                  },
                }),
              ],
              alignment: AlignmentType.CENTER,
              spacing: {
                after: 600, // Add space after the title
              }
            }),
            new Paragraph({
              children: [
                new ImageRun({
                  data: null,
                  transformation: {
                    width: 226.77, // 6 cm in pixels
                    height: 226.77 // 6 cm in pixels
                  }
                })
              ],
              alignment: AlignmentType.CENTER,
              spacing: {
                after: 2000, // Add space after the image
              },
            }),
            new Paragraph({
              children: [
                new TextRun({
                  text: "",
                  break: 1 // Page break
                })
              ],
              spacing: {
                before: 1200, // Add space before the page break to separate items
              },
            })
          );
        }
      }

      // Add a page break after every batch of items, except the last batch
      // if (batchEnd < totalItems) {
      //   paragraphs.push(new Paragraph({
      //     children: [
      //       new TextRun({
      //         text: "",
      //         break: 1 // Page break
      //       })
      //     ],
      //     spacing: {
      //       before: 2400, // Add space before the page break to ensure separation
      //     },
      //   }));
      // }
    }

    const doc = new Document({
      sections: [
        {
          children: paragraphs
        }
      ]
    });

    Packer.toBlob(doc).then(blob => {
      saveAs(blob, fileName);
      setIsBackdropOpen(false);
      setSuccessMessageOpen(true);
    });
  };

  const groupBranchesByLetter = (branches) => {
    return branches.reduce((acc, branch) => {
      const letter = branch.title[0].toUpperCase();
      if (!acc[letter]) {
        acc[letter] = [];
      }
      acc[letter].push(branch);
      return acc;
    }, {});
  };

  const handleChangeType = (event) => {
    setSelectedType(event.target.value);
    setCheckedItems([])
    setSelectAll(false)
  };

  const groupedBranches = groupBranchesByLetter(branches);

  return (
    <>
      <Helmet>
        <title> QR İşlemleri | Mall Logistics Panel </title>
      </Helmet>

      <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={isBackdropOpen}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Stack style={{ paddingInline: '25px' }}>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            QR
          </Typography>
        </Stack>
        {branches.length > 0 ?
          <Card sx={{ padding: '15px' }}>
            <Stack
              direction="column"
              alignItems="center"
              justifyContent="center"
              paddingY={2}
              paddingX={2}
            >

              <Stack sx={{ width: '100%' }}>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="location-type"
                    name="locationType"
                    value={selectedType}
                    onChange={handleChangeType}
                    row
                  >
                    <FormControlLabel value="" control={<Radio />} label="Master QR" />
                    <FormControlLabel value="store" control={<Radio />} label="Mağaza" />
                    <FormControlLabel value="warehouse" control={<Radio />} label="Depo" />
                    <FormControlLabel value="office" control={<Radio />} label="Ofis" />
                  </RadioGroup>
                </FormControl>
              </Stack>

              <div>
                <Button
                  disabled={checkedItems.length === 0}
                  variant="contained"
                  onClick={exportToWord}
                  style={{ marginTop: '16px', maxWidth: 500, marginBottom: '16px' }}
                >
                  Seçilenleri İndir
                </Button>
                {checkedItems.length === 0 ?

                  <Tooltip title="Dosya indirme hakkında bilgi için tıklayın">
                    <IconButton
                      size="small"
                      sx={{ color: 'gray', marginLeft: '8px' }}
                      onClick={handleOpen}
                    >
                      <HelpOutlineIcon />
                    </IconButton>
                  </Tooltip>
                  :
                  <Tooltip title="Dosya indirme hakkında bilgi için tıklayın">
                    <IconButton
                      size="small"
                      sx={{
                        color: 'gray',
                        marginLeft: '8px',
                        animation: 'pulse 2s infinite',
                        '@keyframes pulse': {
                          '0%': {
                            transform: 'scale(1)',
                            color: 'gray',
                          },
                          '50%': {
                            transform: 'scale(1.1)',
                            color: '#088AB2', // Açık mavi
                          },
                          '100%': {
                            transform: 'scale(1)',
                            color: 'gray',
                          },
                        },
                      }}
                      onClick={handleOpen}
                    >
                      <HelpOutlineIcon />
                    </IconButton>
                  </Tooltip>
                }

              </div>
              <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-title"
                aria-describedby="modal-description"
              >
                <Box
                  sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 800,
                    bgcolor: 'background.paper',
                    borderRadius: '10px',
                    boxShadow: 24,
                    p: 4,
                    justifyContent: 'flex-end',
                    display: 'flex',
                    flexDirection: 'column'
                  }}
                >
                  <h3 id="modal-title">Dosya İndirme Uyarısı</h3>
                  <img
                    src={require('../../src/assets/images/popUpError.png')}
                    alt="Pop-up Error"
                    style={{
                      display: 'block',
                      margin: '20px auto',
                      maxWidth: '100%',
                      height: 'auto',
                    }}
                  />
                  <p id="modal-description">
                    Eğer tarayıcınızda görseldeki işareti görüyorsanız, gösterilen ikona tıklayarak tarayıcınızdan, bu sayfa için <b>"Pop-up'lar ve yönlendirmeler"</b> seçeneğine izin vermeniz gerekmektedir. Aksi durumda dosya indirme işlemlerinize engel olacaktır.
                  </p>
                  <Button onClick={handleClose} variant="contained" color="primary">
                    Tamam
                  </Button>
                </Box>
              </Modal>


              <FormGroup sx={{ width: '100%' }}>
                <Grid container spacing={2}>
                  {Object.keys(groupedBranches).length > 0 &&
                    <Grid item xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={selectAll}
                            onChange={handleSelectAll}
                            color="primary"
                          />
                        }
                        label="Tümünü Seç"
                      />
                    </Grid>
                  }

                  {Object.keys(groupedBranches).map((letter) => (
                    <Grid item xs={12} key={letter}>
                      <Typography variant="h6" gutterBottom>
                        {letter}
                      </Typography>
                      <Grid container spacing={2} >
                        {groupedBranches[letter].map((branch) => (
                          <Grid item xs={12} sm={6} md={3} key={branch.id}>
                            <FormControlLabel
                              sx={{
                                border: '1px solid #ccc',
                                borderRadius: '4px',
                                padding: '8px',
                                margin: '8px 0',
                              }}
                              control={
                                <Checkbox
                                  checked={checkedItems.some(item => item.id === branch.id)}
                                  onChange={() => handleSelect(branch)}
                                  color="primary"
                                />
                              }
                              label={
                                <div style={{ display: 'flex', alignItems: 'center', alignSelf: 'flex-end' }}>
                                  {branch.title}
                                  <Tooltip title="QR yenilemek için tıklayın">
                                    <IconButton onClick={() => handleOpenCheckMessage(branch.id)} aria-hidden="false" aria-label="refresh">
                                      <RefreshIcon />
                                    </IconButton>
                                  </Tooltip>
                                </div>
                              }
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </Grid>
                  ))}
                </Grid>
              </FormGroup>
              <Dialog open={successMessageOpen}>
                <DialogTitle>Başarılı</DialogTitle>
                <DialogContent>
                  <Typography>İndirme işlemi başarıyla tamamlandı.</Typography>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      setSuccessMessageOpen(false);
                    }}
                    color="primary"
                  >
                    Tamam
                  </Button>
                </DialogActions>
              </Dialog>

              <Dialog open={showCheckMessage}>
                <DialogTitle sx={{ color: 'red' }}>Uyarı</DialogTitle>
                {!refresLoading ?
                  <DialogContent>
                    <Typography>Bu işlemi tamamlarsanız QR Kodunuz yenilenecektir ve eski QR kodunuz kullanılmaz hale gelecektir. Bu işlem geri çevrilemez, bu işlemi tamamlamak istediğinize emin misiniz?</Typography>
                  </DialogContent> :
                  <DialogContent>
                    <DialogContent>
                      <Typography>Bu işlemi tamamlarsanız QR Kodunuz yenilenecektir ve eski QR kodunuz kullanılmaz hale gelecektir. Bu işlem geri çevrilemez, bu işlemi tamamlamak istediğinize emin misiniz?</Typography>
                    </DialogContent>
                    <Backdrop sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }} open={refresLoading}>
                      <CircularProgress color="inherit" />
                    </Backdrop>
                  </DialogContent>
                }

                <DialogActions>
                  <Button
                    onClick={() => {
                      setShowCheckMessage(false);
                      setRefreshID("")
                    }}
                    variant="contained"
                    color='error'
                    sx={{ backgroundColor: '#DC423C', height: '35px' }}
                  >
                    Hayır
                  </Button>

                  <Button
                    onClick={() => {
                      renewQRCode(refreshID);
                    }}
                    variant="contained"
                    color='success'
                    sx={{ color: 'white', backgroundColor: '#009427', height: '35px' }}
                    disabled={isButtonDisabled}
                  >
                    {isButtonDisabled ? (
                      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
                        <CircularProgress
                          variant="determinate"
                          value={progress}
                          sx={{ color: 'darkgray' }}
                          size={18}
                        />
                      </Box>
                    ) : (
                      "Evet"
                    )}
                  </Button>
                </DialogActions>
              </Dialog>

              <Dialog open={showResultBox}>
                <DialogTitle>{checkMessageTitle}</DialogTitle>
                <DialogContent>
                  <Typography>{checkMessage}</Typography>
                </DialogContent>
                <DialogActions>
                  <Button
                    onClick={() => {
                      setShowResultBox(false)
                    }}
                    color="primary"
                  >
                    Tamam
                  </Button>
                </DialogActions>
              </Dialog>
            </Stack>
          </Card> :
          <Card sx={{ padding: '15px' }}>
            <Stack
              direction="column"
              alignItems="center"
              justifyContent="center"
              paddingY={2}
              paddingX={2}
            >

              <Stack sx={{ width: '100%' }}>
                <FormControl component="fieldset">
                  <RadioGroup
                    aria-label="location-type"
                    name="locationType"
                    value={selectedType}
                    onChange={handleChangeType}
                    row
                  >
                    <FormControlLabel value="" control={<Radio />} label="Master QR" />
                    <FormControlLabel value="store" control={<Radio />} label="Mağaza" />
                    <FormControlLabel value="warehouse" control={<Radio />} label="Depo" />
                    <FormControlLabel value="office" control={<Radio />} label="Ofis" />
                  </RadioGroup>
                </FormControl>
              </Stack>
              <Typography>Listelenecek veri bulunamadı.</Typography>
            </Stack>
          </Card>
        }
      </Stack >
    </>
  );
}
