import {
  Button,
  Container,
  Stack,
  Typography,
  OutlinedInput,
  Select,
  MenuItem,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { useSelector } from 'react-redux';
import api from 'src/services/BaseService';
import { saveAs } from 'file-saver';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function StoreQr() {
  const customer = useSelector((state) => state.customerReducer);
  const [location, setLocation] = useState();
  const [locations, setLocations] = useState([]);
  const [qr, setQr] = useState('');

  const fetchQr = (locationID) => {
    api
      .post('locations/get-static-qr', {
        locationID: locationID,
      })
      .then((res) => {
        // console.log(res.data);
        if (res.data.status) {
          setQr(res.data.result);
        } else {
          setQr('');
        }
      })
      .catch((err) => {
        // console.log(err);
        setQr('');
      });
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    // console.log('value: ', value);

    setLocation({
      title: value.title,
      locationID: value.locationID,
    });

    fetchQr(value.locationID);
  };

  const handleDownload = () => {
    saveAs(qr, `${location.title}_qr.jpg`);
  };

  useEffect(() => {
    // console.log('customer: ', customer.customer);
    if (customer) {
      api
        .post('locations/get-locations', {
          branchID: customer.customer?.id,
        })
        .then((res) => {
          // console.log(res.data);
          if (res.data.status) {
            const mappedLocations = res.data.result.map((x) => ({
              title: x.title,
              locationID: x.locationID,
            }));
            setLocations(mappedLocations);
            if (res.data.result.length === 1) {
              setLocation({
                title: res.data.result[0].title,
                locationID: res.data.result[0].locationID,
              });

              fetchQr(res.data.result[0].locationID);
            }
          } else {
            setLocations([]);
          }
        })
        .catch((err) => {
          // console.log(err);
          setLocations([]);
        });
    }
  }, [customer]);

  return (
    <>
      <Helmet>
        <title> Mağaza QR | Mall Logistics Panel </title>
      </Helmet>

      <Stack style={{ paddingInline: '25px' }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            QR
          </Typography>
        </Stack>

        {locations?.length > 1 && (
          <Select
            sx={{
              width: 500,
              marginRight: '8px',
              backgroundColor: 'white',
            }}
            displayEmpty
            value={location}
            input={<OutlinedInput />}
            onChange={handleChange}
            renderValue={(selected) => {
              return selected?.title || 'Lokasyon seçiniz';
            }}
            MenuProps={MenuProps}
            inputProps={{ 'aria-label': 'Without label' }}
          >
            <MenuItem value="" disabled>
              Lokasyon seçiniz
            </MenuItem>
            {locations?.map((x, index) => (
              <MenuItem key={index} value={x}>
                {x?.title}
              </MenuItem>
            ))}
          </Select>
        )}

        {locations?.length === 1 && (
          <Typography>
            <strong>{location?.title}</strong> QR Kodu
          </Typography>
        )}

        {qr && (
          <>
            <img
              src={qr}
              alt="QR Code"
              style={{ width: '500px', height: '500px', marginTop: '16px' }}
            />
            <Button
              variant="contained"
              onClick={handleDownload}
              style={{ marginTop: '16px', maxWidth: 500 }}
            >
              İndir
            </Button>
          </>
        )}
      </Stack>
    </>
  );
}
