import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// @mui
import { styled, alpha } from '@mui/material/styles';
import {
  Box,
  Link,
  Drawer,
  Typography,
 
} from '@mui/material';
// mock
// hooks
import useResponsive from '../../../hooks/useResponsive';
// components
import Logo from '../../../components/logo';
import Scrollbar from '../../../components/scrollbar';
import NavSection from '../../../components/nav-section';
//
import navConfig from './config';
import { tokenService } from '../../../services';

// ----------------------------------------------------------------------

const NAV_WIDTH = 280;

const StyledAccount = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: alpha(theme.palette.grey[500], 0.12),
}));

// ----------------------------------------------------------------------

Nav.propTypes = {
  openNav: PropTypes.bool,
  onCloseNav: PropTypes.func,
};

export default function Nav({ openNav, onCloseNav }) {
  const { pathname } = useLocation();

  const isDesktop = useResponsive('up', 'lg');

  useEffect(() => {
    if (openNav) {
      onCloseNav();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar
      sx={{
        '& .simplebar-content': {
          display: 'flex',
          flexDirection: 'column',
          background: '#172955',
          minHeight: '100vh',
        },
      }}
    >
      <Box
        sx={{
          px: 2.5,
          py: 3,
          display: 'inline-flex',
          justifyContent: 'center',
          width:'100%'
        }}
      >
        <Logo />
      </Box>

      <div
        style={{
          width: '85%',
          display: 'flex',
          alignSelf: 'center',
          height: 1,
          backgroundColor: '#3D60B2',
          marginBottom: 20,
        }}
      />

      <Box sx={{ mb: 5, mx: 2.5, borderRadius: '5px', background: '#263F78' }}>
        <Link underline="none">
          <StyledAccount>
            <Box>
              <Typography
                variant="subtitle2"
                sx={{ color: '#B6C1F1', fontSize: 12 }}
              >
                {tokenService.getUser()?.name}{' '}
                {tokenService.getUser()?.lastname}
              </Typography>
            </Box>
          </StyledAccount>
        </Link>
      </Box>

      <Typography
        sx={{
          color: '#98ABEA',
          fontSize: 12,
          marginLeft: '20px',
          fontWeight: '600',
          marginBottom: '10px',
        }}
      >
        {tokenService.getUser()?.accountCategory === 'mall-logistics'
          ? 'MALL LOGISTICS'
          : tokenService.getUser()?.accountCategory === 'mall'
          ? 'YÖNETİM'
          : 'MAĞAZA'}
      </Typography>

      <NavSection data={navConfig} />

      <Box sx={{ flexGrow: 1 }} />
    </Scrollbar>
  );

  return (
    <Box
      component="nav"
      sx={{
        flexShrink: { lg: 0 },
        width: { lg: NAV_WIDTH },
      }}
    >
      {isDesktop ? (
        <Drawer
          open
          variant="permanent"
          PaperProps={{
            sx: {
              width: NAV_WIDTH,
              bgcolor: '#172955',
              borderRightStyle: 'dashed',
            },
          }}
        >
          {renderContent}
        </Drawer>
      ) : (
        <Drawer
          open={openNav}
          onClose={onCloseNav}
          ModalProps={{
            keepMounted: true,
          }}
          PaperProps={{
            sx: { 
              width: NAV_WIDTH ,
              bgcolor: '#172955',
              borderRightStyle: 'dashed',
            },
  
          }}
        >
          {renderContent}
        </Drawer>
      )}
    </Box>
  );
}
