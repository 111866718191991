// @mui
import PropTypes from 'prop-types';
import { alpha, styled } from '@mui/material/styles';
import { Card, Typography, Paper, Stack } from '@mui/material';
// utils
import { fShortenNumber } from '../../../utils/formatNumber';
// components
import Iconify from '../../../components/iconify';

// ----------------------------------------------------------------------

const StyledIcon = styled('div')(({ theme }) => ({
  display: 'flex',
  borderRadius: '5px',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}));

// ----------------------------------------------------------------------

AppAllDatas.propTypes = {
  color: PropTypes.string,
  icon: PropTypes.string,
  title: PropTypes.string.isRequired,
  sx: PropTypes.object,
  state: PropTypes.string,
  comparison: PropTypes.string,
  open: PropTypes.number,
  openCount: PropTypes.number,
  porter: PropTypes.number,
  porterCount: PropTypes.number,
  completed: PropTypes.number,
  completedCount: PropTypes.number,
};

export default function AppAllDatas({
  title,
  icon,
  color,
  sx,
  state,
  comparison,
  open,
  openCount,
  porter,
  porterCount,
  completed,
  completedCount,
  ...other
}) {
  return (
    <Paper
      elevation={3}
      sx={{
        display: 'flex',
        alignItems: 'center',
        height: 400,
        justifyContent: 'space-evenly',
        textAlign: 'center',
        ...sx,
      }}
      {...other}
    >
      <Stack
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {' '}
        <StyledIcon
          sx={{
            color: 'white',
            backgroundImage: (theme) =>
              `linear-gradient(135deg, #F7A937 0%, #ED9C27 100%)`,
          }}
        >
          <Iconify
            icon={'eva:diagonal-arrow-right-up-outline'}
            width={48}
            height={48}
          />
        </StyledIcon>
        <Typography sx={{ fontSize: 18, color: '#344767' }}>
          Açıkta Bekleyen İşlemler
        </Typography>
        <Typography sx={{ fontSize: 32, color: '#7B809A', mt: 1 }}>
          {open}
        </Typography>
        <Typography sx={{ fontSize: 12, color: '#344767', mt: 2 }}>
          Aktif Paket Sayısı: {openCount}
        </Typography>
      </Stack>
      <Stack
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {' '}
        <StyledIcon
          sx={{
            color: 'white',
            backgroundImage: (theme) =>
              `linear-gradient(135deg, #203B80 0%, #3A5EB9 100%)`,
          }}
        >
          <Iconify
            icon={'iconamoon:shopping-bag-light'}
            width={36}
            height={36}
          />
        </StyledIcon>
        <Typography sx={{ fontSize: 18, color: '#344767' }}>
          Porter Üzerinde İşlemler
        </Typography>
        <Typography sx={{ fontSize: 32, color: '#7B809A', mt: 1 }}>
          {porter}
        </Typography>
        <Typography sx={{ fontSize: 12, color: '#344767', mt: 2 }}>
          Aktif Paket Sayısı: {porterCount}
        </Typography>
      </Stack>
      <Stack
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        {' '}
        <StyledIcon
          sx={{
            color: 'white',
            backgroundImage: (theme) =>
              `linear-gradient(135deg, #318CA4 0%, #175F72 100%)`,
          }}
        >
          <Iconify
            icon={'icon-park-outline:check-one'}
            width={36}
            height={36}
          />
        </StyledIcon>
        <Typography sx={{ fontSize: 18, color: '#344767' }}>
          Teslim Edilen İşlemler
        </Typography>
        <Typography sx={{ fontSize: 32, color: '#7B809A', mt: 1 }}>
          {completed}
        </Typography>
        <Typography sx={{ fontSize: 12, color: '#344767', mt: 2 }}>
          Aktif Paket Sayısı: {completedCount}
        </Typography>
      </Stack>
    </Paper>
  );
}
