import React, { useState, useEffect } from 'react';
import {
  Backdrop,
  CircularProgress,
  FormControl,
  Typography,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  FormControlLabel,
  FormLabel,
  Stack,
  Grid,
} from '@mui/material';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Iconify from '../iconify/Iconify';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Switch from '@mui/material/Switch';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import api from 'src/services/BaseService';

const AddStoreModal = ({ open, onClose, isEdit, editData, mallList }) => {
  const [showResultMessage, setShowResultMessage] = useState(false);
  const [resultMessage, setResultMessage] = useState('');
  const [isBackdropOpen, setIsBackdropOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [selectedOption, setSelectedOption] = useState('new');
  const [userList, setUserList] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');

  const [locationName, setLocationName] = useState('');
  const [locationAddress, setLocationAddress] = useState('');
  const [locationType, setLocationType] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const [name, setName] = useState('');
  const [lastname, setLastname] = useState('');
  const [userName, setUserName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [password, setPassword] = useState('');

  const [formData, setFormData] = useState({
    mallID: '',
    title: '',
    companyLegalTitle: '',
    companyCorporationalCode: '',
    taxNo: '',
    taxAdministration: '',
    address: '',
    city: '',
    region: '',
    country: 'Türkiye',
    isPrimary: false,
    // isHandsfree: false
  });

  const typeList = [
    { name: 'Mağaza', value: 'store' },
    { name: 'Depo', value: 'warehouse' },
  ];

  const cityList = [
    { id: 1, name: 'Adana' },
    { id: 2, name: 'Adıyaman' },
    { id: 3, name: 'Afyonkarahisar' },
    { id: 4, name: 'Ağrı' },
    { id: 5, name: 'Amasya' },
    { id: 6, name: 'Ankara' },
    { id: 7, name: 'Antalya' },
    { id: 8, name: 'Artvin' },
    { id: 9, name: 'Aydın' },
    { id: 10, name: 'Balıkesir' },
    { id: 11, name: 'Bilecik' },
    { id: 12, name: 'Bingöl' },
    { id: 13, name: 'Bitlis' },
    { id: 14, name: 'Bolu' },
    { id: 15, name: 'Burdur' },
    { id: 16, name: 'Bursa' },
    { id: 17, name: 'Çanakkale' },
    { id: 18, name: 'Çankırı' },
    { id: 19, name: 'Çorum' },
    { id: 20, name: 'Denizli' },
    { id: 21, name: 'Diyarbakır' },
    { id: 22, name: 'Edirne' },
    { id: 23, name: 'Elazığ' },
    { id: 24, name: 'Erzincan' },
    { id: 25, name: 'Erzurum' },
    { id: 26, name: 'Eskişehir' },
    { id: 27, name: 'Gaziantep' },
    { id: 28, name: 'Giresun' },
    { id: 29, name: 'Gümüşhane' },
    { id: 30, name: 'Hakkari' },
    { id: 31, name: 'Hatay' },
    { id: 32, name: 'Isparta' },
    { id: 33, name: 'Mersin' },
    { id: 34, name: 'İstanbul' },
    { id: 35, name: 'İzmir' },
    { id: 36, name: 'Kars' },
    { id: 37, name: 'Kastamonu' },
    { id: 38, name: 'Kayseri' },
    { id: 39, name: 'Kırklareli' },
    { id: 40, name: 'Kırşehir' },
    { id: 41, name: 'Kocaeli' },
    { id: 42, name: 'Konya' },
    { id: 43, name: 'Kütahya' },
    { id: 44, name: 'Malatya' },
    { id: 45, name: 'Manisa' },
    { id: 46, name: 'Kahramanmaraş' },
    { id: 47, name: 'Mardin' },
    { id: 48, name: 'Muğla' },
    { id: 49, name: 'Muş' },
    { id: 50, name: 'Nevşehir' },
    { id: 51, name: 'Niğde' },
    { id: 52, name: 'Ordu' },
    { id: 53, name: 'Rize' },
    { id: 54, name: 'Sakarya' },
    { id: 55, name: 'Samsun' },
    { id: 56, name: 'Siirt' },
    { id: 57, name: 'Sinop' },
    { id: 58, name: 'Sivas' },
    { id: 59, name: 'Tekirdağ' },
    { id: 60, name: 'Tokat' },
    { id: 61, name: 'Trabzon' },
    { id: 62, name: 'Tunceli' },
    { id: 63, name: 'Şanlıurfa' },
    { id: 64, name: 'Uşak' },
    { id: 65, name: 'Van' },
    { id: 66, name: 'Yozgat' },
    { id: 67, name: 'Zonguldak' },
    { id: 68, name: 'Aksaray' },
    { id: 69, name: 'Bayburt' },
    { id: 70, name: 'Karaman' },
    { id: 71, name: 'Kırıkkale' },
    { id: 72, name: 'Batman' },
    { id: 73, name: 'Şırnak' },
    { id: 74, name: 'Bartın' },
    { id: 75, name: 'Ardahan' },
    { id: 76, name: 'Iğdır' },
    { id: 77, name: 'Yalova' },
    { id: 78, name: 'Karabük' },
    { id: 79, name: 'Kilis' },
    { id: 80, name: 'Osmaniye' },
    { id: 81, name: 'Düzce' },
  ];

  const steps = [
    'Mağaza Bilgileri',
    'Lokasyon Bilgileri',
    'Kullanıcı Bilgileri',
  ];

  const handleTabChange = (event, newValue) => {
    setSelectedOption(newValue);
    setName('');
    setLastname('');
    setUserName('');
    setEmail('');
    setPhone('');
    setPassword('');
    setSelectedUser('');
  };

  const nextStep = () => {
    if (activeStep !== 2) {
      setActiveStep(activeStep + 1);
    }
  };

  const backStep = () => {
    if (activeStep !== 0) {
      setActiveStep(activeStep - 1);
    }
  };

  const updateUser = (branchID, selectedUser) => {
    const branchIDs = selectedUser.authLocations.branch.map(
      (item) => item.branchID
    );
    branchIDs.push(branchID);
    api
      .post('/users/update-profile', {
        userID: selectedUser.id,
        frontAuth: ['operations', 'manager'],
        isAdministrator: '1',
        authLocations: {
          branch: branchIDs,
        },
      })
      .then((response) => {
        if (response.data.status) {
          setResultMessage('Mağaza başarıyla eklendi!');
          setShowResultMessage(true);
          setIsBackdropOpen(false);
        } else {
          setResultMessage('Mağaza eklenirken bir sorunla karşılaşıldı!');
          setShowResultMessage(true);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const createUser = (branchID) => {
    if (selectedOption === 'new') {
      api
        .post('/flow/user-create', {
          mallID: formData.mallID,
          corporationID: '',
          branchID: branchID,
          email: email,
          phone: phone,
          name: name,
          lastname: lastname,
          password: password,
          username: userName,
          type: 'corporational',
          isAdministrator: true,
          accountCategory: 'store',
          frontAuth: ['operations', 'manager'],
          authLocations: {
            branch: [branchID],
            mallID: [formData.mallID]
          },
        })
        .then((response) => {
          if (response.data.status) {
            createLocation(branchID, response.data.result);
          } else {
            setResultMessage('Mağaza eklenirken bir sorunla karşılaşıldı!');
            setShowResultMessage(true);
          }
        })
    }
  };

  const createLocation = (branchID, selectedUser) => {
    if (selectedOption === 'new') {
      api
        .post('/flow/location-create', {
          title: locationName,
          mallID: formData.mallID,
          corporationID: '',
          branchID: branchID,
          type: locationType,
          adminUserID: selectedUser,
          address: locationAddress,
        })
        .then((response) => {
          if (response.data.status) {
            setResultMessage('Mağaza başarıyla eklendi!');
            setShowResultMessage(true);
            setIsBackdropOpen(false);
          } else {
            setResultMessage('Mağaza eklenirken bir sorunla karşılaşıldı!');
            setShowResultMessage(true);
          }
        })
    } else if (selectedOption === 'existing') {
      api
        .post('/flow/location-create', {
          title: locationName,
          mallID: formData.mallID,
          corporationID: '',
          branchID: branchID,
          type: locationType,
          adminUserID: selectedUser.id,
          address: locationAddress,
        })
        .then((response) => {
          if (response.data.status) {
            updateUser(branchID, selectedUser);
            setResultMessage('Mağaza başarıyla eklendi!');
          } else {
            setResultMessage('Mağaza eklenirken bir sorunla karşılaşıldı!');
            setShowResultMessage(true);
          }
        })
    } else if (selectedOption === 'proceed') {
      api
        .post('/flow/location-create', {
          title: locationName,
          mallID: formData.mallID,
          corporationID: '',
          branchID: branchID,
          type: locationType,
          adminUserID: '',
          address: locationAddress,
        })
        .then((response) => {
          if (response.data.status) {
            setResultMessage('Mağaza başarıyla eklendi!');
            setShowResultMessage(true);
            setIsBackdropOpen(false);
          } else {
            setResultMessage('Mağaza eklenirken bir sorunla karşılaşıldı!');
            setShowResultMessage(true);
          }
        })
    }
  };

  const handleSave = () => {
    setIsBackdropOpen(true);
    let isPrimaryBoolean;
    if (formData.isPrimary === false) {
      isPrimaryBoolean = '0';
    } else {
      isPrimaryBoolean = '1';
    }
    if (selectedOption === 'new') {
      api
        .post('/flow/branch-create', {
          title: formData.title,
          mallID: formData.mallID,
          corporationID: '',
          type: 'corporational',
          companyLegalTitle: formData.companyLegalTitle,
          billing: {
            status: true,
            type: 'corporational',
            taxNo: formData.taxNo,
            taxAdministration: formData.taxAdministration,
            address: formData.address,
            region: formData.region,
            city: formData.city,
            country: formData.country,
          },
          companyCorporationalCode: formData.companyCorporationalCode,
          isPrimary: isPrimaryBoolean,
          // isHandsfree: formData.isHandsfree
        })
        .then((response) => {
          if (response.data.status) {
            // console.log(response.data, "Add Branch Result")
            createUser(response.data.result);
          } else {
            setResultMessage('Mağaza eklenirken bir sorunla karşılaşıldı!');
            setShowResultMessage(true);
            // toast.error(response.data.message);
          }
        })
        .catch((error) => {
          // console.log(error);
          // toast.error(error.message);
        });
    } else if (selectedOption === 'existing') {
    
      api
        .post('/flow/branch-create', {
          title: formData.title,
          mallID: formData.mallID,
          corporationID: '',
          type: 'corporational',
          companyLegalTitle: formData.companyLegalTitle,
          billing: {
            status: true,
            type: 'corporational',
            taxNo: formData.taxNo,
            taxAdministration: formData.taxAdministration,
            address: formData.address,
            region: formData.region,
            city: formData.city,
            country: formData.country,
          },
          companyCorporationalCode: formData.companyCorporationalCode,
          isPrimary: isPrimaryBoolean,
          // isHandsfree: formData.isHandsfree
        })
        .then((response) => {
          if (response.data.status) {
            createLocation(response.data.result, selectedUser);
          } else {
            setResultMessage('Mağaza eklenirken bir sorunla karşılaşıldı!');
            setShowResultMessage(true);
          }
        })
    } else if (selectedOption === 'proceed') {
      api
        .post('/flow/branch-create', {
          title: formData.title,
          mallID: formData.mallID,
          corporationID: '',
          type: 'corporational',
          companyLegalTitle: formData.companyLegalTitle,
          billing: {
            status: true,
            type: 'corporational',
            taxNo: formData.taxNo,
            taxAdministration: formData.taxAdministration,
            address: formData.address,
            region: formData.region,
            city: formData.city,
            country: formData.country,
          },
          companyCorporationalCode: formData.companyCorporationalCode,
          isPrimary: isPrimaryBoolean,
          // isHandsfree: formData.isHandsfree
        })
        .then((response) => {
          if (response.data.status) {
            createLocation(response.data.result, '');
          } else {
            setResultMessage('Mağaza eklenirken bir sorunla karşılaşıldı!');
            setShowResultMessage(true);
          }
        })
    }
  };

  const allFieldsAreValid = () => {
    if (activeStep === 0) {
      if (
        formData.title !== '' &&
        formData.companyLegalTitle !== '' &&
        formData.taxNo !== ''
      ) {
        return true;
      } else return false;
    } else if (activeStep === 1) {
      if (
        formData.mallID !== '' &&
        locationName !== '' &&
        locationType !== ''
      ) {
        return true;
      } else return false;
    } else if (activeStep === 2 && selectedOption === 'proceed') {
      return true;
    } else if (activeStep === 2 && selectedOption === 'new') {
      if (
        name !== '' &&
        lastname !== '' &&
        userName !== '' &&
        email !== '' &&
        phone !== '' &&
        password !== ''
      ) {
        return true;
      } else return false;
    } else if (activeStep === 2 && selectedOption === 'existing') {
      if (
        name !== '' &&
        lastname !== '' &&
        userName !== '' &&
        email !== '' &&
        phone !== ''
      ) {
        return true;
      } else return false;
    }
  };

  const handleMallChange = async (event) => {
    const { value } = event.target;
    getUserList(value);
    setFormData((prevState) => ({
      ...prevState,
      mallID: value,
    }));
  };

  const handleCityChange = (event) => {
    const { value } = event.target;
    setFormData((prevState) => ({
      ...prevState,
      city: value,
    }));
  };

  const handleSwitchChange = () => {
    const { name, value } = event.target;
    if (name === 'isPrimary') {
      setFormData((prevState) => ({
        ...prevState,
        [name]: !formData.isPrimary, // Değerin tersine çevrilmesi
      }));
    }
    // else if (name === 'isHandsfree') {
    //   setFormData((prevState) => ({
    //     ...prevState,
    //     [name]: !formData.isHandsfree, // Değerin tersine çevrilmesi
    //   }));
    // }
    else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  const handleTextFieldChange = (e) => {
    const { name, value } = e.target;
    if (name === 'taxNo') {
      if (/^\d*$/.test(value)) {
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    } else if (name === 'companyCorporationalCode') {
      if (/^[0-9.,]*$/.test(value)) {
        setFormData({
          ...formData,
          [name]: value,
        });
      }
    } else {
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const handleClose = () => {
    setFormData({});
    onClose();
  };

  const handleCloseMessage = () => {
    setShowResultMessage(false);
    setFormData({});
    onClose();
  };

  const handleChangeSelectedUser = (e) => {
    const { value } = e.target;
    setSelectedUser(value);
    setName(value.name);
    setLastname(value.lastname);
    setUserName(value.username);
    setEmail(value.email);
    setPhone(value.phone);
  };

  const getUserList = (mallID) => {
    api
      .post('users/get-users', {
        type: 'corporational',
        mallID: mallID,
        status: '1',
      })
      .then((response) => {
        if (response.data.status) {
          setUserList(response.data.result);
        } 
      })
  };

  useEffect(() => {
    if (isEdit) {
      console.log(editData)
      let isPrimaryBoolean;
      if (editData.isPrimary === '1') {
        isPrimaryBoolean = true;
      } else {
        isPrimaryBoolean = false;
      }
      setLocationAddress(editData.locationAddress);
      setLocationName(editData.locations[0].title);
      getUserList(editData.mallID);
      setFormData({
        mallID: editData.mallID,
        title: editData.title,
        companyLegalTitle: editData.companyLegalTitle,
        companyCorporationalCode: editData.companyCorporationalCode,
        taxNo: editData.billing?.taxNo,
        taxAdministration: editData.billing?.taxAdministration,
        address: editData.billing?.address,
        city: editData.billing?.city,
        region: editData.billing?.region,
        country: editData.billing?.country,
        isPrimary: isPrimaryBoolean,
      });
    }
  }, []);

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <Backdrop sx={{ color: '#fff', zIndex: 2 }} open={isBackdropOpen}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <Stack
          width="95%"
          direction="column"
          alignItems="flex-end"
          justifyContent="flex-end"
          marginTop={3}
          mb={5}
        >
          <div
            style={{
              flexDirection: 'row',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              marginTop: 10,
            }}
          >
            <Typography>Öncelikli Mağaza</Typography>
            <Switch
              checked={formData.isPrimary}
              onChange={handleSwitchChange}
              name="isPrimary"
              color="primary"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </div>
          {/* <div
            style={{
              flexDirection: 'row',
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
              marginTop: 10,
            }}
          >
            <Typography>Handsfree</Typography>
            <Switch
              checked={formData.isHandsfree}
              onChange={handleSwitchChange}
              name="isHandsfree"
              color="primary"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </div> */}
        </Stack>
        <Stack
          direction="column"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Mağaza Ekleme Formu
          </Typography>
          <Typography gutterBottom>
            Lütfen listeye eklemek istediğiniz mağaza bilgilerini giriniz.
          </Typography>
        </Stack>

        <Box sx={{ width: '100%' }}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </Box>
        {activeStep === 0 && (
          <DialogContent>
            <>
              <Grid xs={16} container alignItems="center" spacing={2}>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    label="Mağaza Adı*"
                    name="title"
                    value={formData.title}
                    onChange={handleTextFieldChange}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    label="Ticari Ünvan*"
                    name="companyLegalTitle"
                    value={formData.companyLegalTitle}
                    onChange={handleTextFieldChange}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    label="Cari Kod"
                    name="companyCorporationalCode"
                    value={formData.companyCorporationalCode}
                    onChange={handleTextFieldChange}
                    margin="normal"
                  />
                </Grid>
              </Grid>

              <Grid xs={16} container alignItems="center" spacing={2}>
                <Grid item xs={4}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel>İl</InputLabel>
                    <Select
                      sx={{ width: '100%' }}
                      label="İl"
                      value={formData.city}
                      onChange={handleCityChange}
                      name="city"
                    >
                      {cityList.map((city, index) => (
                        <MenuItem key={index} value={city.name}>
                          {city.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    label="İlçe"
                    name="region"
                    value={formData.region}
                    onChange={handleTextFieldChange}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    fullWidth
                    label="Ülke"
                    name="Country"
                    value={formData.country}
                    onChange={handleTextFieldChange}
                    margin="normal"
                    disabled
                  />
                </Grid>
              </Grid>

              <Grid xs={16} container alignItems="center" spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Vergi Dairesi"
                    name="taxAdministration"
                    value={formData.taxAdministration}
                    onChange={handleTextFieldChange}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Vergi Numarası*"
                    type="text"
                    name="taxNo"
                    value={formData.taxNo}
                    onChange={handleTextFieldChange}
                    margin="normal"
                  />
                </Grid>
              </Grid>

              <Grid xs={16} container alignItems="center" spacing={2}>
                <Grid item xs={16}>
                  <TextField
                    fullWidth
                    label="Fatura Adresi"
                    name="address"
                    value={formData.address}
                    onChange={handleTextFieldChange}
                    margin="normal"
                  />
                </Grid>
              </Grid>

              <Grid xs={16} container alignItems="center" spacing={2}>
                <Grid item xs={16}>
                  <div
                    style={{
                      height: '1px',
                      backgroundColor: '#EAECF0',
                      margin: '0 15px',
                      marginTop: '32px',
                    }}
                  ></div>
                </Grid>
              </Grid>
              <Stack
                direction="column"
                alignItems="center"
                justifyContent="space-between"
                mb={5}
              >
                <Button
                  color="primary"
                  onClick={nextStep}
                  variant="contained"
                  sx={{
                    marginTop: '32px',
                    width: '241px',
                    height: '46px',
                    backgroundColor: '#1570EF',
                    fontSize: 12,
                    fontWeight: 500,
                  }}
                  disabled={!allFieldsAreValid()}
                >
                  SONRAKİ ADIM
                </Button>
              </Stack>
            </>
          </DialogContent>
        )}

        {activeStep === 1 && (
          <DialogContent>
            <>
              <Grid xs={16} container alignItems="center" spacing={2}>
                <Grid item xs={6}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Bulunduğu AVM/Plaza*</InputLabel>
                    <Select
                      label="Bulunduğu AVM/Plaza*"
                      value={formData.mallID}
                      onChange={handleMallChange}
                      name="mallID"
                      disabled={mallList.length === 0}
                    >
                      {mallList.map((mall, index) => (
                        <MenuItem key={index} value={mall.id}>
                          {mall.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Lokasyon Adı*"
                    name="locationName"
                    value={locationName}
                    onChange={(e) => setLocationName(e.target.value)}
                    margin="normal"
                  />
                </Grid>
              </Grid>

              <Grid xs={16} container alignItems="center" spacing={2}>
                <Grid item xs={6}>
                  <TextField
                    fullWidth
                    label="Adres"
                    name="locationAddress"
                    value={locationAddress}
                    onChange={(e) => setLocationAddress(e.target.value)}
                    margin="normal"
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth margin="normal">
                    <InputLabel>Lokasyon Türü*</InputLabel>
                    <Select
                      sx={{ width: '100%' }}
                      label="Lokasyon Türü*"
                      value={locationType}
                      onChange={(e) => setLocationType(e.target.value)}
                      name="locationType"
                    >
                      {typeList.map((type, index) => (
                        <MenuItem key={index} value={type.value}>
                          {type.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
            </>
            <Grid xs={16} container alignItems="center" spacing={2}>
              <Grid item xs={16}>
                <div
                  style={{
                    height: '1px',
                    backgroundColor: '#EAECF0',
                    margin: '0 15px',
                    marginTop: '32px',
                  }}
                ></div>
              </Grid>
            </Grid>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              mb={5}
            >
              <Button
                color="primary"
                onClick={backStep}
                variant="outlined"
                sx={{
                  marginTop: '32px',
                  width: '241px',
                  height: '46px',
                  marginRight: '25px',
                  fontSize: 12,
                  fontWeight: 500,
                }}
              >
                ÖNCEKİ ADIM
              </Button>
              <Button
                color="primary"
                onClick={nextStep}
                variant="contained"
                sx={{
                  marginTop: '32px',
                  width: '241px',
                  height: '46px',
                  backgroundColor: '#1570EF',
                  fontSize: 12,
                  fontWeight: 500,
                }}
                disabled={!allFieldsAreValid()}
              >
                SONRAKİ ADIM
              </Button>
            </Stack>
          </DialogContent>
        )}

        {activeStep === 2 && (
          <>
            <DialogContent>
              <Box
                sx={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  marginBottom: '20px',
                }}
              >
                <TabContext value={selectedOption}>
                  <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList
                      onChange={handleTabChange}
                      aria-label="lab API tabs example"
                    >
                      <Tab label="Yeni Sorumlu Kişi Ekle" value="new" />
                      <Tab label="Mevcuttan Kişilerden Seç" value="existing" />
                      <Tab label="Kişi Seçmeden İlerle" value="proceed" />
                    </TabList>
                  </Box>
                </TabContext>
              </Box>

              {selectedOption === 'new' && (
                <>
                  <Grid xs={16} container alignItems="center" spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="Sorumlu Kişi Ad*"
                        name="name"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="Sorumlu Kişi Soyad*"
                        name="lastname"
                        value={lastname}
                        onChange={(e) => setLastname(e.target.value)}
                        margin="normal"
                      />
                    </Grid>
                  </Grid>

                  <Grid xs={16} container alignItems="center" spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="Kullanıcı Adı*"
                        name="username"
                        value={userName}
                        onChange={(e) => setUserName(e.target.value)}
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        type={showPassword ? 'text' : 'password'}
                        label="Kullanıcı Şifresi*"
                        name="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        margin="normal"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                              >
                                <Iconify
                                  icon={
                                    showPassword
                                      ? 'eva:eye-fill'
                                      : 'eva:eye-off-fill'
                                  }
                                />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid xs={16} container alignItems="center" spacing={2}>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="E-Mail Adresi*"
                        name="email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        margin="normal"
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        fullWidth
                        label="Telefon*"
                        type="number"
                        name="phone"
                        value={phone}
                        onChange={(e) => setPhone(e.target.value)}
                        margin="normal"
                        onInput={(e) => {
                          let inputValue = e.target.value;
                          if (inputValue < 0) {
                            inputValue = '0';
                          }
                          // Eğer ilk karakter 0 ise ve giriş uzunluğu 1'den fazlaysa, ilk karakteri sil
                          if (inputValue.startsWith('0')) {
                            inputValue = inputValue.slice(1);
                          }
                          // Maksimum 10 karakter sınırlaması
                          if (inputValue.length > 10) {
                            inputValue = inputValue.slice(0, 10);
                          }
                          // Giriş değeri değiştiyse, yeni değeri güncelle
                          if (inputValue !== e.target.value) {
                            e.target.value = inputValue;
                            // State'i güncelle
                            setPhone(inputValue);
                          }
                        }}
                      />
                    </Grid>
                  </Grid>

                  <Grid xs={16} container alignItems="center" spacing={2}>
                    <Grid item xs={16}>
                      <div
                        style={{
                          height: '1px',
                          backgroundColor: '#EAECF0',
                          margin: '0 15px',
                          marginTop: '32px',
                        }}
                      ></div>
                    </Grid>
                  </Grid>
                </>
              )}
              {selectedOption === 'existing' && (
                <>
                  <Grid xs={16} container alignItems="center" spacing={2}>
                    <Grid item xs={16}>
                      <FormControl fullWidth margin="normal">
                        {userList.length === 0 ? (
                          <InputLabel>Kullanıcılar listeleniyor...</InputLabel>
                        ) : (
                          <InputLabel>Mevcutta Bulunan Kullanıcılar</InputLabel>
                        )}
                        <Select
                          disabled={userList.length === 0}
                          sx={{ width: '100%' }}
                          label="Mevcutta Bulunan Kullanıcılar"
                          value={selectedUser}
                          onChange={(e) => handleChangeSelectedUser(e)}
                          name="selectedUser"
                        >
                          {userList?.map((user, index) => (
                            <MenuItem key={index} value={user}>
                              {user.name} {user.lastname}
                            </MenuItem>
                          ))}
                        </Select>
                      </FormControl>
                    </Grid>
                  </Grid>
                  <Grid xs={16} container alignItems="center" spacing={2}>
                    <Grid item xs={16}>
                      <div
                        style={{
                          height: '1px',
                          backgroundColor: '#EAECF0',
                          margin: '0 15px',
                          marginTop: '32px',
                        }}
                      ></div>
                    </Grid>
                  </Grid>
                </>
              )}
            </DialogContent>

            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              mb={5}
            >
              <Button
                color="primary"
                onClick={backStep}
                variant="outlined"
                sx={{
                  marginTop: '32px',
                  width: '241px',
                  height: '46px',
                  marginRight: '25px',
                  fontSize: 12,
                  fontWeight: 500,
                }}
              >
                ÖNCEKİ ADIM
              </Button>
              <Button
                color="primary"
                onClick={handleSave}
                variant="contained"
                sx={{
                  marginTop: '32px',
                  width: '241px',
                  height: '46px',
                  backgroundColor: '#1570EF',
                  fontSize: 12,
                  fontWeight: 500,
                }}
                disabled={!allFieldsAreValid()}
              >
                İŞLEMİ TAMAMLA
              </Button>
            </Stack>
          </>
        )}
      </Dialog>

      <Dialog
        open={showResultMessage}
        onClose={handleCloseMessage}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {isEdit === true ? (
          <DialogTitle id="alert-dialog-title">
            {'Mağaza Düzenleme'}
          </DialogTitle>
        ) : (
          <DialogTitle id="alert-dialog-title">{'Mağaza Ekleme'}</DialogTitle>
        )}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {resultMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMessage}>Tamam</Button>{' '}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddStoreModal;
