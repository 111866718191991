import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Box from '@mui/material/Box';

export default function NumericInputField({
  value,
  onChange,
  sx,
  maxLength,
  onFocus,
}) {
  const handleIncrease = () => {
    onChange((Number(value) + 1).toString());
  };

  const handleDecrease = () => {
    onChange(Math.max(0, Number(value) - 1).toString()); // 0'ın altına düşmesini engellemek için
  };

  const handleChange = (event) => {
    const newValue = event.target.value.slice(0, maxLength); // maxLength kontrolü
    onChange((newValue));
  };

  return (
    <TextField
      sx={sx}
      type="number"
      inputProps={{
        inputMode: 'numeric',
        pattern: '[0-9]*',
        maxLength: maxLength,
      }}
      value={value}
      onChange={handleChange}
      variant="outlined"
      onFocus={onFocus}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <Box display="flex" flexDirection="column">
              <IconButton
                onClick={handleIncrease}
                aria-label="increase quantity"
                size="small"
              >
                <ArrowDropUpIcon />
              </IconButton>
              <IconButton
                onClick={handleDecrease}
                aria-label="decrease quantity"
                size="small"
              >
                <ArrowDropDownIcon />
              </IconButton>
            </Box>
          </InputAdornment>
        ),
      }}
    />
  );
}
