import { useEffect, useState } from 'react';
import {
  Autocomplete,
  TextField,
  Container,
  Typography,
  Divider,
  FormControl,
  Stack,
  MenuItem,
  Select,
  InputLabel,
  Box,
  Button,
  Collapse,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Alert,
  InputAdornment,
  IconButton,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import { LoadingButton } from '@mui/lab';
import Services from 'src/services/Services';
import Dots from 'react-activity/dist/Dots';
import 'react-activity/dist/Dots.css';
import { toast } from 'react-toastify';
import { styled } from '@mui/material/styles';
import Iconify from '../components/iconify';

const StyledContent = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  minHeight: '100vh',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  padding: theme.spacing(12, 0),
}));
const StyledFormWrapper = styled('div')(({ theme }) => ({
  maxWidth: 480,
  margin: 'auto',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
}));

export default function LoginPage() {
  const [avm, setAvm] = useState(null);
  const [taxNumber, setTaxNumber] = useState('');
  const [formOpen, setFormOpen] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(false);
  const [bearerToken, setBearerToken] = useState('');
  const [malls, setMalls] = useState([]);
  const [loading, setLoading] = useState(false);
  const [shops, setShops] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showPasswordApprove, setShowPasswordApprove] = useState(false);

  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    phoneNumber: '',
    username: '',
    password: '',
    passwordApprove: '',
    kvkk: false,
    terms: false,
  });

  const handleSearch = () => {
    setLoading(true);
    const requestData = {
      taxNo: taxNumber,
      mallID: avm,
    };

    Services.SearchBranchByTaxNo(
      bearerToken,
      requestData.taxNo,
      requestData.mallID
    )
      .then((response) => {
        if (response.data.status) {
          setFormOpen(true);
          setShops(response?.data?.result);
        } else {
          setFormOpen(false);
          toast.error('Kayıtlı vergi numarası bulunamadı');
        }
      })
      .catch((error) => {
        toast.error('Kayıtlı vergi numarası bulunamadı');
        setFormOpen(false);
      })
      .finally(() => setLoading(false));
  };

  const handleSubmit = (e) => {
    console.log('çalıştı');
    e.preventDefault();
    setLoading(true);
    Services.CreateEmployeeWithoutLogin(bearerToken, formData)
      .then((response) => {
        if (response.data.status === true) {
          toast.success('Başarıyla oluşturuldu.');
          setSelectedOption(null);
          setFormData({
            firstName: '',
            lastName: '',
            email: '',
            phoneNumber: '',
            username: '',
            password: '',
            mallID: null,
            branchID: null,
            corporationID: null,
            branchTitle: null,
          });
          window.location.href = '/request-received';
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(response.data.message);
      })
      .finally(() => setLoading(false));
  };

  const allFieldsAreValid = () => {
    if (
      isValidEmail === true &&
      formData.firstName.length >= 3 &&
      formData.lastName.length >= 2 &&
      formData.phoneNumber.length === 10 &&
      formData.username.length > 1 &&
      formData.password.length > 1 &&
      formData.branchID &&
      formData.kvkk === true &&
      formData.terms === true &&
      formData.password === formData.passwordApprove &&
      formData.password?.includes(' ') === false &&
      formData.passwordApprove?.includes(' ') === false
    ) {
      return false;
    } else {
      return true;
    }
  };

  const handleTaxNumberChange = (event) => {
    const value = event.target.value.replace(/[^0-9]/g, '').slice(0, 11);
    setTaxNumber(value);
  };

  const handleNameAndSurnameChange = (e) => {
    const value = e.target.value.replace(/[^a-zA-ZğüşöçĞÜŞÖÇıİ\s]/g, '');
    setFormData({
      ...formData,
      [e.target.name]: value,
    });
  };

  const handleEmailChange = (event) => {
    const inputValue = event.target.value.toLowerCase();
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    setIsValidEmail(emailRegex.test(inputValue));
    setFormData({
      ...formData,
      email: inputValue,
    });
  };

  const handlePhoneNumberChange = (event) => {
    const value = event.target.value.replace(/\D/g, '');
    if (/^(?!0)\d*$/.test(value) || value === '') {
      setFormData({ ...formData, phoneNumber: value });
    }
  };

  useEffect(() => {
    setLoading(true);
    Services.GetTokenForCreateEmployeeWithoutLogin()
      .then((response) => {
        setBearerToken(response?.data?.result?.token);
      })
      .catch((error) => {
        // console.log(error);
      })
      .finally(() => setLoading(false));
  }, []);

  useEffect(() => {
    if (bearerToken) {
      setLoading(true);
      Services.GetMallsForCreateEmployeeWithoutLogin(bearerToken)
        .then((response) => {
          setMalls(response?.data?.result);
        })
        .catch((error) => {
          // console.log(error);
        })
        .finally(() => setLoading(false));
    }
  }, [bearerToken]);

  return (
    <>
      {loading ? (
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            width: '100%',
            height: '100%',
          }}
        >
          <Dots />
        </div>
      ) : (
        <>
          <Helmet>
            <title> Create Employee | Mall Logistics Panel </title>
          </Helmet>
          <Container maxWidth="sm">
            <StyledContent>
              <Typography textAlign="center" variant="h4" gutterBottom>
                Mall Logistics Kullanıcı Oluşturma Servisi
              </Typography>
              <Divider sx={{ my: 3 }}>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                  AVM Ara
                </Typography>
              </Divider>
              <>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <Autocomplete
                    options={malls || []}
                    getOptionLabel={(option) => option.title}
                    value={malls.find((mall) => mall.id === avm) || null}
                    onChange={(event, newValue) => {
                      console.log('event.target: ', event.target.value);
                      console.log('newValue: ', newValue);
                      setAvm(newValue ? newValue.id : '');
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label="Lütfen AVM Seçin"
                        variant="outlined"
                        fullWidth
                      />
                    )}
                    disabled={loading}
                    isOptionEqualToValue={(option, value) =>
                      option.id === value.id
                    }
                  />
                </FormControl>
                <FormControl sx={{ m: 1, minWidth: 120 }}>
                  <TextField
                    required
                    id="taxNumber"
                    name="taxNumber"
                    label="Lütfen Vergi Numarası Girin"
                    type="number"
                    value={taxNumber}
                    onChange={handleTaxNumberChange}
                  />
                </FormControl>
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ my: 2 }}
                ></Stack>
                <LoadingButton
                  onClick={handleSearch}
                  disabled={!avm}
                  fullWidth
                  size="large"
                  type="submit"
                  variant="contained"
                >
                  Ara
                </LoadingButton>
              </>
              <Collapse
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '100%',
                  marginTop: '20px',
                }}
                in={formOpen}
              >
                <StyledFormWrapper>
                  <FormControl onSubmit={handleSubmit}>
                    <InputLabel id="demo-simple-select-helper-label">
                      Lütfen Mağaza Seçin
                    </InputLabel>
                    <Select
                      disabled={loading}
                      required
                      labelId="demo-simple-select-helper-label"
                      id="demo-simple-select-helper"
                      value={selectedOption}
                      label="Shops"
                      onChange={(e) => {
                        setSelectedOption(e.target.value);
                        setFormData({
                          ...formData,
                          mallID: e.target.value.mallID,
                          branchID: e.target.value.branchID,
                          corporationID: e.target.value.corporationID
                            ? e.target.value.corporationID
                            : '',
                          branchTitle: e.target.value.title,
                        });
                      }}
                    >
                      <MenuItem value={null} disabled>
                        Lütfen Mağaza Seçin
                      </MenuItem>
                      {!loading &&
                        shops?.map((item, key) => (
                          <MenuItem key={key} value={item}>
                            {item?.title}
                          </MenuItem>
                        ))}
                    </Select>
                    <TextField
                      label="Ad"
                      name="firstName"
                      value={formData.firstName}
                      onChange={handleNameAndSurnameChange}
                      margin="normal"
                      fullWidth
                    />
                    <TextField
                      label="Soyad"
                      name="lastName"
                      value={formData.lastName}
                      onChange={handleNameAndSurnameChange}
                      margin="normal"
                      fullWidth
                    />
                    <TextField
                      label="E-posta"
                      type="email"
                      name="email"
                      value={formData.email}
                      onChange={handleEmailChange}
                      onBlur={() => {
                        if (formData.username === '') {
                          setFormData({
                            ...formData,
                            username: formData.email?.toLowerCase(),
                          });
                        }
                      }}
                      margin="normal"
                      fullWidth
                    />
                    <TextField
                      label="5XXXXXXXXX"
                      name="phoneNumber"
                      value={formData.phoneNumber}
                      onChange={handlePhoneNumberChange}
                      margin="normal"
                      fullWidth
                    />
                    <TextField
                      label="Kullanıcı Adı"
                      name="username"
                      value={formData.username}
                      onChange={(e) => {
                        setFormData({
                          ...formData,
                          username: e.target.value,
                        });
                      }}
                      margin="normal"
                      fullWidth
                      autoComplete="false"
                    />
                    <TextField
                      label="Parola"
                      type={showPassword ? 'text' : 'password'}
                      name="password"
                      value={formData.password}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() => setShowPassword(!showPassword)}
                              edge="end"
                            >
                              <Iconify
                                icon={
                                  showPassword
                                    ? 'eva:eye-fill'
                                    : 'eva:eye-off-fill'
                                }
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) =>
                        setFormData({ ...formData, password: e.target.value })
                      }
                      margin="normal"
                      fullWidth
                    />
                    <TextField
                      label="Parola Tekrar"
                      type={showPasswordApprove ? 'text' : 'password'}
                      name="password approve"
                      value={formData.passwordApprove}
                      InputProps={{
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              onClick={() =>
                                setShowPasswordApprove(!showPasswordApprove)
                              }
                              edge="end"
                            >
                              <Iconify
                                icon={
                                  showPasswordApprove
                                    ? 'eva:eye-fill'
                                    : 'eva:eye-off-fill'
                                }
                              />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      onChange={(e) =>
                        setFormData({
                          ...formData,
                          passwordApprove: e.target.value,
                        })
                      }
                      margin="normal"
                      fullWidth
                    />
                    {formData.password?.includes(' ') ||
                    formData.passwordApprove?.includes(' ') ? (
                      <Alert severity="error">
                        Şifre "boşluk" karakteri içeremez!
                      </Alert>
                    ) : null}
                    <FormGroup className="kvkk-form-wrapper">
                      <FormControlLabel
                        required
                        style={{
                          color: '#5E596D',
                          fontSize: '12px',
                          fontWeight: 400,
                        }}
                        control={
                          <Checkbox
                            checked={formData.terms === true ? true : false}
                            value={formData.terms}
                            onChange={() =>
                              setFormData({
                                ...formData,
                                terms: !formData.terms,
                              })
                            }
                          />
                        }
                        label="Kullanım koşullarını okudum ve kabul ediyorum."
                      />
                      <FormControlLabel
                        required
                        style={{
                          color: '#5E596D',
                          fontSize: '7px',
                          fontWeight: 400,
                        }}
                        checked={formData.kvkk === true ? true : false}
                        control={
                          <Checkbox
                            value={formData.kvkk}
                            onChange={() =>
                              setFormData({
                                ...formData,
                                kvkk: !formData.kvkk,
                              })
                            }
                          />
                        }
                        label="KVKK Aydınlatma Metnini okudum ve anladım."
                      />
                    </FormGroup>
                    <Button
                      fullWidth
                      size="large"
                      type="submit"
                      variant="contained"
                      color="primary"
                      disabled={allFieldsAreValid()}
                      onClick={handleSubmit}
                    >
                      Çalışan oluştur
                    </Button>
                  </FormControl>
                </StyledFormWrapper>
              </Collapse>
            </StyledContent>
          </Container>
        </>
      )}
    </>
  );
}
