import ReactApexChart from 'react-apexcharts';
// @mui
import { Card, CardHeader, Box, Typography, Stack } from '@mui/material';
import { useState } from 'react';
// components

// ----------------------------------------------------------------------

export default function AppLineDatas({
  title,
  subheader,
  interval,
  height,
  days,
  totalWaybills,
  completedWaybills,
  totalDatas,
  completedDatas,
  chartLabels,
  chartData,
}) {
  const [selected, setSelected] = useState('Aylık');
  const series = [
    {
      name: 'Toplam İrsaliye',
      data: totalWaybills,
    },
    {
      name: 'Tamamlanan İrsaliye',
      data: completedWaybills,
    },
  ];

  const options = {
    chart: {
      height: height,
      type: 'line',
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
    },

    dataLabels: {
      enabled: false,
    },
    // title: {
    //   text: 'Product Trends by Month',
    //   align: 'left',
    // },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5,
      },
    },
    colors: ['#0666F6', '#C893FD'],
    xaxis: {
      categories: days,
    },
    stroke: {
      curve: 'straight',
      width: 3, // Çizgi kalınlığı
    },
    markers: {
      size: 6, // Marker boyutu
      colors: ['#0666F6', '#C893FD'], // Marker ve dış çizgi rengi (aynı renkler)
      shape: 'circle', // Marker şekli (daire)
    },
    legend: {
      show: false, // Legend'i gizle
    },
  };

  return (
    <Card>
      <Stack
        alignItems={'center'}
        flexDirection={'row'}
        justifyContent={'space-between'}
      >
        <Stack marginTop={'15px'} flexDirection={'column'}>
          <Typography
            style={{
              paddingLeft: 20,
              fontSize: 16,
              color: '#98A2B3',
              fontWeight: '400',
            }}
          >
            {subheader}
          </Typography>
          <Typography
            style={{
              paddingLeft: 20,
              fontSize: 22,
              //   color: '#98A2B3',
              fontWeight: '700',
            }}
          >
            {title}
          </Typography>
        </Stack>
        <Stack marginTop={'15px'} flexDirection={'row'} alignItems={'center'}>
          <div
            style={{
              height: 10,
              width: 10,
              backgroundColor: '#0666F6',
              borderRadius: 5,
              marginRight: 10,
            }}
          />
          <Typography fontWeight={'400'} color={'#615E83'} marginRight={2}>
            {interval === 'Changeable' ? 'Aylık Ortalama' : 'Toplam İrsaliye'}
          </Typography>
          <div
            style={{
              height: 10,
              width: 10,
              backgroundColor: '#C893FD',
              borderRadius: 5,
              marginRight: 10,
            }}
          />
          <Typography fontWeight={'400'} color={'#615E83'} marginRight={2}>
            {interval === 'Changeable'
              ? 'Toplam Teslim'
              : 'Tamamlanan İrsaliye'}
          </Typography>
          {interval === 'Changeable' ? (
            <Stack
              style={{
                backgroundColor: '#EEF0FB',
                padding: 15,
                borderRadius: 5,
                fontWeight: '400',
                color: '#9291A5',
                marginRight: 10,
                width: 250,
              }}
              justifyContent={'space-around'}
              alignItems={'center'}
              flexDirection={'row'}
            >
              <Typography
                onClick={() => setSelected('Günlük')}
                style={{
                  cursor: 'pointer',
                  padding: 10,
                  borderRadius: 5,
                  backgroundColor:
                    selected === 'Günlük' ? '#172955' : 'transparent',
                  color: selected === 'Günlük' ? 'white' : '#9291A5',
                }}
              >
                Günlük
              </Typography>
              <Typography
                onClick={() => setSelected('Haftalık')}
                style={{
                  cursor: 'pointer',
                  padding: 10,
                  borderRadius: 5,
                  backgroundColor:
                    selected === 'Haftalık' ? '#172955' : 'transparent',
                  color: selected === 'Haftalık' ? 'white' : '#9291A5',
                }}
              >
                Haftalık
              </Typography>
              <Typography
                onClick={() => setSelected('Aylık')}
                style={{
                  cursor: 'pointer',
                  padding: 10,
                  borderRadius: 5,
                  backgroundColor:
                    selected === 'Aylık' ? '#172955' : 'transparent',
                  color: selected === 'Aylık' ? 'white' : '#9291A5',
                }}
              >
                Aylık
              </Typography>
            </Stack>
          ) : (
            <Typography
              style={{
                backgroundColor: '#EEF0FB',
                padding: 10,
                borderRadius: 5,
                fontWeight: '400',
                color: '#9291A5',
                marginRight: 10,
              }}
            >
              {interval}
            </Typography>
          )}
        </Stack>
      </Stack>

      <Box sx={{ p: 0, pb: 1, height: `${height}px` }} dir="ltr">
        <ReactApexChart
          options={options}
          series={series}
          type="line"
          height={height}
        />
      </Box>
    </Card>
  );
}
