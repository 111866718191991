import { Backdrop, Box, Button, CircularProgress, Stack, TextField, Typography, Select, MenuItem, FormControl, InputLabel, InputAdornment, IconButton, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import React, { useEffect, useState } from 'react'
import { Helmet } from 'react-helmet-async';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import Iconify from 'src/components/iconify';
import NumericInputField from 'src/components/shared/NumericInputField';
import api from 'src/services/BaseService';
import Services from 'src/services/Services';

export default function PreHandshake() {
    const { avmID } = useParams();
    const [selectedMall, setSelectedMall] = useState({});
    const [storeList, setStoreList] = useState([]);
    const [backdropOpen, setBackdropOpen] = useState(false);
    const [toolQuantities, setToolQuantities] = useState();
    const [totalPackageCount, setTotalPackageCount] = useState("0");
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [showPassword, setShowPassword] = useState(false);
    const [selectedStore, setSelectedStore] = useState("");
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedTools, setSelectedTools] = useState([]);
    const [containsRing, setContainsRing] = useState(false);
    const [selectedType, setSelectedType] = useState("");
    const [token, setToken] = useState();

    const [messageBackdropOn, setMessageBackdropOn] = useState(false);

    const createHandshake = () => {
        setMessageBackdropOn(true)
        let formatedToolsArr = selectedTools.map(item => ({
            transportationTool: item.tool,
            transportationToolCount: parseInt(item.quantity, 10)
        }));

        const filteredArray = formatedToolsArr.filter(item => item.transportationToolCount > 0);

        Services.CreatePreHandshake(token, avmID, selectedStore.branchID, filteredArray, username, password, totalPackageCount).then((response) => {
            if (response.data.status) {
                toast.success('İşleminiz başarıyla tamamlandı.');
            }
            else {
                toast.error("İşlem yapılırken bir hatayla karşılaşıldı.")
            }
            setMessageBackdropOn(false)
        })
            .catch((error) => {
                setMessageBackdropOn(true)
            });
    }

    useEffect(() => {
        setBackdropOpen(true)
        Services.GetMLToken().then((res) => {
            if (res.data.status) {
                getMalls(res.data.result.token)
                getStores(res.data.result.token)
                setToken(res.data.result.token)
            }
        });
    }, []);

    const handleToolCountChange = (tool, value) => {
        if (/^\d*$/.test(value)) {
            setToolQuantities((prev) => ({ ...prev, [tool]: value }));
        }

        if (selectedType === "ring") {
            setTotalPackageCount(value)
        }
    };

    const handleDialogOpen = () => {
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
    };

    const handleDialogConfirm = () => {
        createHandshake()
        setDialogOpen(false)
        setToolQuantities(selectedMall.transportationTools?.reduce((acc, tool) => ({ ...acc, [tool]: '0' }), {}))
        setTotalPackageCount("0")
        setUsername("")
        setPassword("")
        setShowPassword(false)
        setSelectedTools([])
        setSelectedStore("")
        setSelectedType("")
    }


    function getStores(token) {
        Services.GetStores(token, avmID).then((response) => {
            if (response.data.status) {
                setStoreList(response.data.result.sort((a, b) => a.title.localeCompare(b.title)))
                setBackdropOpen(false)
            }
            else {
                setBackdropOpen(false)
            }
        });
    }

    function getMalls(token) {
        Services.GetMalls(token).then((response) => {
            if (response.data.status) {
                setSelectedMall(response.data.result.find(x => x.id === avmID))
                setToolQuantities(response.data.result.find(x => x.id === avmID).transportationTools?.reduce((acc, tool) => ({ ...acc, [tool]: '0' }), {}))
                let allTools = (response.data.result.find(x => x.id === avmID).transportationTools);
                allTools.map((tool) => {
                    if (tool.toLowerCase().includes('ring') || tool.toLowerCase().includes('koli')) {
                        setContainsRing(true)
                    }
                })
            }
        });
    }

    const handleStoreSelect = (store) => {
        setSelectedStore(store);
    };

    const handleTypeSelect = (event) => {
        setSelectedType(event.target.value)
        setToolQuantities(selectedMall.transportationTools?.reduce((acc, tool) => ({ ...acc, [tool]: '0' }), {}))
        setTotalPackageCount("0")
    }

    const handleComplete = () => {
        const filledTools = Object.entries(toolQuantities)
            .filter(([_, quantity]) => quantity.trim() !== '')
            .map(([tool, quantity]) => ({ tool, quantity }));
        setSelectedTools(filledTools)
        handleDialogOpen()
    }

    const isFormValid = () => {
        const allToolsFilled = Object.values(toolQuantities || {}).some(quantity => quantity.trim() !== '');
        return allToolsFilled && totalPackageCount.trim() !== '' && username.trim() !== '' && password.trim() !== '' && selectedStore;
    };

    return (
        <>
            <Helmet>
                <title> Pre-Handshake Form | Mall Logistics Panel </title>
            </Helmet>

            <div style={{
                position: 'sticky',
                width: '100%',
                height: '5%',
                backgroundColor: '#172955',
                top: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
            }}>
                <Box
                    component="img"
                    src="/assets/logo.png"
                    sx={{ width: { xs: '60px', sm: '60px' }, height: 'auto' }}
                />
            </div>
            <Dialog
                open={dialogOpen}
                onClose={handleDialogClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={messageBackdropOn}>
                    <CircularProgress />
                </Backdrop>
                <DialogTitle id="alert-dialog-title">İşlemi Tamamla</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        <b>{selectedMall.title}</b> için, bu araçlarla <br /> <br />
                        {selectedTools?.map((item, index) => (
                            <div key={index}>
                                <b>{item.tool} Sayısı</b> : {item.quantity}
                                <br />
                            </div>
                        ))}
                        <br />
                        <b>{totalPackageCount}</b> adet paket ile gönderiyi onaylıyor musunuz?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose}>Hayır</Button>
                    <Button onClick={handleDialogConfirm}>Evet</Button>
                </DialogActions>
            </Dialog>

            <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={backdropOpen}>
                <CircularProgress color="inherit" />
            </Backdrop>
            {!backdropOpen &&
                <Stack sx={{
                    height: '95%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    padding: '3%',
                    paddingTop: '25px',
                    gap: 3
                }}>
                    <Box
                        sx={{
                            height: '100%',
                            gap: '2%',
                            overflowY: 'auto',
                            display: 'flex',
                            flexDirection: 'column',
                            width: { xs: '90%', sm: '80%', md: '60%', lg: '35%' },
                            alignItems: 'center',
                            paddingTop: '15px',
                            paddingBottom: '15px',
                            borderRadius: 2,
                            boxShadow: 2,
                            minWidth: '300px',
                            maxWidth: '600px',
                            paddingX: '25px'
                        }}
                    >
                        <Typography variant='h3'>{selectedMall.title}</Typography>

                        <Typography sx={{ fontWeight: '700', width: '100%' }}>Mağaza</Typography>

                        <FormControl sx={{ width: '100%' }}>
                            <Autocomplete
                                id="store-selector"
                                options={storeList}
                                getOptionLabel={(option) => option.title}
                                value={selectedStore ? selectedStore : null}
                                onChange={(event, newValue) => {
                                    handleStoreSelect(newValue);
                                }}
                                disableClearable
                                disablePortal
                                renderInput={(params) => <TextField {...params} label={storeList.length > 0 ? "Mağaza Seçiniz" : "Listelenecek Mağaza Bulunamadı"} />}
                                isOptionEqualToValue={(option, value) => option.id === value.id}
                                sx={{ width: '100%' }}
                            />


                        </FormControl>

                        {containsRing &&
                            <>
                                <Typography sx={{ fontWeight: '700', width: '100%' }}>Tür</Typography>
                                <FormControl sx={{ width: '100%' }}>
                                    <InputLabel id="dropdown-label">Ring veya Araç</InputLabel>
                                    <Select
                                        labelId="dropdown-label"
                                        id="dropdown"
                                        value={selectedType}
                                        label="Ring veya Araç"
                                        onChange={handleTypeSelect}
                                    >

                                        <MenuItem value={"ring"}>
                                            Ring
                                        </MenuItem>
                                        <MenuItem value={"vehicle"}>
                                            Araç
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </>
                        }
                        {containsRing ?
                            <>
                                {selectedType === "vehicle" ?
                                    <>
                                        <Typography sx={{ fontWeight: '700', width: '100%' }}>Araçlar</Typography>
                                        <Box
                                            display="flex"
                                            flexDirection="column"
                                            gap={2}
                                            sx={{ width: '100%' }}
                                        >
                                            {selectedMall.transportationTools?.map((tool) => {
                                                if (!tool.toLowerCase().includes('ring') && !tool.toLowerCase().includes('koli')) {
                                                    return (
                                                        <Box
                                                            key={tool}
                                                            display="flex"
                                                            alignItems="center"
                                                            gap={1}
                                                            sx={{ width: '100%' }}
                                                        >
                                                            <Typography sx={{ width: '150px' }}>{tool} Sayısı:</Typography>
                                                            <NumericInputField
                                                                sx={{ width: '100%' }}
                                                                value={toolQuantities[tool] || ''}
                                                                onChange={(newValue) => handleToolCountChange(tool, newValue)}
                                                                maxLength={3}
                                                                onFocus={(e) => e.target.select()}
                                                            />
                                                        </Box>)
                                                }
                                            })}
                                        </Box>
                                    </> :
                                    <>
                                        {selectedType === "ring" && <>
                                            <Typography sx={{ fontWeight: '700', width: '100%' }}>Ring</Typography>
                                            <Box
                                                display="flex"
                                                flexDirection="column"
                                                gap={2}
                                                sx={{ width: '100%' }}
                                            >
                                                {selectedMall.transportationTools?.map((tool) => {
                                                    if (tool.toLowerCase().includes('ring') || tool.toLowerCase().includes('koli')) {
                                                        return (
                                                            <Box
                                                                key={tool}
                                                                display="flex"
                                                                alignItems="center"
                                                                gap={1}
                                                                sx={{ width: '100%' }}
                                                            >
                                                                <Typography sx={{ width: '150px' }}>{tool} Sayısı:</Typography>
                                                                <NumericInputField
                                                                    sx={{ width: '100%' }}
                                                                    value={toolQuantities[tool] || ''}
                                                                    onChange={(newValue) => handleToolCountChange(tool, newValue)}
                                                                    maxLength={3}
                                                                    onFocus={(e) => e.target.select()}
                                                                />
                                                            </Box>)
                                                    }
                                                })}
                                            </Box>
                                        </>
                                        }
                                    </>
                                }
                            </>
                            :
                            <>
                                <Typography sx={{ fontWeight: '700', width: '100%' }}>Araçlar</Typography>
                                <Box
                                    display="flex"
                                    flexDirection="column"
                                    gap={2}
                                    sx={{ width: '100%' }}
                                >
                                    {selectedMall.transportationTools?.map((tool) => (
                                        <Box
                                            key={tool}
                                            display="flex"
                                            alignItems="center"
                                            gap={1}
                                            sx={{ width: '100%' }}
                                        >
                                            <Typography sx={{ width: '150px' }}>{tool} Sayısı:</Typography>
                                            <NumericInputField
                                                sx={{ width: '100%' }}
                                                value={toolQuantities[tool] || ''}
                                                onChange={(newValue) => handleToolCountChange(tool, newValue)}
                                                maxLength={3}
                                                onFocus={(e) => e.target.select()}
                                            />
                                        </Box>
                                    ))}
                                </Box>
                            </>
                        }

                        <Typography sx={{ fontWeight: '700', width: '100%' }}>Kullanıcı Bilgileri</Typography>
                        <Box
                            display="flex"
                            flexDirection="column"
                            gap={2}
                            sx={{ width: '100%' }}
                        >
                            <Box
                                display="flex"
                                alignItems="center"
                                gap={1}
                            >
                                <Typography sx={{ width: '150px' }}>Kullanıcı Adı:</Typography>
                                <TextField
                                    sx={{ width: '100%' }}
                                    type="text"
                                    value={username}
                                    onChange={(e) => setUsername(e.target.value)}
                                    variant="outlined"
                                />
                            </Box>

                            <Box
                                display="flex"
                                alignItems="center"
                                gap={1}
                            >
                                <Typography sx={{ width: '150px' }}>Şifre:</Typography>
                                <TextField
                                    sx={{ width: '100%' }}
                                    type={showPassword ? 'text' : 'password'}
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    variant="outlined"
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    onClick={() => setShowPassword(!showPassword)}
                                                    edge="end"
                                                >
                                                    <Iconify
                                                        icon={
                                                            showPassword
                                                                ? 'eva:eye-fill'
                                                                : 'eva:eye-off-fill'
                                                        }
                                                    />
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Box>
                        </Box>

                        <div style={{ flexDirection: 'row', justifyContent: 'center', alignItems: 'center', display: 'flex' }}>
                            <Button disabled={!isFormValid()} variant='contained' onClick={handleComplete}>Kaydet</Button>
                        </div>
                    </Box>
                </Stack>
            }
        </>
    );
}
