import { useState, useEffect, useRef } from 'react';
import * as React from 'react';
import { toast } from 'react-toastify';
// @mui
import {
  Button,
  MenuItem,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Select,
  OutlinedInput,
  Radio,
  RadioGroup,
  FormControl,
  Autocomplete,
  FormControlLabel,
} from '@mui/material';
import Services from 'src/services/Services';

function AddEditUserForPointModal({
  userID,
  isUserDialogOpen,
  actionType,
  setUserDialogOpen,
  MenuProps,
}) {
  const [token, setToken] = useState('');
  const [buttonLoading, setButtonLoading] = useState(false);
  const [resetLoading, setResetLoading] = useState(false);
  const [isAutocompleteVisible, setIsAutocompleteVisible] = useState(true);
  const [selectedUserType, setSelectedUserType] = useState('user');
  const [name, setName] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [frontAuth, setFrontAuth] = useState([]);
  const [locations, setLocations] = useState([]);
  const [selectedRoles, setSelectedRoles] = useState([]);
  const [firstResponsibleOffice, setFirstResponsibleOffice] = useState(null);
  const [selectedLocations, setSelectedLocations] = useState([]);

  const roles = [
    {
      title: 'Operasyon',
      value: 'operations',
    },
    {
      title: 'Yönetici',
      value: 'manager',
    },
  ];

  const options = locations?.map((option) => {
    const firstLetter = option.title[0].toUpperCase();
    return {
      firstLetter: /[0-9]/.test(firstLetter) ? '0-9' : firstLetter,
      ...option,
    };
  });

  const handleSubmit = () => {
    setButtonLoading(true);
    let allowedPoints = [];
    let body_data = {};
    if (actionType === 'new') {
      // yeni kullanıcı
      if (isAutocompleteVisible) {
        console.log('firstOffice: ', firstResponsibleOffice);
        if (
          firstResponsibleOffice &&
          name?.length > 0 &&
          lastname?.length > 0 &&
          username?.length > 0 &&
          phone?.length >= 10 &&
          email?.length > 0 &&
          password?.length > 0 &&
          selectedRoles?.length > 0
        ) {
          allowedPoints.push({
            id: firstResponsibleOffice.id,
            isFirstResponsible: true,
          });
          if (selectedLocations?.length > 0) {
            for (let i = 0; i < selectedLocations?.length; i++) {
              allowedPoints.push({
                id: selectedLocations[i].id,
                isFirstResponsible: false,
              });
            }
          }

          body_data = {
            type: selectedUserType,
            name: name,
            lastname: lastname,
            email: email,
            phone: phone,
            username: username,
            password: password,
            allowedPoints: allowedPoints,
            isAdministrator: selectedRoles.includes('manager') ? true : false,
            frontAuth:
              selectedUserType === 'esnaf' ? ['operations'] : selectedRoles,
          };
        } else {
          toast.error('Zorunlu alanları doldurunuz.');
          setButtonLoading(false);
          return;
        }
      } else {
        // user ve manager
        body_data = {
          type: selectedUserType,
          name: name,
          lastname: lastname,
          email: email,
          phone: phone,
          username: username,
          password: password,
          allowedPoints: [],
          isAdministrator: selectedRoles.includes('manager') ? true : false,
          frontAuth: selectedRoles,
        };
      }
      console.log(body_data);
      // Servise bağlanacak
      Services.CreateEmployee(token, body_data)
        .then((res) => {
          console.log('create: ', res.data);
          if (res.data.status) {
            toast.success('Kullanıcı oluşturuldu.');
            setTimeout(() => {
              window.location.reload(true);
            }, 2000);
          } else {
            toast.error('Kullanıcı oluşturulurken bir hata oluştu.');
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Kullanıcı oluşturulurken bir hata oluştu.');
        })
        .finally(() => setButtonLoading(false));
    } else {
      // düzenleme
      if (isAutocompleteVisible) {
        console.log('firstOffice: ', firstResponsibleOffice);
        if (
          firstResponsibleOffice &&
          name?.length > 0 &&
          lastname?.length > 0 &&
          username?.length > 0 &&
          phone?.length >= 10 &&
          email?.length > 0 &&
          password?.length > 0 &&
          selectedRoles?.length > 0
        ) {
          allowedPoints.push({
            id: firstResponsibleOffice.id,
            isFirstResponsible: true,
          });
          if (selectedLocations?.length > 0) {
            for (let i = 0; i < selectedLocations?.length; i++) {
              allowedPoints.push({
                id: selectedLocations[i].id,
                isFirstResponsible: false,
              });
            }
          }

          body_data = {
            userID: userID,
            type: selectedUserType,
            name: name,
            lastname: lastname,
            email: email,
            phone: phone,
            username: username,
            password: password,
            allowedPoints: allowedPoints,
            isAdministrator: selectedRoles.includes('manager') ? true : false,
            frontAuth:
              selectedUserType === 'esnaf' ? ['operations'] : selectedRoles,
          };
        } else {
          toast.error('Zorunlu alanları doldurunuz.');
          setButtonLoading(false);
          return;
        }
      } else {
        // user ve manager
        body_data = {
          userID: userID,
          type: selectedUserType,
          name: name,
          lastname: lastname,
          email: email,
          phone: phone,
          username: username,
          password: password,
          allowedPoints: [],
          isAdministrator: selectedRoles.includes('manager') ? true : false,
          frontAuth: selectedRoles,
        };
      }
      console.log(body_data);
      Services.UpdateProfile(token, body_data)
        .then((res) => {
          console.log(res.data);
          if (res.data.status) {
            toast.success('Kullanıcı bilgileri düzenlendi.');
            setTimeout(() => {
              window.location.reload(true);
            }, 2000);
          } else {
            toast.error('Kullanıcı bilgileri düzenlenirken bir hata oluştu.');
          }
        })
        .catch((err) => {
          console.log(err);
          toast.error('Kullanıcı bilgileri düzenlenirken bir hata oluştu.');
        })
        .finally(() => setButtonLoading(false));
    }
  };

  const handleCopyPassword = () => {
    navigator.clipboard
      .writeText(password)
      .then(() => {
        console.log('Şifre kopyalandı:', password);
      })
      .catch((err) => {
        console.error('Şifre kopyalanırken bir hata oluştu:', err);
      });
  };

  const handleResetPassword = () => {
    setResetLoading(true);
    api
      .post('flow/user-forced-reset-password', {
        // email: '',
        userID: userID,
      })
      .then((response) => {
        if (response.data) {
          setPassword(response.data.result);
          setPasswordDialog(true);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error);
        toast.error(error.message);
      })
      .finally(() => setResetLoading(false));
  };

  const handleCloseModal = () => {
    setName('');
    setLastname('');
    setUsername('');
    setEmail('');
    setPhone('');
    setPassword('');
    setSelectedRoles([]);
    setSelectedLocations([]);
    setFirstResponsibleOffice(null);
    setSelectedUserType('user');
    setUserDialogOpen(false);
  };

  useEffect(() => {
    const shouldShowAutocomplete =
      selectedUserType === 'user' && selectedRoles.includes('manager');
    setIsAutocompleteVisible(!shouldShowAutocomplete);
  }, [selectedUserType, selectedRoles]);

  useEffect(() => {
    const parsedUser = JSON.parse(localStorage.getItem('user'));
    console.log('parsedUser', parsedUser);
    setToken(parsedUser.token);
    Services.GetPoints(parsedUser.token)
      .then((res) => {
        const points = res.data.result?.map((item) => ({
          title: item.title,
          id: item.id,
        }));
        setLocations(points);

        if (actionType === 'edit') {
          Services.GetUser(parsedUser.token, userID)
            .then((response) => {
              const user = response.data.result;
              const selectedRolesFromFrontAuth = roles
                .filter((role) => user?.frontAuth?.includes(role.value))
                .map((role) => role.value);

              const _firstResponsibleOffice = user?.allowedPoints.find(
                (point) => point.firstResponsible === true
              );

              const _selectedLocations = user?.allowedPoints
                .filter((point) => point.firstResponsible === false)
                .map((point) => point);

              setSelectedUserType(user.type);
              setName(user.name);
              setLastname(user.lastname);
              setUsername(user.username);
              setFrontAuth(user.frontAuth ? user.frontAuth : []);
              setEmail(user.email);
              setPhone(user.phone);
              setPassword(user.password);
              setSelectedRoles(selectedRolesFromFrontAuth);
              setFirstResponsibleOffice(_firstResponsibleOffice);
              setSelectedLocations(_selectedLocations);
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          setSelectedUserType('user');
          setName('');
          setLastname('');
          setUsername('');
          setEmail('');
          setPhone('');
          setPassword('');
          setSelectedRoles([]);
          setSelectedLocations([]);
          setFirstResponsibleOffice(null);
        }
      })
      .catch((err) => {
        console.log(err);
        setLocations([]);
        setSelectedUserType('user');
        setName('');
        setLastname('');
        setUsername('');
        setEmail('');
        setPhone('');
        setPassword('');
        setSelectedRoles([]);
        setSelectedLocations([]);
        setFirstResponsibleOffice(null);
      });
  }, [isUserDialogOpen]);

  return (
    <Dialog open={isUserDialogOpen} onClose={handleCloseModal}>
      <DialogTitle>
        Kullanıcı {actionType === 'new' ? 'Ekle' : 'Düzenle'}
      </DialogTitle>
      <DialogContent>
        <FormControl component="fieldset" sx={{ mb: 2, width: '100%' }}>
          <RadioGroup
            style={{
              display: 'flex',
              flexDirection: 'row',
              // justifyContent: 'space-between',
              width: '100%',
            }}
            aria-label="user-type"
            name="user-type"
            value={selectedUserType}
            onChange={(e) => {
              if (e.target.value === 'esnaf') {
                setSelectedRoles(['operations']);
              }
              setSelectedUserType(e.target.value);
            }}
          >
            <FormControlLabel
              value="user"
              control={<Radio />}
              label="Kullanıcı"
            />
            <FormControlLabel value="esnaf" control={<Radio />} label="Esnaf" />
          </RadioGroup>
        </FormControl>
        <Select
          fullWidth
          label="Yetki Düzeyleri *"
          placeholder="Yetki Düzeyleri *"
          displayEmpty
          multiple
          disabled={selectedUserType === 'esnaf'}
          value={selectedRoles}
          onChange={(event) => {
            setSelectedRoles(event.target.value);
            console.log(event.target.value);
          }}
          input={<OutlinedInput />}
          sx={{ mb: 2 }}
          renderValue={(selected) => {
            if (selected?.length === 0) {
              return <em>Yetki Düzeyleri *</em>;
            }

            return selected
              .map((value) => roles?.find((role) => role.value === value).title)
              .join(', ');
          }}
          MenuProps={MenuProps}
          inputProps={{ 'aria-label': 'Without label' }}
        >
          {roles?.map((x, index) => (
            <MenuItem key={index} value={x.value}>
              {x.title}
            </MenuItem>
          ))}
        </Select>

        <TextField
          label="Ad *"
          autoComplete="off"
          fullWidth
          value={name}
          onChange={(e) => setName(e.target.value)}
          sx={{ my: 2 }}
        />
        <TextField
          label="Soyad *"
          autoComplete="off"
          fullWidth
          value={lastname}
          onChange={(e) => setLastname(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          label="Kullanıcı adı *"
          autoComplete="off"
          fullWidth
          value={username}
          onChange={(e) => setUsername(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          label="E-posta *"
          autoComplete="off"
          fullWidth
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          sx={{ mb: 2 }}
        />
        <TextField
          label="Telefon *"
          autoComplete="off"
          fullWidth
          value={phone}
          type="number"
          onChange={(e) => setPhone(e.target.value)}
          sx={{ mb: 2 }}
        />
        <div style={{ display: 'flex', position: 'relative' }}>
          <TextField
            label="Parola *"
            autoComplete="off"
            fullWidth
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            sx={{ mb: 2 }}
          />
          {actionType === 'edit' && (
            <Button
              disabled={resetLoading}
              variant="contained"
              style={{
                right: 30,
                marginTop: 10,
                position: 'absolute',
              }}
              onClick={handleResetPassword}
            >
              <>{resetLoading ? 'Şifre Sıfırlanıyor...' : 'Şifreyi Sıfırla'}</>
            </Button>
          )}
        </div>
        {isAutocompleteVisible && (
          <>
            {' '}
            <Autocomplete
              id="grouped-demo"
              fullWidth
              value={firstResponsibleOffice}
              sx={{ mb: 2 }}
              options={options?.sort(
                (a, b) => -b.firstLetter.localeCompare(a.firstLetter)
              )}
              groupBy={(option) => option.firstLetter}
              getOptionLabel={(option) => option.title}
              onChange={(event, newValue) => {
                setFirstResponsibleOffice(newValue);
                console.log(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Sorumlu Olduğu Birim *"
                  placeholder="Sorumlu Olduğu Birim *"
                />
              )}
            />
            <Autocomplete
              id="grouped-demo2"
              fullWidth
              multiple
              value={selectedLocations}
              disabled={
                firstResponsibleOffice === null ||
                firstResponsibleOffice === '' ||
                firstResponsibleOffice?.length === 0
              }
              options={options?.filter(
                (option) =>
                  !selectedLocations.map((loc) => loc.id).includes(option.id) &&
                  option.id !== firstResponsibleOffice?.id
              )}
              groupBy={(option) => option.firstLetter}
              getOptionLabel={(option) => option.title}
              onChange={(event, newValue) => {
                setSelectedLocations(newValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Yetkisi Olduğu Birimler"
                  placeholder="Yetkisi Olduğu Birimler"
                />
              )}
            />
          </>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          disabled={buttonLoading}
          variant="contained"
          style={{}}
          onClick={handleSubmit}
        >
          <>
            {buttonLoading
              ? 'İşlem Gerçekleştiriliyor...'
              : actionType === 'edit'
              ? 'Düzenlemeyi Bitir'
              : 'Ekle'}
          </>
        </Button>
        <Button onClick={() => window.open('/dashboard/points', '_self')}>
          İptal
        </Button>
      </DialogActions>
    </Dialog>
  );
}

export default AddEditUserForPointModal;
