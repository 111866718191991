import React from 'react';
import { Helmet } from 'react-helmet-async';
import { concat, filter } from 'lodash';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useSelector } from 'react-redux';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  Select,
  TableContainer,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TableHead,
  IconButton,
  RadioGroup,
  FormControlLabel,
  Radio,
  Collapse,
  FormControl,
  OutlinedInput,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
import { gridFilterActiveItemsLookupSelector } from '@mui/x-data-grid';
import Services from 'src/services/Services';
import api from 'src/services/BaseService';

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export default function CreateEmployeeApproval() {
  const [isApprovalModalOpen, setIsApprovalModalOpen] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [isRefuseModalOpen, setIsRefuseModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState();
  const [user, setUser] = useState();
  const [tableData, setTableData] = useState();

  const handleApprove = (item) => {
    setIsApprovalModalOpen(true);
    setUser(item);
  };
  const handleRefuse = (item) => {
    setIsRefuseModalOpen(true);
    setUser(item);
  };
  const handleChangeAdmin = (event) => {
    setLoading(true);
    setIsAdmin(event.target.value);
    if (event.target.value === 'true') {
      api
        .post('locations/get-locations', {
          branchID: user?.branchID, // "00064741047904891577",
        })
        .then((response) => {
          // console.log('response: ', response.data);
          if (response.data.status) {
            setOptions(response.data.result);
          } else {
            toast.error(response.data.message);
          }
        })
        .catch((error) => {
          // console.log(error);
          toast.error(error.message);
        })
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    api
      .post('users/get-users', {
        status: 3,
      })
      .then((response) => {
        if (response.data.status) {
          setTableData(response.data.result);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        // console.log(error);
        toast.error(error.message);
      });
  }, []);

  return (
    <>
      <Helmet>
        <title> Çalışan Oluşturma Onay | Mall Logistics Panel </title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Çalışan Oluşturma Onay
          </Typography>
        </Stack>

        <Card>
          <Scrollbar sx={{ marginTop: '25px' }}>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="center">#</TableCell>
                    <TableCell align="center">Ad</TableCell>
                    <TableCell align="center">Soyad</TableCell>
                    <TableCell align="center">Mağaza İsmi</TableCell>
                    <TableCell align="center">Onayla / Reddet</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {tableData?.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell align="center">{index + 1}</TableCell>
                      <TableCell align="center">{item.name}</TableCell>
                      <TableCell align="center">{item.lastname}</TableCell>
                      <TableCell align="center">{item.username}</TableCell>
                      <TableCell
                        sx={{
                          display: 'flex',
                          alignItems: 'center,',
                          justifyContent: 'space-around',
                        }}
                        align="justify"
                      >
                        <IconButton
                          onClick={() => handleApprove(item)}
                          color="primary"
                        >
                          <CheckIcon />
                        </IconButton>
                        <IconButton
                          onClick={() => handleRefuse(item)}
                          color="primary"
                        >
                          <ClearIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          {/* <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={orderList.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        /> */}
        </Card>
      </Container>
      <Dialog open={isApprovalModalOpen}>
        <DialogTitle align="center">Talep Onayı</DialogTitle>
        <DialogContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'space-between',
          }}
        >
          <Typography>
            Oluşturmak istediğiniz kullanıcı admin mi çalışan mı ?
          </Typography>
          <RadioGroup
            aria-labelledby="demo-radio-buttons-group-label"
            name="radio-buttons-group"
            row
            value={isAdmin}
            onChange={handleChangeAdmin}
          >
            <FormControlLabel value={true} control={<Radio />} label="Admin" />
            <FormControlLabel
              value={false}
              control={<Radio />}
              label="Çalışan"
            />
          </RadioGroup>
          {/* <Typography>{user?.name}</Typography> */}
          <Collapse in={isAdmin === 'true' ? true : false}>
            <FormControl sx={{ width: 300, padding: 2.5 }}>
              <Select
                displayEmpty
                disabled={loading}
                value={selectedOption}
                input={<OutlinedInput />}
                onChange={(event) => setSelectedOption(event.target.value)}
                renderValue={(selected) => {
                  if (!selected) {
                    return <em>Adminler</em>;
                  }
                  return selected;
                }}
                MenuProps={MenuProps}
                inputProps={{ 'aria-label': 'Without label' }}
              >
                {options?.map((item, index) => (
                  <MenuItem value={item?.branchID}>{item?.title}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Collapse>
        </DialogContent>

        <DialogActions
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
          }}
        >
          <Button
            disabled={
              isAdmin === 'true' && selectedOption === null ? true : false
            }
            onClick={() => {
              setIsApprovalModalOpen(false);
              // console.log("TALEP ONAYLANDI")
              //   window.location.reload(false);
            }}
            color="primary"
          >
            Evet
          </Button>
          <Button
            // disabled={!isCheckboxChecked}
            onClick={() => {
              setIsApprovalModalOpen(false);
              // console.log("TALEP ONAYLANMADI")
            }}
            color="primary"
          >
            Hayır
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={isRefuseModalOpen}>
        <DialogTitle align="center">Talep Reddi</DialogTitle>
        <DialogContent>
          <Typography>Talebi Reddetmek İstediğinize Emin Misiniz ?</Typography>
        </DialogContent>
        <DialogActions
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-around',
          }}
        >
          <Button
            // disabled={!isCheckboxChecked}
            onClick={() => {
              setIsRefuseModalOpen(false);
              // console.log("TALEP REDDEDİLDİ")
              //   window.location.reload(false);
            }}
            color="primary"
          >
            Evet
          </Button>
          <Button
            // disabled={!isCheckboxChecked}
            onClick={() => {
              setIsRefuseModalOpen(false);
              // console.log("TALEP REDDEDİLMEDİ")
            }}
            color="primary"
          >
            Hayır
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
