import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import moment from 'moment';
// @mui
import {
  Card,
  Stack,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  MenuItem,
  Button,
  Divider,
  useMediaQuery
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Select from '@mui/material/Select';
//shared
import ClearButton from 'src/components/shared/ClearButton';
import FilterButton from 'src/components/shared/FilterButton';
import AddEntityButton from 'src/components/shared/AddEntityButton';
import DynamicTable from 'src/components/shared/DynamicTable';
import EditButton from 'src/components/shared/EditButton';
// sections
import api from 'src/services/BaseService';
import './muhaberatOutput.css';
import AddStoreModal from 'src/components/modals/AddStoreModal';
import CustomFilter from 'src/components/filter/CustomFilter';
import FilterViewButton from 'src/components/shared/FilterViewButton';
// ----------------------------------------------------------------------

const mallID = localStorage.getItem('mallID');

export default function StoreList() {
  const [storeList, setStoreList] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [title, setTitle] = useState('');
  const [mallTitle, setMallTitle] = useState('');
  const [addEditStoreOpen, setAddEditStoreOpen] = useState(false);
  const [isModalEdit, setIsModalEdit] = useState(false);
  const [editData, setEditData] = useState({});
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [responsiblePerson, setResponsiblePerson] = useState('');
  const [isPrimary, setIsPrimary] = useState('');
  const [mallList, setMallList] = useState([]);
  const [filterModal, setFilterModal] = useState(false);
  const isMobile = useMediaQuery('(max-width:768px)');
  const handleModal = () => {
    setFilterModal(true);
  };

  const handleCloseModal = () => {
    setFilterModal(false);
  };
  const STORE_TABLE_HEAD = [
    {
      id: 'title',
      label: 'Mağaza Adı',
      alignRight: false,
      sx: { width: 100 },
    },
    {
      id: 'mallTitle',
      label: 'AVM/Plaza',
      alignRight: false,
      sx: { width: 100 },
    },
    {
      id: 'userNameLastname',
      label: 'Sorumlu Kişi',
      alignRight: false,
      sortBy: 'locations[0].userNameLastname',
      format: (value, row) => {
        return row.locations[0].userNameLastname
          ? row.locations[0].userNameLastname
          : '-';
      },
      sx: { width: 100 },
    },
    {
      id: 'createdDateTime',
      label: 'Oluşturulma Tarihi',
      alignRight: false,
      format: (value) => moment.unix(value).format('DD/MM/YYYY HH:mm'),
      sx: { width: 100 },
    },
    {
      id: 'isPrimary',
      label: 'Öncelik Durumu',
      alignRight: false,
      format: (value) => (value === '1' ? 'Evet' : 'Hayır'),
      sx: { width: 50 },
    },
    {
      id: 'operation',
      label: '',
      alignRight: false,
      format: (value, row) => {
        return (
          <EditButton to={`/dashboard/edit-store/${row.id}/${row.mallID}`} />
        );
      },
      sx: { width: 25 },
    },
  ];

  const handleOpenAddStoreModal = () => {
    setAddEditStoreOpen(true);
    setIsModalEdit(false);
  };

  const handleCloseAddStoreModal = () => {
    setAddEditStoreOpen(false);
    getMallsAndStores();
  };

  function getStores(malls) {
    api
      .post('flow/get-malls-branches', {
        mallID: mallID,
      })
      .then((response) => {
        if (response.data) {
          let mallTitleAddedArray = [];
          let allStores = response.data.result;
          for (let i = 0; i < allStores?.length; i++) {
            const selectedMallTitle = malls.find(
              (item) => item.id === allStores[i].mallID
            ).title;
            mallTitleAddedArray = allStores.map((obj) => {
              return { ...obj, mallTitle: selectedMallTitle };
            });
          }
          setStoreList(mallTitleAddedArray);
          setOriginalData(mallTitleAddedArray);
        }
      });
  }

  function getMallsAndStores() {
    api
      .post('/malls/get-malls', {
        withOffices: true,
      })
      .then((response) => {
        if (response.data) {
          getStores(response.data.result);
          setMallList(response.data.result);
        }
      });
  }

  useEffect(() => {
    getMallsAndStores();
  }, []);

  const getFilteredStoreList = () => {
    let filteredStoreList;
    if (
      title ||
      mallTitle ||
      (startDate && endDate) ||
      isPrimary ||
      responsiblePerson
    ) {
      filteredStoreList = originalData.filter((item) => {
        const titleMatch = title
          ? item.title.toLowerCase().includes(title.toLowerCase())
          : true;
        const mallTitleMatch = mallTitle
          ? item.mallTitle.toLowerCase().includes(mallTitle.toLowerCase())
          : true;
        const responsiblePersonMatch = responsiblePerson
          ? item.locations[0].userNameLastname
            ? item.locations[0].userNameLastname
              .toLowerCase()
              .includes(responsiblePerson.toLowerCase())
            : null
          : true;
        const dateMatch =
          startDate && endDate
            ? moment.unix(item.createdDateTime).format('DD-MM-YYYY') >=
            moment(startDate).format('DD-MM-YYYY') &&
            moment.unix(item.createdDateTime).format('DD-MM-YYYY') <=
            moment(endDate).format('DD-MM-YYYY')
            : true;
        const isPrimaryMatch = isPrimary ? item.isPrimary === isPrimary : true;
        return (
          titleMatch &&
          mallTitleMatch &&
          dateMatch &&
          isPrimaryMatch &&
          responsiblePersonMatch
        );
      });
    } else {
      filteredStoreList = originalData;
    }

    setStoreList(filteredStoreList);
  };

  const clearFilters = () => {
    setStoreList(originalData);
    setTitle('');
    setMallTitle('');
    setStartDate(null);
    setEndDate(null);
    setIsPrimary('');
    setResponsiblePerson('');
  };

  return (
    <>
      <Helmet>
        <title> Mağaza Listesi | Mall Logistics Panel </title>
      </Helmet>

      <Stack style={{ paddingInline: '25px' }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Mağaza Listesi
          </Typography>
          <AddEntityButton
            label={'Mağaza Ekle'}
            onClick={() => handleOpenAddStoreModal()}
          />


        </Stack>
        <Stack
          direction="row"
          justifyContent={isMobile ? 'space-between' : 'end'}
          mb={5}
        >

          {isMobile ? <FilterViewButton variant="contained" onClick={handleModal} /> : null}



        </Stack>

        <Card>
          {/* İlk Satır */}
          <CustomFilter modalType={'drawer'} filterModal={filterModal} handleCloseModal={handleCloseModal}>
            <Stack
              direction="column"
              alignItems="center"
              justifyContent="space-between"
              paddingY={2}
              paddingX={1}
              spacing={2}
            >
              <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                <TextField
                  sx={{ flex: 1 }}
                  label="Mağaza Adı"
                  variant="outlined"
                  value={title}
                  onChange={(e) => setTitle(e.target.value)}
                />
              </FormControl>

              <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                <TextField
                  sx={{ flex: 1 }}
                  label="AVM/Plaza"
                  variant="outlined"
                  value={mallTitle}
                  onChange={(e) => setMallTitle(e.target.value)}
                />
              </FormControl>
              <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                <TextField
                  sx={{ flex: 1 }}
                  label="Sorumlu Kişi"
                  variant="outlined"
                  value={responsiblePerson}
                  onChange={(e) => setResponsiblePerson(e.target.value)}
                />
              </FormControl>

              <FormControl sx={{ flex: 1, minWidth: '100%' }} variant="outlined">
                <InputLabel id="priority-store-label">Öncelik Durumu</InputLabel>
                <Select
                  labelId="priority-store-label"
                  id="priority-store-label"
                  value={isPrimary}
                  onChange={(e) => setIsPrimary(e.target.value)}
                  label="Öncelik Durumu"
                >
                  <MenuItem value="1">Evet</MenuItem>
                  <MenuItem value="0">Hayır</MenuItem>
                </Select>
              </FormControl>
            </Stack>

            {/* İkinci Satır */}
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-start"
              paddingY={2}
              paddingX={1}
              spacing={2}
            >
              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  format="DD/MM/YYYY"
                  label="Başlangıç Tarihi"
                  value={startDate}
                  onChange={(newValue) => setStartDate(newValue)}
                />
              </LocalizationProvider>

              <LocalizationProvider dateAdapter={AdapterMoment}>
                <DatePicker
                  format="DD/MM/YYYY"
                  label="Bitiş Tarihi"
                  value={endDate}
                  onChange={(newValue) => setEndDate(newValue)}
                />
              </LocalizationProvider>
            </Stack>

            {/* Divider */}
            <Divider sx={{ width: '100%' }} />

            {/* Butonlar */}
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              paddingY={2}
              paddingX={1}
              spacing={2}
            >
              <ClearButton onClick={() => clearFilters()} />
              <FilterButton onClick={() => getFilteredStoreList()} />
            </Stack>
          </CustomFilter>


          <DynamicTable
            data={storeList}
            headers={STORE_TABLE_HEAD}
            initialOrder={'createdDateTime'}
            initalOrderDirection={'asc'}
            isOrdered={true}
            isMobile={isMobile}
            handleModal={handleModal}
            handleCloseModal={handleCloseModal}
          />
        </Card>
      </Stack>

      {addEditStoreOpen && (
        <AddStoreModal
          open={addEditStoreOpen}
          onClose={handleCloseAddStoreModal}
          isEdit={isModalEdit}
          editData={editData}
          mallList={mallList}
        />
      )}
    </>
  );
}
