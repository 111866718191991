import React from 'react';
import { Box } from '@mui/material';

const HorizontalDivider = ({ onClick }) => {

    return (
        <>
            <Box
                borderBottom={1}
                borderColor="#E5E7EB"
                width="100%"
            />
        </>
    );
};

export default HorizontalDivider;