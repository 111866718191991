import React from 'react';
import { Box, Container, Typography, Button, Grid, Paper } from '@mui/material';
import QRCode from 'react-qr-code';
import googlePlay from '../assets/images/googleplay.png';
import { ReactComponent as AppStoreLogo } from '../assets/images/appstore.svg';

const appStoreLogo = 'path/to/app-store-logo.png';
const logo = <Box component="img" src="/assets/logo.png" />;
const RequestReceivedPage = () => {
  const handleRedirectToLoginButtonClick = () => {
    window.open('/login', '_blank');
  };

  return (
    <Box
      sx={{
        background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
        minHeight: '100vh',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Paper
        elevation={3}
        sx={{ padding: 3, textAlign: 'center', width: '80%', maxWidth: 800 }}
      >
        <Typography my={2} variant="h4" gutterBottom>
          Talebinizi Aldık!
        </Typography>
        {/* Buradan aşağısı değişti */}
        <Typography variant="body1" paragraph>
          Oluşturulan kullanıcı hesabınıza Mall Logistics Web'den giriş yapmak
          için aşağıdan devam edebilirsiniz.
        </Typography>
        <Grid item xs={12} sm={6}>
          <Button
            sx={{ marginBottom: '40px', width: '256px' }}
            variant="contained"
            color="primary"
            fullWidth
            target="_blank"
            rel="noopener noreferrer"
            onClick={handleRedirectToLoginButtonClick}
          >
            {logo}
          </Button>

          {/* <QRCode value="https://apps.apple.com/tr/app/mall-logistics-panel/id6464216792?l=tr" /> */}
        </Grid>
        <Typography variant="body1" paragraph>
          Oluşturulan kullanıcı ile hesabınıza mobil uygulama ile giriş yapmak
          için aşağıdan uygulamamızı indirebilirsiniz.
        </Typography>
        <Grid my={2} container justifyContent="center">
          <Grid item xs={12} sm={6}>
            <Button
              sx={{ width: '256px' }}
              variant="contained"
              color="primary"
              fullWidth
              href="https://play.google.com/store/apps/details?id=com.malllogistics.app"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={googlePlay} alt="Google Play Store" />
            </Button>
            {/* <QRCode value="https://play.google.com/store/apps/details?id=com.malllogistics.app" /> */}
          </Grid>
          <Grid item xs={12} sm={6}>
            <Button
              sx={{ marginBottom: '40px', width: '256px' }}
              variant="contained"
              color="primary"
              fullWidth
              href="https://apps.apple.com/tr/app/mall-logistics-panel/id6464216792?l=tr"
              target="_blank"
              rel="noopener noreferrer"
            >
              <AppStoreLogo />
            </Button>
            {/* <QRCode value="https://apps.apple.com/tr/app/mall-logistics-panel/id6464216792?l=tr" /> */}
          </Grid>
        </Grid>
      </Paper>
    </Box>
  );
};

export default RequestReceivedPage;
