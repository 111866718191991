import { Helmet } from 'react-helmet-async';
import React, { useEffect, useRef, useState } from 'react';
import { toast } from 'react-toastify';
import {
  Button,
  Card,
  Table,
  Stack,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  TableRow,
  Select,
  MenuItem,
} from '@mui/material';
import '../pagesStyles/priceTable.css';
import { DownloadTableExcel } from 'react-export-table-to-excel';

import api from 'src/services/BaseService';

import Scrollbar from '../components/scrollbar';

import Services from 'src/services/Services';

const malls = [
  {
    code: 'AA105',
    title: 'İzmir Optimum AVM',
  },
  {
    code: 'AA102',
    title: 'Galataport Office',
  },
  {
    code: 'AA103',
    title: 'Galataport Terminal',
  },
  {
    code: 'AA104',
    title: 'Nata Vega AVM',
  },
  {
    code: 'AA108',
    title: 'Teras Park AVM',
  },
  {
    code: 'AA106',
    title: 'Hilltown Karşıyaka AVM',
  },
  {
    code: 'AA109',
    title: 'Tekira AVM',
  },
  {
    code: 'AA113',
    title: 'Anatolium Bursa AVM',
  },
  {
    code: 'AA111',
    title: 'Tarsu AVM - Danışma',
  },
];

const durations = [1, 2, 4, 6, 12];

const months = [
  'Ocak',
  'Şubat',
  'Mart',
  'Nisan',
  'Mayıs',
  'Haziran',
  'Temmuz',
  'Ağustos',
  'Eylül',
  'Ekim',
  'Kasım',
  'Aralık',
];

export default function PriceTablePage() {
  const tableRef = useRef(null);

  const [allDatas, setAllDatas] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear());
  const [monthlyData, setMonthlyData] = useState({});
  const tl = new Intl.NumberFormat('tr-TR', {
    style: 'currency',
    currency: 'TRY',
    maximumFractionDigits: 0,
  });

  const getYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const options = [];
    for (let i = 0; i < 5; i++) {
      options.push(currentYear - i);
    }
    return options;
  };

  function exportToExcel() {
    const temp1 = [];
    months.forEach((month) => {
      malls.forEach((mall) => {
        const monthlyDataTemp = monthlyData[month];
        const rowData = {
          Ay: month,
          Lokasyon: mall.title,
          'Toplam Süre': monthlyDataTemp?.[mall.code]?.totalDuration || '',
          'Toplam Fiyat': monthlyDataTemp?.[mall.code]?.totalPrice
            ? tl.format(monthlyDataTemp?.[mall.code]?.totalPrice)
            : '',
        };
        temp1.push(rowData);
      });
    });

    api
      .post('excel/export', {
        type: 'excel',
        content: temp1,
        header: {
          Ay: 'string',
          Lokasyon: 'string',
          'Toplam Süre': 'string',
          'Toplam Fiyat': 'string',
        },
        sheetTitle: 'Emanet Dolap Raporu',
      })
      .then((response) => {
        // console.log('response: ', response.data);
        if (response.data.status) {
          window.open( response.data.result, '_blank');
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        // console.log(error);
        toast.error(error.message);
      });
  }

  function getDataList() {
    Services.GetPrices()
      .then((response) => {
        // console.log('prices: ', response.data);
        setAllDatas(response.data);
      })
      .catch((error) => {
        // console.log(error);
        setAllDatas([]);
      });
  }

  useEffect(() => {
    getDataList();
  }, []);

  useEffect(() => {
    const initialData = {};
    for (const month of months) {
      initialData[month] = {
        monthlyTotalDuration: 0,
        monthlyTotalPrice: 0,
        monthlyTotalCount: 0,
      };
      for (const mall of malls) {
        initialData[month][mall.code] = {
          totalDuration: 0,
          totalPrice: 0,
        };
      }
    }

    allDatas?.forEach((data) => {
      const startDate = new Date(data.startDate);
      const monthIndex = startDate.getMonth();
      const month = months[monthIndex];
      const code = data.locationCode;
      if (initialData[month].hasOwnProperty(code)) {
        if (
          data.paymentType === '1' &&
          new Date(data.startDate).getFullYear() === year
        ) {
          initialData[month][code].totalPrice += data.price;
          initialData[month].monthlyTotalPrice += data.price;
          initialData[month][code].totalDuration += durations[data.durationId];
          initialData[month].monthlyTotalDuration += durations[data.durationId];
          initialData[month].monthlyTotalCount += 1;
        }
      }
    });
    setMonthlyData(initialData);
  }, [allDatas, year]);

  return (
    <>
      <Helmet>
        <title> Emanet Dolap Raporu | Mall Logistics Panel </title>
      </Helmet>

      <Stack style={{ paddingInline: '25px' }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Emanet Dolap Raporu
          </Typography>
        </Stack>

        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            marginBottom: 15,
          }}
        >
          <Select
            label="Yıl"
            value={year}
            onChange={(e) => setYear(e.target.value)}
            style={{ marginRight: '16px' }}
          >
            {getYearOptions().map((option) => (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            ))}
          </Select>
          <DownloadTableExcel
            filename="users table"
            sheet="users"
            currentTableRef={tableRef.current}
          >
            <Button
              variant="contained"
              color="success"
              // onClick={() => exportToExcel()}
            >
              Excele aktar
            </Button>
          </DownloadTableExcel>
        </div>

        <Card>
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table ref={tableRef} style={{ borderCollapse: 'collapse' }}>
                <thead>
                  <TableRow>
                    <TableCell
                      style={{ border: '1px solid #e0e0e0' }}
                      colSpan={1}
                      sx={{
                        textAlign: 'center',
                      }}
                    >
                      Lokasyon
                    </TableCell>
                    {malls.map((mall) => (
                      <TableCell
                        key={mall.code}
                        style={{ border: '1px solid #e0e0e0' }}
                        colSpan={2}
                        sx={{
                          textAlign: 'center',
                          border: '1px solid #e0e0e0',
                        }}
                      >
                        {mall.title}
                      </TableCell>
                    ))}
                    <TableCell
                      style={{ border: '1px solid #e0e0e0' }}
                      rowSpan={2}
                    >
                      Toplam Süre
                    </TableCell>
                    <TableCell
                      style={{ border: '1px solid #e0e0e0' }}
                      rowSpan={2}
                    >
                      Toplam Fiyat
                    </TableCell>
                    <TableCell
                      style={{ border: '1px solid #e0e0e0' }}
                      rowSpan={2}
                    >
                      Toplam Kiralama
                    </TableCell>
                  </TableRow>
                  {/* <TableRow>
                <TableCell
                      style={{ border: '1px solid #e0e0e0' }}
                      colSpan={1}
                      sx={{
                        textAlign: 'center',
                      }}
                    >
                      Code
                    </TableCell>
                    {malls.map((mall) => (
                      <TableCell
                        key={mall.code}
                        style={{ border: '1px solid #e0e0e0' }}
                        colSpan={2}
                        sx={{
                          textAlign: 'center',
                          border: '1px solid #e0e0e0',
                        }}
                      >
                        {mall.code}
                      </TableCell>
                    ))}
                </TableRow> */}
                  <TableRow>
                    <TableCell style={{ border: '1px solid #e0e0e0' }}>
                      Ay
                    </TableCell>
                    {malls.map((mall) => (
                      <React.Fragment key={mall.code}>
                        <TableCell style={{ border: '1px solid #e0e0e0' }}>
                          Toplam Süre
                        </TableCell>
                        <TableCell style={{ border: '1px solid #e0e0e0' }}>
                          Toplam Fiyat
                        </TableCell>
                      </React.Fragment>
                    ))}
                  </TableRow>
                </thead>
                <tbody>
                  {months.map((month) => (
                    <TableRow key={month}>
                      <TableCell style={{ border: '1px solid #e0e0e0' }}>
                        {month}
                      </TableCell>
                      {malls.map((mall) => (
                        <React.Fragment key={mall.code}>
                          <TableCell style={{ border: '1px solid #e0e0e0' }}>
                            {monthlyData.hasOwnProperty(month) &&
                              (monthlyData[month][mall.code]?.totalDuration !==
                              0
                                ? monthlyData[month][mall.code]?.totalDuration
                                : '')}
                          </TableCell>
                          <TableCell style={{ border: '1px solid #e0e0e0' }}>
                            {monthlyData.hasOwnProperty(month) &&
                              (monthlyData[month][mall.code]?.totalPrice !== 0
                                ? `${tl.format(
                                    monthlyData[month][mall.code]?.totalPrice
                                  )}`
                                : '')}
                          </TableCell>
                        </React.Fragment>
                      ))}

                      <TableCell style={{ border: '1px solid #e0e0e0' }}>
                        {monthlyData.hasOwnProperty(month) &&
                          monthlyData[month]?.monthlyTotalDuration}
                      </TableCell>
                      <TableCell style={{ border: '1px solid #e0e0e0' }}>
                        {monthlyData.hasOwnProperty(month) &&
                          tl.format(monthlyData[month]?.monthlyTotalPrice)}
                      </TableCell>
                      <TableCell style={{ border: '1px solid #e0e0e0' }}>
                        {monthlyData.hasOwnProperty(month) &&
                          monthlyData[month].monthlyTotalCount}
                      </TableCell>
                    </TableRow>
                  ))}
                </tbody>
              </Table>
            </TableContainer>
          </Scrollbar>
        </Card>
      </Stack>
    </>
  );
}
