import React from 'react';
import { Button } from '@mui/material';
import Iconify from '../iconify';

const AddEntityButton = ({ onClick, label,sx }) => {

    return (
        <>
            <Button
                sx={{
                    backgroundColor: '#079455',
                    fontSize: 14,
                    fontFamily: 'Inter',
                    fontWeight: 400,
                    letterSpacing:0.4,
                    height:40,
                    borderRadius: '5px',
                    padding: '8px 16px 8px 16px',
                    gap:'6px',
                    '&:hover': {
                        backgroundColor: '#067041',
                    },
                    ...sx
                }}
                
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
                onClick={onClick}
            >
                {label.toLocaleUpperCase('tr-TR')}
            </Button>
        </>
    );
};

export default AddEntityButton;