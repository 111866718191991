import React, { useState, useEffect, useRef } from 'react';
import { Backdrop, CircularProgress, FormControl, Typography, Modal, Box, Grid, Radio, RadioGroup, FormControlLabel, Checkbox, Switch, colors, InputAdornment } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Chip from '@mui/material/Chip';
import CancelIcon from '@mui/icons-material/Cancel';
import api from 'src/services/BaseService';
import moment from 'moment';
import { toast } from 'react-toastify';
import Iconify from 'src/components/iconify';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';


const AddEditPointModal = ({ open, onClose, isEdit, editData, malls, userList, setEditData }) => {
    const [showResultMessage, setShowResultMessage] = useState(false);
    const [resultMessage, setResultMessage] = useState('');
    const [isBackdropOpen, setIsBackdropOpen] = useState(true);
    const [workingDays, setWorkingDays] = useState([]);
    const [amazonWorkStatus, setAmazonWorkStatus] = useState(false);
    const [trendyolWorkStatus, setTrendyolWorkStatus] = useState(false);
    const [hepsiburadaWorkStatus, setHepsiburadaWorkStatus] = useState(false);
    const [hepsijetWorkStatus, setHepsijetWorkStatus] = useState(false);
    const [startDate, setStartDate] = useState();
    const [endDate, setEndDate] = useState();
    const [closedDates, setClosedDates] = useState([]);
    const [closedTimeStart, setClosedTimeStart] = useState();
    const [closedTimeEnd, setClosedTimeEnd] = useState();
    const [originalUserList, setOriginalUserList] = useState([]);
    const [isTabFocused, setIsTabFocused] = useState(true);



    const days = ["Pazartesi", "Salı", "Çarşamba", "Perşembe", "Cuma", "Cumartesi", "Pazar"]
    const [formData, setFormData] = useState({
        users: [],
        selectedBranch: '',
        //isAmazon: false,
        //isTrendyol: false,
        //isHepsiburada: false,
        //isHepsijet: false,
        isEasyPoint: false,
        isMalKabul: false,
        isMuhaberat: false,
        getirID: "",
        isGetir: null,
        title: "",
        amazonHubTitle: "",
        amazonAddress1: "",
        amazonAddress2: "",
        phone: "",
        email: "",
        city: "",
        region: "",
        district: "",
        address: "",
        address2: "",
        postCode: "",
        mallID: "",
        floor: "",
        latitude: "",
        longitude: "",
        branchCode: "",
        branchSubCode: "",
        prefix: "",
        dailyCapacity: "",
        workingDays: {
            monday: {
                enable: "off",
                openingTime: {
                    hour: "00",
                    minute: "00"
                },
                closingTime: {
                    hour: "00",
                    minute: "00"
                }
            },
            tuesday: {
                enable: "off",
                openingTime: {
                    hour: "00",
                    minute: "00"
                },
                closingTime: {
                    hour: "00",
                    minute: "00"
                }
            },
            wednesday: {
                enable: "off",
                openingTime: {
                    hour: "00",
                    minute: "00"
                },
                closingTime: {
                    hour: "00",
                    minute: "00"
                }
            },
            thursday: {
                enable: "off",
                openingTime: {
                    hour: "00",
                    minute: "00"
                },
                closingTime: {
                    hour: "00",
                    minute: "00"
                }
            },
            friday: {
                enable: "off",
                openingTime: {
                    hour: "00",
                    minute: "00"
                },
                closingTime: {
                    hour: "00",
                    minute: "00"
                }
            },
            saturday: {
                enable: "off",
                openingTime: {
                    hour: "00",
                    minute: "00"
                },
                closingTime: {
                    hour: "00",
                    minute: "00"
                }
            },
            sunday: {
                enable: "off",
                openingTime: {
                    hour: "00",
                    minute: "00"
                },
                closingTime: {
                    hour: "00",
                    minute: "00"
                }
            }
        },
    });
    const textAreaRef = useRef(null);
    const handleIdCopy = (e) => {
        const value = textAreaRef.current.querySelector('input').value;
        navigator.clipboard.writeText(value)
        e.target.focus();

    };

    useEffect(() => {
        const handleFocus = () => {
            setIsTabFocused(true); // Tarayıcı sekmesi odaklandığında state'i güncelle
            if (isEdit) {
                getPointsForFocus();
            }
        };

        const handleBlur = () => {
            setIsTabFocused(false); // Tarayıcı sekmesi odaklanmadığında state'i güncelle
        };

        // Tarayıcı sekme durumunu dinle
        window.addEventListener('focus', handleFocus);
        window.addEventListener('blur', handleBlur);

        // Event listener'ları temizleme
        return () => {
            window.removeEventListener('focus', handleFocus); // Komponentten kaldırıldığında dinleyiciyi kaldır
            window.removeEventListener('blur', handleBlur); // Komponentten kaldırıldığında dinleyiciyi kaldır
        };
    }, [isTabFocused]);

    function getPointsForFocus() {
        api
            .post('flow/get-points')
            .then((response) => {
                if (response.data.status) {
                    if (editData) {
                        setEditData(response.data.result.filter(item => item.id === editData.id)[0]);
                    }

                } else {
                    toast.error(response.data.message);
                }
            })
            .catch((error) => toast.error(error.message));
    }

    const handleDeleteClosedDate = (index) => {
        const updatedDates = [...closedDates];
        updatedDates.splice(index, 1);
        setClosedDates(updatedDates);
    };

    const handleAddClosedDates = () => {
        const dateTimeArr = closedDates

        const dateStartString = closedTimeStart;
        const dateStartObject = new Date(dateStartString);

        const dateEndString = closedTimeEnd;
        const dateEndObject = new Date(dateEndString);

        const options = { timeZone: 'Europe/Istanbul', hour12: false, hour: '2-digit', minute: '2-digit' };
        const formattedStartTime = dateStartObject.toLocaleTimeString('tr-TR', options);
        const formattedEndTime = dateEndObject.toLocaleTimeString('tr-TR', options);

        dateTimeArr.push({ startDate: new Date(startDate).toLocaleDateString('tr-TR'), endDate: new Date(endDate).toLocaleDateString('tr-TR'), startTime: formattedStartTime, endTime: formattedEndTime })
        setClosedDates(dateTimeArr)
        setStartDate(null)
        setEndDate(null)
        setClosedTimeStart(null)
        setClosedTimeEnd(null)
    }

    // const handleUserSelectChange = (selectedUsers) => {
    //     setFormData({
    //         ...formData,
    //         users: selectedUsers
    //     });
    // };
    // const handleRemoveUser = (userId) => {
    //     const updatedUsers = formData.users.filter(user => user.id !== userId);
    //     handleUserSelectChange(updatedUsers);
    // };

    const addPointToUsers = (id, title) => {
        let allUsers = formData.users
        for (let i = 0; i < allUsers.length; i++) {
            let currentAllowedPoints = []
            currentAllowedPoints = allUsers[i].allowedPoints
            currentAllowedPoints.push({ id: id, title: title, firstResponsible: false })
            api
                .post('flow/users-create-employee-allowed-points', {
                    userID: allUsers[i].id,
                    allowedPoints: currentAllowedPoints
                })
                .then((response) => {
                    if (response.data.status) {
                        setShowResultMessage(true)
                        setResultMessage("Nokta başarıyla eklendi.")
                        setIsBackdropOpen(false)
                    } else {
                        setShowResultMessage(true)
                        setResultMessage("Nokta eklenirken bir sorunla karşılaşıldı!")
                        setIsBackdropOpen(false)
                    }
                })
                .catch((error) => {
                    console.log(error);
                    toast.error(error.message);
                });
        }
    }


    const handleSave = () => {
        setIsBackdropOpen(true)
        if (!isEdit) {
            api
                .post('/easypoint-centers/create', {
                    title: formData.title,
                    amazonTitle: formData.amazonHubTitle,
                    amazonAddress1: formData.amazonAddress1,
                    amazonAddress2: formData.amazonAddress2,
                    isMuhaberat: formData.isMuhaberat,
                    isMalKabul: formData.isMalKabul,
                    getirID: formData?.getirID,
                    isEasyPoint: formData.isEasyPoint,
                    //isBox: false,
                    //isEsnaf: formData.selectedBranch === "isEsnaf" ? true : false,
                    //isAmazon: formData.isAmazon,
                    //isActiveOnAmazon: formData.isAmazon,
                    //isTrendyol: formData.isTrendyol,
                    //isActiveOnTrendyol: formData.isTrendyol,
                    //isHepsiburada: formData.isHepsiburada,
                    //isHepsijet: formData.isHepsijet,
                    //isN11: false,
                    packageCapacity: formData.dailyCapacity,
                    phone: formData.phone,
                    city: formData.city,
                    region: formData.region,
                    district: formData.district,
                    postalCode: formData.postCode,
                    muhaberatAddress1: formData.address,
                    muhaberatAddress2: formData.address2,
                    latitude: formData.latitude,
                    longitude: formData.longitude,
                    email: formData.email,
                    floor: formData.floor,
                    mallID: formData.mallID,
                    branchCode: formData.branchCode,
                    subBranchCode: formData.branchSubCode,
                    prefix: formData.prefix,
                    trendyolID: "",
                    amazonID: "",
                    workingDays: formData.workingDays,
                    closedDates: closedDates,
                    status: true,
                })
                .then((response) => {
                    if (response.data.status) {

                        setShowResultMessage(true)
                        setResultMessage("Nokta başarıyla eklendi.")
                        setIsBackdropOpen(false)

                    } else {
                        setShowResultMessage(true)
                        setResultMessage("Nokta eklenirken bir sorunla karşılaşıldı!")
                        setIsBackdropOpen(false)
                    }
                })
                .catch((error) => {
                    console.log(error);
                    toast.error(error.message);
                });
        }
        else {
            api
                .post('/easypoint-centers/update', {
                    id: editData.id,
                    title: formData.title,
                    amazonTitle: formData.amazonHubTitle,
                    amazonAddress1: formData.amazonAddress1,
                    amazonAddress2: formData.amazonAddress2,
                    isBox: false,
                    isMuhaberat: formData.isMuhaberat,
                    isMalKabul: formData.isMalKabul,
                    getirID: formData?.getirID,
                    isEasyPoint: formData.isEasyPoint,
                    isEsnaf: formData.selectedBranch === "isEsnaf" ? true : false,
                    isAmazon: formData.isAmazon,
                    isActiveOnAmazon: formData.isAmazon,
                    isTrendyol: formData.isTrendyol,
                    isActiveOnTrendyol: formData.isTrendyol,
                    isHepsiburada: formData.isHepsiburada,
                    isHepsijet: formData.isHepsijet,
                    isN11: false,
                    packageCapacity: formData.dailyCapacity,
                    phone: formData.phone,
                    city: formData.city,
                    region: formData.region,
                    district: formData.district,
                    postalCode: formData.postCode,
                    muhaberatAddress1: formData.address,
                    muhaberatAddress2: formData.address2,
                    latitude: formData.latitude,
                    longitude: formData.longitude,
                    email: formData.email,
                    floor: formData.floor,
                    mallID: formData.mallID,
                    branchCode: formData.branchCode,
                    subBranchCode: formData.branchSubCode,
                    prefix: formData.prefix,
                    trendyolID: "",
                    amazonID: "",
                    workingDays: formData.workingDays,
                    closedDates: closedDates,
                    status: true,
                })
                .then((response) => {
                    if (response.data.status) {
                        setShowResultMessage(true)
                        setResultMessage("Nokta başarıyla düzenlendi.")
                        setIsBackdropOpen(false)
                    } else {
                        setShowResultMessage(true)
                        setResultMessage("Nokta düzenlenirken bir sorunla karşılaşıldı!")
                        setIsBackdropOpen(false)
                    }
                })
                .catch((error) => {
                    console.log(error);
                    toast.error(error.message);
                });
        }

    }
    //console.log("formData",formData);

    const allFieldsAreValid = () => {
        if (formData.title !== '' && formData.email !== '' && formData.city !== '' && formData.district !== '' && formData.postCode !== '' && formData.dailyCapacity !== '') {
            return true
        }



        // else if (formData.selectedBranch === 'isEasyPoint') {
        //     if (formData.title !== '' && formData.email !== '' && formData.city !== '' && formData.district !== '' && formData.postCode !== '' && formData.dailyCapacity !== '' && formData.selectedBranch !== '' && formData.mallID !== '') {
        //         return true
        //     }
        //     else
        //         return false
        // }
    };

    // const handleSwitchChange = () => {
    //     const { name, value } = event.target;
    //     if (name === "amazonWorkStatus") {
    //         setAmazonWorkStatus(!amazonWorkStatus)
    //     }
    //     else if (name === "trendyolWorkStatus") {
    //         setTrendyolWorkStatus(!trendyolWorkStatus)
    //     }
    //     else if(name === "hepsiburadaWorkStatus"){
    //         setHepsiburadaWorkStatus(!hepsiburadaWorkStatus)
    //     }
    //     else{
    //         setHepsijetWorkStatus(!hepsijetWorkStatus)
    //     }

    // };

    const handleDayChange = (event) => {
        const day = event.target.name;
        const isChecked = event.target.checked;
        let updateWorkingDaysArr = [...workingDays];

        if (isChecked) {
            updateWorkingDaysArr.push(day);
        }
        else {
            updateWorkingDaysArr = updateWorkingDaysArr.filter((item) => item !== day);
        }
        setWorkingDays(updateWorkingDaysArr)
        const updatedFormData = { ...formData };

        updatedFormData.workingDays[day].enable = updatedFormData.workingDays[day]?.enable === "on" ? "off" : "on";
        updatedFormData.workingDays[day].openingTime.hour = "00";
        updatedFormData.workingDays[day].openingTime.minute = "00";

        updatedFormData.workingDays[day].closingTime.hour = "00";
        updatedFormData.workingDays[day].closingTime.minute = "00";

        setFormData(updatedFormData);
    };

    const handleTextFieldChange = (e) => {
        const { name, value } = e.target;
        if (name === "getirID") {
            const newValue = value.replace(/\s/g, '');
            setFormData({
                ...formData,
                [name]: newValue
            });
        } else {
            setFormData({
                ...formData,
                [name]: value
            });
        }

    };


    const handleChangePartners = (event) => {
        setFormData({
            ...formData,
            [event.target.name]: event.target.checked
        });

        if (event.target.name === "isAmazon") {
            setAmazonWorkStatus(false)
        }
        else if (event.target.name === "isTrendyol") {
            setTrendyolWorkStatus(false)
        }
        else if (event.target.name === 'isHepsiburada') {
            setHepsiburadaWorkStatus(false)
        }
        else {
            setHepsijetWorkStatus(false)
        }
    };

    const handleClose = () => {
        setFormData({})
        onClose()
    }

    const handleCloseMessage = () => {
        setShowResultMessage(false)
        setFormData({})
        onClose()
    }

    const handleOpeningTimeChange = (newTime, day) => {
        // Yeni saat değerini formData içindeki ilgili günün openingTime'a atayın
        const updatedFormData = { ...formData };
        updatedFormData.workingDays[day].openingTime.hour = moment(newTime).format('HH');
        updatedFormData.workingDays[day].openingTime.minute = moment(newTime).format('mm');
        setFormData(updatedFormData);
    };

    const handleSwitchChange = (e) => {
        const { checked, name } = e.target;
        console.log("value", checked, name);
        //setCheckGetirId(checkGetirId);
        setFormData({
            ...formData,
            [name]: checked
        });


    }

    const handleClosingTimeChange = (newTime, day) => {
        // Yeni saat değerini formData içindeki ilgili günün closingTime'a atayın
        const updatedFormData = { ...formData };
        updatedFormData.workingDays[day].closingTime.hour = moment(newTime).format('HH');
        updatedFormData.workingDays[day].closingTime.minute = moment(newTime).format('mm');
        setFormData(updatedFormData);
    };

    useEffect(() => {
        setIsBackdropOpen(false)
        if (isEdit) {
            let selectedBranchValue

            if (editData.isEsnaf === "1") {
                selectedBranchValue = "isEsnaf"
            }
            else if (editData.isEasyPoint === "1") {
                selectedBranchValue = "isEasyPoint"
            } else if (editData.isMalKabul === "1") {
                selectedBranchValue = "isMalKabul"
            } else if (editData.isMuhaberat === "1") {
                selectedBranchValue = "isMuhaberat"
            }
            
            const formatedUserList = userList.filter(item2 => editData?.users?.some(item1 => item1.id === item2.id));
            const filteredDays = Object.keys(editData.workingDays).filter(day => editData.workingDays[day].enable === "on");
            setWorkingDays(filteredDays)
            setClosedDates(editData.closedDates)
            editData.users ? setOriginalUserList(formatedUserList) : setOriginalUserList([])
            setFormData({
                users: editData.users ? editData.users : [],
                selectedBranch: selectedBranchValue,

                isMalKabul: editData.isMalKabul === "1" ? true : false,
                isMuhaberat: editData.isMuhaberat === "1" ? true : false,
                getirID: editData.getirID,
                isGetir:editData.getirID?.length > 0 ? true : false,
                isEasyPoint: editData.isEasyPoint === "1" ? true : false,
                //isAmazon: editData.isAmazon === "1" ? true : false,
                //isTrendyol: editData.isTrendyol === "1" ? true : false,
                //isHepsiburada: editData.isHepsiburada === "1" ? true : false,
                //isHepsijet: editData.isHepsijet === "1" ? true : false,
                title: editData.title,
                amazonHubTitle: editData.amazonTitle,
                amazonAddress1: editData.amazonAddress1,
                amazonAddress2: editData.amazonAddress2,
                phone: editData.phone,
                email: editData.email,
                city: editData.city,
                region: editData.region,
                district: editData.district,
                address: editData.muhaberatAddress1,
                address2: editData.muhaberatAddress2,
                postCode: editData.postalCode,
                mallID: editData.mallID,
                floor: editData.floor,
                latitude: editData.latitude,
                longitude: editData.longitude,
                branchCode: editData.branchCode,
                branchSubCode: editData.subBranchCode,
                prefix: editData.prefix,
                dailyCapacity: editData.packageCapacity,
                workingDays: {
                    monday: {
                        enable: editData.workingDays.monday?.enable ?? false,
                        openingTime: {
                            hour: editData.workingDays.monday?.openingTime?.hour ?? 0,
                            minute: editData.workingDays.monday?.openingTime?.minute ?? 0
                        },
                        closingTime: {
                            hour: editData.workingDays.monday?.closingTime?.hour ?? 0,
                            minute: editData.workingDays.monday?.closingTime?.minute ?? 0
                        }
                    },
                    tuesday: {
                        enable: editData.workingDays.tuesday?.enable ?? false,
                        openingTime: {
                            hour: editData.workingDays.tuesday?.openingTime?.hour ?? 0,
                            minute: editData.workingDays.tuesday?.openingTime?.minute ?? 0
                        },
                        closingTime: {
                            hour: editData.workingDays.tuesday?.closingTime?.hour ?? 0,
                            minute: editData.workingDays.tuesday?.closingTime?.minute ?? 0
                        }
                    },
                    wednesday: {
                        enable: editData.workingDays.wednesday?.enable ?? false,
                        openingTime: {
                            hour: editData.workingDays.wednesday?.openingTime?.hour ?? 0,
                            minute: editData.workingDays.wednesday?.openingTime?.minute ?? 0
                        },
                        closingTime: {
                            hour: editData.workingDays.wednesday?.closingTime?.hour ?? 0,
                            minute: editData.workingDays.wednesday?.closingTime?.minute ?? 0
                        }
                    },
                    thursday: {
                        enable: editData.workingDays.thursday?.enable ?? false,
                        openingTime: {
                            hour: editData.workingDays.thursday?.openingTime?.hour ?? 0,
                            minute: editData.workingDays.thursday?.openingTime?.minute ?? 0
                        },
                        closingTime: {
                            hour: editData.workingDays.thursday?.closingTime?.hour ?? 0,
                            minute: editData.workingDays.thursday?.closingTime?.minute ?? 0
                        }
                    },
                    friday: {
                        enable: editData.workingDays.friday?.enable ?? false,
                        openingTime: {
                            hour: editData.workingDays.friday?.openingTime?.hour ?? 0,
                            minute: editData.workingDays.friday?.openingTime?.minute ?? 0
                        },
                        closingTime: {
                            hour: editData.workingDays.friday?.closingTime?.hour ?? 0,
                            minute: editData.workingDays.friday?.closingTime?.minute ?? 0
                        }
                    },
                    saturday: {
                        enable: editData.workingDays.saturday?.enable ?? false,
                        openingTime: {
                            hour: editData.workingDays.saturday?.openingTime?.hour ?? 0,
                            minute: editData.workingDays.saturday?.openingTime?.minute ?? 0
                        },
                        closingTime: {
                            hour: editData.workingDays.saturday?.closingTime?.hour ?? 0,
                            minute: editData.workingDays.saturday?.closingTime?.minute ?? 0
                        }
                    },
                    sunday: {
                        enable: editData.workingDays.sunday?.enable ?? false,
                        openingTime: {
                            hour: editData.workingDays.sunday?.openingTime?.hour ?? 0,
                            minute: editData.workingDays.sunday?.openingTime?.minute ?? 0
                        },
                        closingTime: {
                            hour: editData.workingDays.sunday?.closingTime?.hour ?? 0,
                            minute: editData.workingDays.sunday?.closingTime?.minute ?? 0
                        }
                    }
                },
            })
        }
    }, [editData]);

    return (
        <Modal open={open} onClose={handleClose}>
            <>
                <Backdrop
                    sx={{ color: '#fff', zIndex: 2, height: '100%' }}
                    open={isBackdropOpen}
                >
                    <CircularProgress color="inherit" />
                </Backdrop>
                <Box
                    sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        boxShadow: 24,
                        p: 4,
                        maxWidth: '80%',
                        maxHeight: '90%',
                        borderRadius: 2,
                        overflow: 'auto',
                    }}
                >



                    {isEdit === true ? <DialogTitle>Nokta Düzenle</DialogTitle> : <DialogTitle>Nokta Ekle</DialogTitle>}

                    <DialogContent>

                        <>
                            <Grid item xs={12} container alignItems="center" spacing={0}>

                                <Grid container alignItems="center" spacing={2}>
                                    <Grid item xs={12}>
                                        <FormControlLabel
                                            control={<Checkbox checked={formData.isMalKabul} onChange={handleChangePartners} name="isMalKabul" />}
                                            label="Mal Kabul"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={formData.isMuhaberat} onChange={handleChangePartners} name="isMuhaberat" />}
                                            label="Muhaberat"
                                        />
                                        <FormControlLabel
                                            control={<Checkbox checked={formData.isEasyPoint} onChange={handleChangePartners} name="isEasyPoint" />}
                                            label="Easy Point"
                                        />

                                    </Grid>



                                </Grid>

                                {isEdit &&
                                    <div style={{ flexDirection: 'row', display: 'flex', justifyContent: 'flex-end', alignItems: 'center', marginTop: 10 }}>

                                    </div>
                                }
                                {isEdit === true ? (
                                    <FormControl fullWidth margin="normal">
                                        <TextField
                                            fullWidth
                                            label="Nokta Id"
                                            name="pointListId"
                                            value={editData.id}
                                            margin="normal"
                                            readOnly
                                            ref={textAreaRef}
                                            disabled={true}
                                            InputProps={{
                                                endAdornment: (
                                                    <InputAdornment position="end">
                                                        <Button onClick={handleIdCopy}>
                                                            <ContentCopyIcon color="action" />
                                                        </Button>
                                                    </InputAdornment>
                                                ),
                                            }}
                                        />


                                    </FormControl>
                                ) : ''

                                }
                                {!formData.isAmazon &&
                                    <TextField
                                        fullWidth
                                        label="Nokta Adı *"
                                        name="title"
                                        value={formData.title}
                                        onChange={handleTextFieldChange}
                                        margin="normal"
                                    />
                                }

                                {formData.isAmazon &&
                                    <Grid container alignItems="center" spacing={2}>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                label="Başlık *"
                                                name="title"
                                                value={formData.title}
                                                onChange={handleTextFieldChange}
                                                margin="normal"
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                label="Amazon Hub Başlığı"
                                                name="amazonHubTitle"
                                                value={formData.amazonHubTitle}
                                                onChange={handleTextFieldChange}
                                                margin="normal"
                                            />
                                        </Grid>
                                    </Grid>
                                }



                                {formData.isAmazon &&
                                    <Grid container alignItems="center" spacing={2}>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                label="Amazon Adres-1 (İl, ilçe, posta kodu gibi ek bilgiler yazmayınız) *"
                                                name="amazonAddress1"
                                                value={formData.amazonAddress1}
                                                onChange={handleTextFieldChange}
                                                margin="normal"
                                            />
                                        </Grid>
                                        <Grid item xs={6}>
                                            <TextField
                                                fullWidth
                                                label="Amazon Adres-2 (İl, ilçe, posta kodu gibi ek bilgiler yazmayınız) *"
                                                name="amazonAddress2"
                                                value={formData.amazonAddress2}
                                                onChange={handleTextFieldChange}
                                                margin="normal"
                                            />
                                        </Grid>
                                    </Grid>
                                }


                                <Grid container alignItems="center" spacing={2}>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            label="Telefon"
                                            name="phone"
                                            type='number'
                                            value={formData.phone}
                                            onChange={handleTextFieldChange}
                                            margin="normal"
                                            onInput={(e) => {
                                                let inputValue = e.target.value;
                                                if (inputValue < 0) {
                                                    inputValue = "0"
                                                }
                                                // Eğer ilk karakter 0 ise ve giriş uzunluğu 1'den fazlaysa, ilk karakteri sil
                                                if (inputValue.startsWith('0')) {
                                                    inputValue = inputValue.slice(1);
                                                }
                                                // Maksimum 10 karakter sınırlaması
                                                if (inputValue?.length > 10) {
                                                    inputValue = inputValue.slice(0, 10);
                                                }
                                                // Giriş değeri değiştiyse, yeni değeri güncelle
                                                if (inputValue !== e.target.value) {
                                                    e.target.value = inputValue;
                                                    // State'i güncelle
                                                    setFormData({
                                                        ...formData,
                                                        phone: inputValue
                                                    });
                                                }
                                            }}
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            label="E-Posta *"
                                            name="email"
                                            value={formData.email}
                                            onChange={handleTextFieldChange}
                                            margin="normal"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container alignItems="center" spacing={2}>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            label="İl *"
                                            name="city"
                                            value={formData.city}
                                            onChange={handleTextFieldChange}
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            label="İlçe *"
                                            name="district"
                                            value={formData.district}
                                            onChange={handleTextFieldChange}
                                            margin="normal"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container alignItems="center" spacing={2}>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            label="Adres-1 (İl, ilçe, posta kodu gibi ek bilgiler yazmayınız) *"
                                            name="address"
                                            value={formData.address}
                                            onChange={handleTextFieldChange}
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            label="Adres-2 (Adres devamı)"
                                            name="address2"
                                            value={formData.address2}
                                            onChange={handleTextFieldChange}
                                            margin="normal"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container alignItems="center" spacing={2}>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            label="Mahalle"
                                            name="region"
                                            value={formData.region}
                                            onChange={handleTextFieldChange}
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            label="Posta Kodu *"
                                            name="postCode"
                                            value={formData.postCode}
                                            onChange={handleTextFieldChange}
                                            margin="normal"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container alignItems="center" spacing={2}>
                                    <Grid item xs={6}>
                                        <FormControl fullWidth margin="normal">
                                            <InputLabel>Bulunduğu AVM/Bina/Plaza*</InputLabel>
                                            <Select
                                                label="Bulunduğu AVM/Bina/Plaza*"
                                                value={formData.mallID}
                                                onChange={handleTextFieldChange}
                                                name="mallID"
                                            >
                                                {malls.map((mall) => (
                                                    <MenuItem
                                                        key={mall.id}
                                                        value={mall.id}
                                                    >
                                                        {mall.title}
                                                    </MenuItem>
                                                ))}
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            label="Bulunduğu Kat"
                                            name="floor"
                                            value={formData.floor}
                                            onChange={handleTextFieldChange}
                                            margin="normal"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container alignItems="center" spacing={2}>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            label="Enlem"
                                            name="latitude"
                                            value={formData.latitude}
                                            onChange={handleTextFieldChange}
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            label="Boylam"
                                            name="longitude"
                                            value={formData.longitude}
                                            onChange={handleTextFieldChange}
                                            margin="normal"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container alignItems="center" spacing={2}>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            label="Şube Kodu"
                                            name="branchCode"
                                            value={formData.branchCode}
                                            onChange={handleTextFieldChange}
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            label="Şube Alt Kodu"
                                            name="branchSubCode"
                                            value={formData.branchSubCode}
                                            onChange={handleTextFieldChange}
                                            margin="normal"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid container alignItems="center" spacing={2}>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            label="Prefix (Mal kabul biriminde kullanılacak)"
                                            name="prefix"
                                            value={formData.prefix}
                                            onChange={handleTextFieldChange}
                                            margin="normal"
                                        />
                                    </Grid>
                                    <Grid item xs={6}>
                                        <TextField
                                            fullWidth
                                            label="Günlük Kapasite *"
                                            type='number'
                                            name="dailyCapacity"
                                            value={formData.dailyCapacity}
                                            onChange={handleTextFieldChange}
                                            margin="normal"
                                        />
                                    </Grid>
                                </Grid>
                                <Grid item xs={6} columnGap={2} display={'flex'} alignItems="center" flexDirection="row">
                                    <Typography>Getir</Typography>
                                    <Switch
                                        checked={formData.isGetir}
                                        onChange={handleSwitchChange}
                                        name="isGetir"
                                        color="primary"
                                        
                                    />
                                    {formData.isGetir && 

                                        <TextField
                                            disabled={editData?.getirID?.length > 0}
                                            label="Getir Id*"
                                            name="getirID"
                                            value={formData.getirID}
                                            onChange={handleTextFieldChange}
                                            margin="normal"
                                        />


                                    }
                                </Grid>
                                {isEdit && <>
                                    <Grid container alignItems="center" sx={{ marginTop: '15px' }} spacing={2}>
                                        <Grid item xs={12}>
                                            {formData.users.map((user) => (
                                                <Chip
                                                    icon={user.isFirstResponsible ? <Iconify style={{ color: 'white' }} icon={"iconoir:profile-circle"} /> : <Iconify icon={"mdi:eye-outline"} />}
                                                    key={user.id}
                                                    label={`${user.name} ${user.lastname}`}
                                                    style={{ margin: 2, backgroundColor: user.isFirstResponsible && 'rgb(35 49 179)', color: user.isFirstResponsible ? 'white' : 'inherit' }}
                                                    onClick={() => window.open(`/dashboard/edit-point-user/${user.id}`, '_blank')}
                                                />
                                            ))}
                                        </Grid>
                                    </Grid>
                                    <Grid container alignItems="center" sx={{ marginTop: '5px' }} spacing={2}>
                                        <Grid item xs={12} sx={{ flexDirection: 'row', display: 'flex', alignItems: 'center' }}>
                                            <Chip
                                                icon={<Iconify icon={"iconoir:profile-circle"} />}
                                                label={`Şube Çalışanı`}
                                                style={{ margin: 2, backgroundColor: 'transparent' }}
                                            />
                                            <Chip
                                                icon={<Iconify icon={"mdi:eye-outline"} />}
                                                label={`Şube gönderilerini görme yetkisine sahip`}
                                                style={{ margin: 2, backgroundColor: 'transparent' }}
                                            />
                                        </Grid>
                                    </Grid>
                                </>
                                }

                                <Grid item xs={12} sx={{ marginTop: '10px' }}>
                                    <Typography>Çalışma Gün Ve Saatleri :</Typography>
                                    <FormControl sx={{ marginTop: 2 }} component="fieldset">
                                        <Grid xs={12} container alignItems="center" spacing={2}>
                                            {Object.keys(formData?.workingDays).map((day, index) => (
                                                <>
                                                    <Grid item xs={2}>
                                                        <FormControlLabel
                                                            control={<Checkbox checked={workingDays?.includes(day)} onChange={handleDayChange} name={day} />}
                                                            label={days[index]}
                                                        />
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                                            <TimePicker
                                                                key={day}
                                                                timeSteps={{ minutes: 30 }}
                                                                clearable
                                                                label={`Başlangıç Saati`}
                                                                ampm={false}
                                                                disabled={formData?.workingDays[day]?.enable !== "on"}
                                                                value={moment().hours(parseInt(formData?.workingDays[day]?.openingTime?.hour)).minutes(parseInt(formData?.workingDays[day]?.openingTime?.minute))}
                                                                onChange={(newTime) => handleOpeningTimeChange(newTime, day)}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                                            <TimePicker
                                                                key={day}
                                                                timeSteps={{ minutes: 30 }}
                                                                clearable
                                                                label={`Bitiş Saati`}
                                                                ampm={false}
                                                                disabled={formData?.workingDays[day]?.enable !== "on"}
                                                                value={moment().hours(parseInt(formData.workingDays[day].closingTime.hour)).minutes(parseInt(formData.workingDays[day].closingTime.minute))}
                                                                onChange={(newTime) => handleClosingTimeChange(newTime, day)}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                </>
                                            ))}

                                        </Grid>
                                    </FormControl>
                                </Grid>

                                {isEdit &&
                                    <>
                                        <Grid item xs={12} sx={{ marginTop: '15px' }}>
                                            <Typography>Kapalı Olduğu Gün Ve Saatler :</Typography>
                                            <FormControl sx={{ marginTop: 2 }} component="fieldset">
                                                <Grid xs={12} container alignItems="center" spacing={2}>
                                                    <Grid item xs={3}>
                                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                                            <DatePicker
                                                                sx={{ marginRight: '8px' }}
                                                                format="DD/MM/YYYY"
                                                                label="Başlangıç Tarihi"
                                                                value={startDate}
                                                                onChange={(newValue) => setStartDate(newValue)}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs={3}>
                                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                                            <DatePicker
                                                                sx={{ marginRight: '8px' }}
                                                                format="DD/MM/YYYY"
                                                                label="Bitiş Tarihi"
                                                                value={endDate}
                                                                onChange={(newValue) => setEndDate(newValue)}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                                            <TimePicker
                                                                timeSteps={{ minutes: 30 }}
                                                                label="Başlangıç Saati"
                                                                clearable
                                                                ampm={false}
                                                                value={closedTimeStart}
                                                                onChange={setClosedTimeStart}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <LocalizationProvider dateAdapter={AdapterMoment}>
                                                            <TimePicker
                                                                timeSteps={{ minutes: 30 }}
                                                                label="Bitiş Saati"
                                                                clearable
                                                                ampm={false}
                                                                value={closedTimeEnd}
                                                                onChange={setClosedTimeEnd}
                                                            />
                                                        </LocalizationProvider>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                        <Button
                                                            color="primary"
                                                            variant="contained"
                                                            disabled={!startDate || !endDate || !closedTimeStart || !closedTimeEnd}
                                                            onClick={handleAddClosedDates}
                                                        >
                                                            Ekle
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </FormControl>
                                        </Grid>

                                        {closedDates?.length > 0 &&
                                            <Grid container alignItems="center" spacing={2} sx={{ marginTop: '15px' }}>
                                                {closedDates.map((date, index) => (
                                                    <Grid item xs={4}>
                                                        <Chip
                                                            key={index}
                                                            label={`${date.startDate} - ${date.endDate}, ${date.startTime} - ${date.endTime}`}
                                                            onDelete={() => handleDeleteClosedDate(index)}
                                                            color="default"
                                                            variant="outlined"
                                                            deleteIcon={<CancelIcon style={{ color: 'red' }} />}
                                                        />
                                                    </Grid>
                                                ))}
                                            </Grid>
                                        }
                                    </>
                                }

                            </Grid>
                        </>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Geri
                        </Button>
                        <Button
                            color="primary"
                            onClick={handleSave}
                            variant="contained"
                            disabled={!allFieldsAreValid()}
                        >
                            Kaydet
                        </Button>
                    </DialogActions>
                </Box>

                <Dialog
                    open={showResultMessage}
                    onClose={handleCloseMessage}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description"
                >
                    {isEdit === true ?
                        <DialogTitle id="alert-dialog-title">{"Nokta Düzenleme"}</DialogTitle>
                        :
                        <DialogTitle id="alert-dialog-title">{"Nokta Ekleme"}</DialogTitle>
                    }
                    <DialogContent>
                        <DialogContentText id="alert-dialog-description">
                            {resultMessage}
                        </DialogContentText>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleCloseMessage}>Tamam</Button>
                    </DialogActions>
                </Dialog>
            </>
        </Modal >





    );
};

export default AddEditPointModal;
