export const userTypes = [
  { label: 'Mağaza Kullanıcısı', value: 'corporational' },
  { label: 'Porter', value: 'porter' },
  { label: 'Yüksek Admin', value: 'highadmin' },
  { label: 'Admin', value: 'admin' },
  { label: 'Muhaberat', value: 'muhaberat' },
  { label: 'AVM Yönetim', value: 'mall-administration' },
  { label: 'API Kullanıcısı', value: 'api-user' },
  { label: 'Muhasebe', value: 'accountant' },
  { label: 'İnsan Kaynakları', value: 'human-resource' },
];
