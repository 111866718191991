// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("./dual.gif", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loading:before {
  content: '';
  background: #fafbfbb3;
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
}

.loading:after {
  content: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
  position: fixed;
  width: 100%;
  top: 50%;
  left: 0;
  z-index: 1001;
  color: black;
  text-align: center;
  font-weight: bold;
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.15);
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
`, "",{"version":3,"sources":["webpack://./src/utils/loading.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,qBAAqB;EACrB,eAAe;EACf,WAAW;EACX,YAAY;EACZ,MAAM;EACN,OAAO;EACP,aAAa;AACf;;AAEA;EACE,gDAA0B;EAC1B,eAAe;EACf,WAAW;EACX,QAAQ;EACR,OAAO;EACP,aAAa;EACb,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;AACnB;;AAEA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,QAAQ;EACR,+BAA+B;EAC/B,UAAU;EACV,aAAa;EACb,mBAAmB;EACnB,uBAAuB;AACzB","sourcesContent":[".loading:before {\n  content: '';\n  background: #fafbfbb3;\n  position: fixed;\n  width: 100%;\n  height: 100%;\n  top: 0;\n  left: 0;\n  z-index: 1000;\n}\n\n.loading:after {\n  content: url('./dual.gif');\n  position: fixed;\n  width: 100%;\n  top: 50%;\n  left: 0;\n  z-index: 1001;\n  color: black;\n  text-align: center;\n  font-weight: bold;\n}\n\n.loading {\n  position: absolute;\n  top: 0;\n  left: 0;\n  right: 0;\n  background: rgba(0, 0, 0, 0.15);\n  z-index: 1;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
