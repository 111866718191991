import * as React from 'react';
import Box from '@mui/material/Box';
import Scrollbar from '../components/scrollbar';
import SendIcon from '@mui/icons-material/Send';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { WarningOutlined } from '@mui/icons-material';
import {
  Card,
  Stack,
  Container,
  Typography,
  Button,
  Divider,
  Modal,
  TextField,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import api from 'src/services/BaseService';
import { HotTable } from '@handsontable/react';
import 'handsontable/dist/handsontable.full.min.css';
import { registerAllModules } from 'handsontable/registry';
// import jsPDF from 'jspdf';
// import 'jspdf-autotable';
import moment from 'moment';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import towers from '../towers.json';
import { tr } from 'date-fns/locale';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';


registerAllModules();
pdfMake.vfs = pdfFonts.pdfMake.vfs;

export default function FullFeaturedCrudGrid() {
  const mall = useSelector((state) => state.customerReducer);
  const hotRef = React.useRef(null);
  const initialRows = Array.from({ length: 50 }, (_, index) => ({
    id: index + 1,
    barcode: '',
    name: '',
    corporation: '',
    tower: '',
    no: '',
    shipmentFirm: '',
    direction: '',
    packageCount: '',
    corporationID: '',
    // fromWhere: '',
  }));
  const columnHeaders = [
    'No',
    'Barkod',
    'Kişi',
    'Firma',
    'Kule',
    'Daire',
    'Kargo Firma',
    'Yön',
    'Adet',
    'Firma ID',
    // We need an extra field like below
    // 'Nereden Geldi ?',
  ];
  const cargoList = [
    'Aras Kargo',
    'MNG Kargo',
    'PTT Kargo',
    'Sürat Kargo',
    'UPS',
    'Yurtiçi Kargo',
    'Trendyol Express',
    'Hepsi Jet',
    'Kolaygelsin',
    'Kargoist',
    'Sendeo',
    'DHL',
    'Fedex',
    'Birgünde Kargo',
    'Beymen',
    'Kargom Sende',
    'Scotty',
    'AGT',
    'Bovo',
    'Bolt',
    'Diğer',
  ];
  const [towerList, setTowerList] = React.useState([]);
  React.useEffect(() => {

    if (mall?.customer?.id === "61973122632916808660") {
      setTowerList([
        'R1',
        'R2',
        'R3',
        'T0',
        'T1',
        'T2',
        'T3',
        'T4',
        'T5',
        'OF0',
        'OF1',
        'OF2',
        'OF3',
        'OF4',
        'OF5',
      ]);
    } else {
      setTowerList([]);
    }
  }, [mall]);




  const directionList = ['Gelen Kargo', 'Giden Kargo'];

  console.log("name", mall);
  const [customerList, setCustomerList] = React.useState([]);
  const [offices, setOffices] = React.useState([]);
  const [IsTowerSelectReadOnly, setIsTowerSelectReadOnly] =
    React.useState(true);
  const [IsOfficeSelectReadOnly, setIsOfficeSelectReadOnly] =
    React.useState(true);
  const columnSettings = [
    {
      readOnly: true,
      width: 50,
      className: 'htCenter htMiddle',
    },
    {
      strict: false,
      className: 'htCenter htMiddle',
      width: 150,
    },
    {
      strict: false,
      className: 'htCenter htMiddle',
      width: 200,
    },
    {
      type: 'autocomplete',
      strict: true,
      className: 'htCenter htMiddle',
      width: 150,
      source: customerList?.map((option) => option?.value),
    },
    {
      type: 'autocomplete',
      // readOnly: IsTowerSelectReadOnly,
      strict: true,
      className: 'htCenter htMiddle',
      width: 50,
      source: towerList,
    },
    {
      type: 'autocomplete',
      // readOnly: IsOfficeSelectReadOnly,
      strict: true,
      className: 'htCenter htMiddle',
      width: 100,
      source: offices,
    },
    {
      className: 'htCenter htMiddle',
      width: 100,
      type: 'autocomplete',
      strict: true,
      source: cargoList,
    },
    {
      className: 'htCenter htMiddle',
      width: 102,
      type: 'autocomplete',
      strict: true,
      source: directionList,
    },
    {
      strict: false,
      className: 'htCenter htMiddle',
      width: 50,
    },
    {
      readOnly: true,
      strict: false,
      visibleRows: 50,
      className: 'htCenter htMiddle',
      width: 200,
    },
    // {
    //   readOnly: true,
    //   strict: false,
    //   visibleRows: 50,
    //   className: 'htCenter htMiddle',
    //   width: 100,
    // },
  ];
  const [isSuccessModalOpen, setSuccessModalOpen] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState('');
  const [tableData, setTableData] = React.useState();
  const [failedRowsCount, setFailedRowsCount] = React.useState();
  const [controlModalOpen, setControlModalOpen] = React.useState(false);
  const [smsModal, setSmsModal] = React.useState(false);
  const [code, setCode] = React.useState('');
  const [muhaberatID, setMuhaberatID] = React.useState('');

  const createPDF = () => {
    const table = hotRef.current.hotInstance.getData();
    const nonEmptyRows = table
      .filter((row) => {
        const slicedRow = row.slice(1, -1);
        return slicedRow.every((cell, index) => {
          // Skip checking row[4] and row[5]
          if (index === 3 || index === 4) return true;
          return cell !== '' && cell !== null;
        });
      })
      .map((row) => {
        const corporational = row[3];
        const cargoFirmName = row[6];
        let direction = '';
        if (row[7] === 'Giden Kargo') {
          direction = 'CustomerToMallLogistics';
        } else if (row[7] === 'Gelen Kargo') {
          direction = 'MallLogisticsToCustomer';
        } else {
          direction = '';
        }
        const matchingCustomer = customerList.find(
          (item) => item.value === corporational
        );
        const matchingCargo = cargoList.find((item) => item === cargoFirmName);
        // const fromWhere = row[6]
        if (matchingCustomer && matchingCargo) {
          return [
            row[0].toString(),
            row[1],
            row[2],
            row[3],
            row[4],
            row[5].toString(),
            row[6],
            row[7],
            row[8],
            row[9],
          ];
        }
      })
      .filter(Boolean);

    if (nonEmptyRows?.length > 0) {
      const headerText = `Zorlu Center - ${moment().format(
        'DD.MM.YYYY HH:mm'
      )} -  ${nonEmptyRows.length} Adet`;
      const docDefinition = {
        content: [
          { text: headerText, style: 'header' },
          {
            table: {
              headerRows: 1,
              widths: [
                'auto',
                'auto',
                'auto',
                'auto',
                'auto',
                'auto',
                'auto',
                'auto',
                'auto',
                'auto',
              ],
              body: [columnHeaders, ...nonEmptyRows],
              alignment: 'center',
              margin: ['auto'],
            },
          },
          { text: '\nTeslim Alan:', margin: [0, 10, 0, 0] },
          { text: 'imza:', margin: [0, 10, 0, 0] },
        ],
        styles: {
          header: {
            fontSize: 18,
            bold: true,
            margin: [0, 0, 0, 10],
            alignment: 'center',
          },
        },
      };

      const pdfDocGenerator = pdfMake.createPdf(docDefinition);
      pdfDocGenerator.open();
      pdfDocGenerator.getBlob((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `Muhaberat_${moment().format('DD-MM-YYYY_HH:mm')}.pdf`;
        a.click();
        URL.revokeObjectURL(url);
      });
    } else {
      openSuccessModal('Lütfen boş alan bırakmayınız', true);
    }
    setControlModalOpen(false);
  };

  function removeTowerAndOffice(data) {
    const updatedData = data.map((item) => {
      if (!item.companyName?.toLowerCase()?.includes('residance')) {
        return { ...item, tower: '', office: '' };
      }
      return item;
    });
    return updatedData;
  }

  const handleCreate = () => {
    //sms or notification service will be added
    const table = hotRef.current.hotInstance.getData();
    let nonEmptyRows = table
      .filter((row) => {
        const slicedRow = row.slice(1, -1);
        return slicedRow.every((cell, index) => {
          // Skip checking row[4] and row[5]
          if (index === 3 || index === 4) return true;
          return cell !== '' && cell !== null;
        });
      })
      .map((row) => {
        const customerName = row[2];
        const corporational = row[3];
        const cargoFirmName = row[6];
        let direction = '';
        if (row[7] === 'Giden Kargo') {
          direction = 'CustomerToMallLogistics';
        } else if (row[7] === 'Gelen Kargo') {
          direction = 'MallLogisticsToCustomer';
        } else {
          direction = '';
        }
        const matchingCustomer = customerList.find(
          (item) => item.value === corporational
        );
        // console.log('matchingCustomer: ', matchingCustomer);
        const matchingCargo = cargoList.find((item) => item === cargoFirmName);
        if (matchingCustomer && matchingCargo) {
          return {
            barcode: row[1],
            shipmentFirm: cargoFirmName,
            isMultipleTransportation: false,
            orderInventory: 1,
            isOnlyCreateJob: true,
            type: 'post-office',
            isPhysicalBarcode: true,
            status: 5,
            direction: direction,
            transportationTool: 'Ring',
            customer: matchingCustomer.id,
            from:
              row[7] === 'Giden Kargo'
                ? matchingCustomer.locationID
                : muhaberatID,
            to:
              row[7] === 'Giden Kargo'
                ? muhaberatID
                : matchingCustomer.locationID,
            sentToEasyPoint: muhaberatID,
            customerName: customerName,
            index: row[0],
            companyName: row[3],
            tower: row[4],
            office: row[5],
            packageCount: row[8],
            requestedInventoryCount: row[8],
            orderInventory: row[8],
          };
        }
      })
      .filter(Boolean);
    nonEmptyRows = removeTowerAndOffice(nonEmptyRows);

    // console.log('nonEmptyRows', nonEmptyRows);
    if (nonEmptyRows?.length > 0) {
      api
        .post('mall-logistics/create', nonEmptyRows)
        .then((response) => {
          // console.log(response, '---');
          const numJobsCreated = nonEmptyRows.length;
          if (response.data.status === true) {
            const data = hotRef.current.hotInstance.getData();
            const newData = data.map((row) => {
              const matchingRow = nonEmptyRows.find(
                (nonEmptyRow) => nonEmptyRow?.index === row[0]
              );
              if (matchingRow) {
                return [row[0], '', '', '', '', '', '', '', '', ''];
              }
              return row;
            });
            const filledRowsCount = newData.filter((row) =>
              row.slice(1).some((cell) => cell !== '')
            ).length;
            openSuccessModal(
              `Toplam ${numJobsCreated} adet iş oluştu.`,
              filledRowsCount
            );
            setTableData(newData);
          } else {
            openSuccessModal('Lütfen boş alan bırakmayınız', true);
          }
        })
        .catch((error) => {
          // console.log(error);
        });
    } else {
      openSuccessModal('Lütfen boş alan bırakmayınız', true);
    }
  };

  const openSuccessModal = (message, count) => {
    setSuccessMessage(message);
    setFailedRowsCount(count);
    setSuccessModalOpen(true);
  };

  React.useEffect(() => {
    const mall = localStorage.getItem('mallID');
    api
      .post('flow/get-malls-branches', {
        mallID: mall,
      })
      .then((response) => {
        if (response.data.status) {
          const result = response.data.result;
          const processedDataList = result?.map((item) => ({
            value: item.title,
            id: item.branchID,
            corporationID: item.corporationID ? item.corporationID : '-',
            locationID: item.locations[0].locationID,
          }));
          setCustomerList(processedDataList);
        } else {
          toast.error(
            'Müşteri listesi çekilirken hata oluştu, bağlantınızı kontrol ediniz.'
          );
          setCustomerList([]);
        }
      })
      .catch(() => {
        toast.error(
          'Müşteri listesi çekilirken hata oluştu, bağlantınızı kontrol ediniz.'
        );
        setCustomerList([]);
      });

    api
      .post('/malls/get-malls', {
        withOffices: true,
      })
      .then((res) => {
        // console.log(res.data);
        if (res.data.status) {
          const foundMall = res.data.result.find((item) => item.id === mall);

          if (foundMall) {
            // console.log('foundMall: ', foundMall);
            const foundOffice = foundMall.offices.find(
              (item) => item.isMuhaberat === '1'
            );
            setMuhaberatID(foundOffice?.id);
          }
        } else {
          toast.error(
            "AVM'nizin bilgileri çekilirken hata oluştu, bağlantınızı kontrol ediniz."
          );
          setMuhaberatID('');
        }
      })
      .catch((err) => {
        toast.error(
          "AVM'nizin bilgileri çekilirken hata oluştu, bağlantınızı kontrol ediniz."
        );
        setMuhaberatID('');
      });
  }, []);

  React.useEffect(() => {
    if (hotRef.current) {
      const hotInstance = hotRef.current.hotInstance;
      hotInstance.addHook('afterChange', onAfterChange);
      hotInstance.updateSettings({
        data: initialRows.map((row) => [
          row.id,
          row.barcode,
          row.name,
          row.corporation,
          row.tower,
          row.no,
          row.shipmentFirm,
          '',
          row.packageCount,
          row.corporationID,
        ]),
        columns: columnSettings,
      });
    }
  }, [hotRef, customerList]);

  const onAfterChange = (changes, source) => {
    const hotInstance = hotRef.current.hotInstance;


    const dataToSet = [];
    changes?.forEach(([row, col, prevValue, newValue]) => {
      const currentValue = hotInstance.getDataAtCell(row, col);
      if (col === 3 && newValue !== '') {
        dataToSet.push([row, 8, 1]);
        if (newValue !== null) {
          if (newValue?.includes('Residance')) {
            setIsTowerSelectReadOnly(false);
          } else {
            // setIsTowerSelectReadOnly(true);
            // setIsOfficeSelectReadOnly(true);
            // setOffices([]);
            //hotInstance.setDataAtCell(row, 4, '');
            const towerColumn = hotInstance.getDataAtCell(row, 4);
            if (newValue !== prevValue && towerColumn !== '') {
              dataToSet.push([row, 4, ''])
            }
          }
        } else {

          setIsTowerSelectReadOnly(true);
          setIsOfficeSelectReadOnly(true);
          setOffices([]);
          dataToSet.push([row, 4, '']);
          dataToSet.push([row, 5, '']);

          //hotInstance.setDataAtCell(row, 4, '');
          //hotInstance.setDataAtCell(row, 5, '');
        }
        const matchingCustomer = customerList?.find(
          (item) => item.value === newValue
        );
        if (matchingCustomer) {
          //hotInstance.setDataAtCell(row, 9, matchingCustomer?.corporationID);
          dataToSet.push([row, 9, matchingCustomer?.corporationID]);
        }
      } else if (col === 3) {
        if (newValue !== currentValue) { dataToSet.push([row, 9, '']) }

        //hotInstance.setDataAtCell(row, 9, '');
      }
      else if (col === 4 && newValue !== '' && newValue !== null && newValue) {
        if (newValue !== currentValue) { dataToSet.push([row, 5, '']) }

        //hotInstance.setDataAtCell(row, 5, '');
        setIsOfficeSelectReadOnly(false);
        setOffices([]);
        let type = 'A';
        if (mall?.customer?.id === "61973122632916808660") {


          if (newValue === 'R1' || newValue === 'R2' || newValue === 'R3') {
            type = 'kule';
            setOffices(towers[type][newValue]);
          }
          else if (
            newValue === 'T0' ||
            newValue === 'T1' ||
            newValue === 'T2' ||
            newValue === 'T3' ||
            newValue === 'T4' ||
            newValue === 'T5'
          ) {
            type = 'teras';
            setOffices(towers[type][newValue]);
          }
          else if (
            newValue === 'OF0' ||
            newValue === 'OF1' ||
            newValue === 'OF2' ||
            newValue === 'OF3' ||
            newValue === 'OF4' ||
            newValue === 'OF5'
          ) {
            type = 'ofis';
            setOffices(towers[type][newValue]);
          }
        }
      }
      else if ((newValue === '' || newValue === null) && col === 4) {
        setIsOfficeSelectReadOnly(true);
        setOffices([]);
        dataToSet.push([row, 5, ''])

        //hotInstance.setDataAtCell(row, 5, '');
      }

    });

    if (dataToSet.length > 0) {
      hotInstance.setDataAtCell(dataToSet);
    }
  };

  return (
    <>
      <Helmet>
        <title> Muhaberat | Mall Logistics Panel </title>
      </Helmet>

      <Stack style={{ paddingInline: '25px' }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Muhaberat Giriş
          </Typography>

          <div>
            <Button
              onClick={() => {
                handleCreate();
              }}
              variant="contained"
              endIcon={<SendIcon />}
              sx={{ marginRight: '12px' }}
            >
              İş Oluştur
            </Button>
            <Button
              onClick={() => {
                setControlModalOpen(true);
              }}
              variant="contained"
              endIcon={<SendIcon />}
            >
              KTF İle Teslim Al
            </Button>
          </div>
        </Stack>

        <Card>
          <Scrollbar>
            <Box sx={{ width: '100%' }}>
              <HotTable
                ref={hotRef}
                rowHeights={35}
                licenseKey="non-commercial-and-evaluation"
                data={tableData}
                persistentState={true}
                colHeaders={columnHeaders}
                columns={columnSettings}
                stretchH="all"
              />
            </Box>
          </Scrollbar>
        </Card>
        <Modal
          open={isSuccessModalOpen}
          onClose={() => {
            setSuccessModalOpen(false);
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              width: 400,
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              textAlign: 'center',
            }}
          >
            {successMessage.includes('Lütfen boş alan bırakmayınız') ? (
              <WarningOutlined sx={{ color: 'orange', fontSize: 64 }} />
            ) : (
              <CheckCircleIcon sx={{ color: 'green', fontSize: 64 }} />
            )}
            <Typography variant="h6">{successMessage}</Typography>
            {failedRowsCount > 0 && (
              <>
                <Divider sx={{ my: 2, backgroundColor: 'gray' }} />{' '}
                <Typography variant="h6">
                  Uyarı: {failedRowsCount} satırınızın verileri doldurulmadığı
                  için bu satırlarda iş oluşturulamadı.
                </Typography>
              </>
            )}
            <Button
              onClick={() => {
                setSuccessModalOpen(false);
                if (!successMessage.includes('Lütfen boş alan bırakmayınız')) {
                }
              }}
            >
              Tamam
            </Button>
          </Box>
        </Modal>

        <Modal
          open={controlModalOpen}
          onClose={() => setControlModalOpen(false)}
        >
          <Box
            sx={{
              position: 'absolute',
              width: 400,
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              textAlign: 'center',
            }}
          >
            <Typography variant="h6">Teslimat Yöntemi Seçin</Typography>
            <Button onClick={() => setSmsModal(true)}>SMS ile Teslim Al</Button>
            <Button
              onClick={() => {
                handleCreate();
                createPDF();
              }}
            >
              Belge ile Teslim Al
            </Button>
          </Box>
        </Modal>

        <Modal open={smsModal} onClose={() => setSmsModal(false)}>
          <Box
            sx={{
              position: 'absolute',
              width: 400,
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              textAlign: 'center',
            }}
          >
            <Typography variant="h6">Kodu Giriniz</Typography>
            <TextField
              label="Kod"
              variant="outlined"
              value={code}
              onChange={(e) => setCode(e.target.value)}
              sx={{ my: 2 }}
            />
            <div>
              <Button>Teslim Al</Button>
            </div>
          </Box>
        </Modal>
      </Stack>
    </>
  );
}
