import React, { useState } from 'react';
import {
  Grid,
  Typography,
  InputLabel,
  Select,
  FormControl,
  Stack,
  Button,
  MenuItem,
  Backdrop,
  CircularProgress,
  DialogTitle,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import api from 'src/services/BaseService';

const AddLocationForStoreModal = ({
  open,
  onClose,
  branchID,
  mallID,
  refreshPage,
}) => {
  const [showResultMessage, setShowResultMessage] = useState(false);
  const [resultMessage, setResultMessage] = useState('');
  const [isBackdropOpen, setIsBackdropOpen] = useState(false);
  const [locationType, setLocationType] = useState('');
  const [formData, setFormData] = useState({
    locationName: '',
    address: '',
  });

  const typeList = [
    { name: 'Mağaza', value: 'store' },
    { name: 'Depo', value: 'warehouse' },
  ];

  const handleCloseMessage = () => {
    setShowResultMessage(false);
    setFormData({});
    onClose();
  };

  const handleTextFieldChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const allFieldsAreValid = () => {
    if (formData.locationName !== '' && locationType !== '') {
      return true;
    } else return false;
  };

  const createLocation = () => {
    setIsBackdropOpen(true);
    api
      .post('/flow/location-create', {
        title: formData.locationName,
        mallID: mallID,
        corporationID: '',
        branchID: branchID,
        type: locationType,
        adminUserID: '',
        address: formData.address,
      })
      .then((response) => {
        if (response.data.status) {
          setResultMessage('Lokasyon başarıyla eklendi!');
          setShowResultMessage(true);
          setIsBackdropOpen(false);
          refreshPage();
          // console.log("Başarıyla eklendi")
        } else {
          setResultMessage('Lokasyon eklenirken bir sorunla karşılaşıldı!');
          setShowResultMessage(true);
          // console.log("Eklenirken bir hata oluştu",response.data)
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  };

  return (
    <>
      <Dialog open={open} maxWidth="800px">
        <Backdrop sx={{ color: '#fff', zIndex: 2 }} open={isBackdropOpen}>
          <CircularProgress color="inherit" />
        </Backdrop>

        <DialogContent>
          <Typography sx={{ color: '#6B7280', fontWeight: 600, fontSize: 18 }}>
            Lokasyon Ekle
          </Typography>
          <div
            style={{
              height: '1px',
              backgroundColor: '#EAECF0',
              marginBottom: '32px',
              marginTop: '15px',
              width: '726px',
            }}
          ></div>
          <Grid xs={16} container alignItems="flex-end" spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Lokasyon Adı*"
                name="locationName"
                value={formData.locationName}
                onChange={handleTextFieldChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <FormControl fullWidth margin="normal">
                <InputLabel>Lokasyon Türü*</InputLabel>
                <Select
                  sx={{ width: '100%' }}
                  label="Lokasyon Türü*"
                  value={locationType}
                  onChange={(e) => setLocationType(e.target.value)}
                  name="locationType"
                >
                  {typeList.map((type, index) => (
                    <MenuItem key={index} value={type.value}>
                      {type.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          </Grid>
          <Grid
            xs={16}
            container
            alignItems="flex-end"
            marginTop="5px"
            spacing={2}
          >
            <Grid item xs={16}>
              <TextField
                fullWidth
                label="Adres Bilgisi"
                name="address"
                value={formData.address}
                onChange={handleTextFieldChange}
                margin="normal"
              />
            </Grid>
          </Grid>

          <div
            style={{
              height: '1px',
              backgroundColor: '#EAECF0',
              marginBottom: '32px',
              marginTop: '40px',
              width: '710px',
              marginLeft: '8px',
            }}
          ></div>

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            mb={5}
          >
            <Button
              color="primary"
              onClick={onClose}
              variant="outlined"
              sx={{
                color: '#000',
                borderColor: '#ccc',
                '&:hover': { borderColor: '#999' },
                marginTop: '32px',
                width: '241px',
                height: '46px',
                marginRight: '25px',
                fontSize: 12,
                fontWeight: 500,
              }}
            >
              İŞLEMİ İPTAL ET
            </Button>
            <Button
              color="primary"
              disabled={!allFieldsAreValid()}
              onClick={createLocation}
              variant="contained"
              sx={{
                marginTop: '32px',
                width: '241px',
                height: '46px',
                backgroundColor: '#1570EF',
                fontSize: 12,
                fontWeight: 500,
              }}
            >
              LOKASYONU EKLE
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>

      <Dialog
        open={showResultMessage}
        onClose={handleCloseMessage}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Lokasyon Ekleme'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {resultMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMessage}>Tamam</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddLocationForStoreModal;
