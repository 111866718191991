import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useSelector } from 'react-redux';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  TextField,
  TableContainer,
  TablePagination,
  FormControl,
  Select,
  OutlinedInput,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// components
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead } from '../sections/@dashboard/user';
import api from 'src/services/BaseService';
import './muhaberatOutput.css';
// ----------------------------------------------------------------------
import { StatusList } from 'src/types/statusTypes';
import { CreatedUsersTypes } from 'src/types/createdUsersTypes';

const TABLE_HEAD = [
  { id: 'no', label: 'No', alignRight: false },
  { id: 'corporation', label: 'Firma', alignRight: false },
  { id: 'deliverValue', label: 'Durumu', alignRight: false },
  { id: 'packageCount', label: 'Paket Sayısı', alignRight: false },
  { id: 'dataEntranceType', label: 'Emir Tipi', alignRight: false },
  { id: 'shipmentFirm', label: 'Kargo Firması', alignRight: false },
  { id: 'createdDateTime', label: 'Oluşturulma Tarihi', alignRight: false },
  { id: 'createdTime', label: 'Oluşturulma Saati', alignRight: false },
  { id: 'completedDateTime', label: 'Tamamlanma Tarihi', alignRight: false },
  { id: 'completedTime', label: 'Tamamlanma Saati', alignRight: false },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis?.map((el) => el[0]);
}

export default function AllActionsPage() {
  const customer = useSelector((state) => state.customerReducer);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [allDatas, setAllDatas] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();
  const [selectedCargoStatus, setSelectedCargoStatus] = useState([]);
  const [firm, setFirm] = useState('');
  const [filteredData, setFilteredData] = useState([]);

  // Service

  function getFilteredOrderList() {
    // console.log('mallID: ', customer?.customer?.id);
    // // console.log(selectedCargoStatus);
    api
      .post('posts/get', {
        mallID: customer?.customer?.id,
        limit: 10000,
        status:
          selectedCargoStatus && selectedCargoStatus?.length > 0
            ? selectedCargoStatus?.map((x) => x.id)
            : StatusList.filter((x) => x.id != 0).map((x) => x.id),
        startDate: startDate
          ? moment(startDate).format('DD-MM-YYYY')
          : startDate,
        endDate: endDate ? moment(endDate).format('DD-MM-YYYY') : endDate,
      })
      .then((response) => {
        if (response.data.status) {
          // console.log(response.data);
          setOrderList(response.data.result);
          setAllDatas(response.data.result);
          setFilteredData(response.data.result);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => toast.error('Bir hata oluştu'));
  }

  useEffect(() => {
    getFilteredOrderList();
  }, [customer]);

  function clearFilters() {
    setStartDate(null);
    setEndDate(null);
    setSelectedCargoStatus([]);
    setFirm('');
    getFilteredOrderList();
    setPage(0);
  }

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedCargoStatus(value);
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const filterByFirm = (text) => {
    const firmName = text.toLowerCase();
    const filteredOrders = allDatas.filter((item) =>
      item.branchTitle.toLowerCase().includes(firmName)
    );
    setFirm(firmName);
    setOrderList(filteredOrders);
    setFilteredData(filteredOrders);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orderList?.length) : 0;

  const filteredOrders = applySortFilter(
    filteredData,
    getComparator(order, orderBy),
    filterName
  );

  const isNotFound = !filteredOrders?.length && !!filterName;

  const getStatusLabel = (statusValue) => {
    const statusObject = StatusList.find((status) => status.id === statusValue);
    return statusObject ? statusObject.label : '';
  };

  const getUserType = (userValue) => {
    const userObject = CreatedUsersTypes.find(
      (user) => user.value === userValue
    );
    return userObject ? userObject.label : userValue;
  };

  function exportToExcel() {
    let temp1 = filteredData.map((x, index) => {
      let statusLabel = getStatusLabel(+x.status);
      let dataEntranceType =
        x.dataEntranceType === 'Mall Logistics API'
          ? `App (${getUserType(x.createdBy)})`
          : 'Entegrasyon';
      let shipmentFirm = x.shipmentFirm !== '' ? x.shipmentFirm : 'Nakliye';
      let createdDateTime = moment.unix(x.createdDateTime).format('DD/MM/YYYY');
      let createdDateHour = moment.unix(x.createdDateTime).format('HH:mm');
      let completedDateTime =
        x.status === '1' || x.status === '17' || x.status === '21'
          ? moment
              .unix(x.logs[x.logs?.length - 1].createdDateTime)
              .format('HH:mm')
          : '';
      let completedDateHour =
        x.status === '1' || x.status === '17' || x.status === '21'
          ? moment
              .unix(x.logs[x.logs?.length - 1].createdDateTime)
              .format('DD/MM/YYYY')
          : '';

      let a = {
        id: index + 1,
        firm: x.branchTitle,
        state: statusLabel,
        packageCount: x.orderInventory,
        dataEntranceType: dataEntranceType,
        shipmentFirm: shipmentFirm,
        createdDateTime: createdDateTime,
        createdDateHour: createdDateHour,
        completedDateTime: completedDateTime,
        completedDateHour: completedDateHour,
      };
      return a;
    });

    // console.log('excel: ', temp1);

    api
      .post('excel/export', {
        type: 'excel',
        content: temp1,
        header: {
          ID: 'number',
          Firma: 'string',
          Durumu: 'string',
          'Paket Sayısı': 'string',
          'Emir Tipi': 'string',
          'Kargo Firması': 'string',
          'Oluşturulma Tarihi': 'string',
          'Oluşturulma Saati': 'string',
          'Tamamlanma Tarihi': 'string',
          'Tamamlanma Saati': 'string',
        },
        sheetTitle: 'İşlemler',
      })
      .then((response) => {
        if (response.data.status) {
          window.open( response.data.result, '_blank');
        } else {
          toast.error('Bir hata oluştu');
        }
      })
      .catch((error) => {
        toast.error('Bir hata oluştu');
      });
  }

  return (
    <>
      <Helmet>
        <title> Tüm İşlemler | Mall Logistics Panel </title>
      </Helmet>

      <Container>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Tüm İşlemler
          </Typography>
        </Stack>

        <Card>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            paddingY={2}
            paddingX={1}
          >
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                sx={{ marginRight: '8px' }}
                format="DD/MM/YYYY"
                label="Başlangıç Tarihi"
                value={startDate}
                onChange={(newValue) => setStartDate(newValue)}
              />
            </LocalizationProvider>

            <LocalizationProvider dateAdapter={AdapterMoment}>
              <DatePicker
                sx={{ marginRight: '8px' }}
                format="DD/MM/YYYY"
                label="Bitiş Tarihi"
                value={endDate}
                onChange={(newValue) => setEndDate(newValue)}
              />
            </LocalizationProvider>

            <Stack
              mr={1}
              direction="column"
              alignItems="flex-start"
              justifyContent="flex-start"
            >
              <FormControl sx={{ minWidth: 250 }}>
                <Select
                  sx={{ marginRight: '8px' }}
                  multiple
                  displayEmpty
                  value={selectedCargoStatus}
                  input={<OutlinedInput />}
                  onChange={handleChange}
                  renderValue={(selected) => {
                    if (selected?.length === 0) {
                      return <em>Durum</em>;
                    }

                    return selected.map((x) => x?.label).join(', ');
                  }}
                  MenuProps={MenuProps}
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  {StatusList.map((x) => (
                    <MenuItem key={x.id} value={x}>
                      {x.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>
            <Button
              sx={{ marginRight: '8px' }}
              variant="contained"
              color="info"
              onClick={() => getFilteredOrderList()}
            >
              Filtrele
            </Button>

            <Button
              sx={{ marginRight: '8px' }}
              variant="contained"
              color="error"
              onClick={() => clearFilters()}
            >
              Temizle
            </Button>

            <Button
              variant="contained"
              color="success"
              onClick={() => exportToExcel()}
            >
              Excele aktar
            </Button>
          </Stack>
          <TextField
            label="Firma"
            variant="outlined"
            value={firm}
            onChange={(e) => filterByFirm(e.target.value)}
            sx={{ mt: 2, marginLeft: 1 }}
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <UserListHead
                  order={order}
                  orderBy={orderBy}
                  headLabel={TABLE_HEAD}
                  rowCount={filteredOrders?.length}
                  numSelected={selected.length}
                  onRequestSort={handleRequestSort}
                  //   onSelectAllClick={handleSelectAllClick}
                />
                <TableBody>
                  {filteredOrders
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => {
                      const {
                        id,
                        barcode,
                        branchTitle,
                        dataEntranceType,
                        status,
                        orderInventory,
                        createdDateTime,
                        createdBy,
                        completedDateTime,
                        shipmentFirm,
                      } = row;
                      const selectedUser = selected.indexOf(barcode) !== -1;
                      const rowIndex = page * rowsPerPage + index + 1;
                      // const _createdDateTime =
                      //   createdDateTime !== '' ? createdDateTime : '';

                      return (
                        <TableRow
                          hover
                          key={index}
                          tabIndex={-1}
                          role="checkbox"
                          selected={selectedUser}
                        >
                          <TableCell align="left">{rowIndex}</TableCell>
                          <TableCell align="left">{branchTitle}</TableCell>
                          <TableCell align="left">
                            {getStatusLabel(+status)}
                          </TableCell>
                          <TableCell align="left">{orderInventory}</TableCell>
                          <TableCell align="left">
                            {dataEntranceType === 'Mall Logistics API'
                              ? `App (${getUserType(createdBy)})`
                              : 'Entegrasyon'}
                          </TableCell>
                          <TableCell align="left">
                            {shipmentFirm !== '' ? shipmentFirm : 'Nakliye'}
                          </TableCell>
                          <TableCell align="left">
                            {moment.unix(createdDateTime).format('DD/MM/YYYY')}
                          </TableCell>
                          <TableCell align="left">
                            {moment.unix(createdDateTime).format('HH:mm')}
                          </TableCell>

                          <TableCell align="left">
                            {(status === '1' ||
                              status === '17' ||
                              status === '21') &&
                              moment
                                .unix(
                                  // row.logs[row.logs?.length - 1]
                                  //   ?.createdDateTime
                                  completedDateTime
                                )
                                .format('DD/MM/YYYY')}
                          </TableCell>

                          <TableCell align="left">
                            {(status === '1' ||
                              status === '17' ||
                              status === '21') &&
                              moment
                                .unix(
                                  // row.logs[row.logs?.length - 1]
                                  //   ?.createdDateTime
                                  completedDateTime
                                )
                                .format('HH:mm')}
                          </TableCell>

                          {/* <TableCell align="left">
                            <Button onClick={() => null}>Detay</Button>
                          </TableCell> */}
                        </TableRow>
                      );
                    })}
                  {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )}
                </TableBody>

                {isNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <Paper
                          sx={{
                            textAlign: 'center',
                          }}
                        >
                          <Typography variant="h6" paragraph>
                            Not found
                          </Typography>

                          <Typography variant="body2">
                            No results found for &nbsp;
                            <strong>&quot;{filterName}&quot;</strong>.
                            <br /> Try checking for typos or using complete
                            words.
                          </Typography>
                        </Paper>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[10, 25, 50, 100]}
            component="div"
            count={orderList?.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </>
  );
}
