import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import { IconButton, InputAdornment, Stack, Button } from '@mui/material';
import { toast } from 'react-toastify';
import { tokenService } from 'src/services';
import api from 'src/services/BaseService';
import Iconify from 'src/components/iconify';

function ProfilePage() {
  const [oldPassword, setOldPassword] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [showOldPassword, setShowOldPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const handleUpdatePassword = () => {
    if (password !== confirmPassword) {
      toast.error('Şifreler uyuşmuyor.');
      return;
    } else {
      api
        .post('users/check-password', { password: oldPassword })
        .then((res) => {
          if (res.data.status) {
            api
              .post('users/update-profile', {
                password: password,
              })
              .then((response) => {
                if (response.data.status) {
                  toast.success('Şifre başarıyla güncellendi.');
                  setTimeout(() => {
                    window.location.reload();
                  }, 2000);
                } else {
                  toast.error('Bir hata oluştu. Lütfen tekrar deneyin.');
                }
              })
              .catch((error) => {
                // console.log(error);
                toast.error('Bir hata oluştu. Lütfen tekrar deneyin.');
              });
          } else {
            toast.error('Eski şifreniz hatalıdır. Lütfen tekrar deneyin.');
          }
        })
        .catch((err) => {
          // console.log(err);
          toast.error('Eski şifreniz hatalıdır. Lütfen tekrar deneyin.');
        });
    }
  };

  return (
    <div style={{ width: '25%', margin: 'auto' }}>
      <div
        style={{
          fontWeight: '600',
          margin: '3% 0',
          textAlign: 'center',
          fontSize: '25px',
        }}
      >
        Şifre Güncelleme
      </div>
      <Stack spacing={3}>
        <TextField
          required
          id="oldPassword"
          name="oldPassword"
          label="Eski Şifre"
          type={showOldPassword ? 'text' : 'password'}
          value={oldPassword}
          onChange={(e) => setOldPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowOldPassword(!showOldPassword)}
                  edge="end"
                >
                  {showOldPassword ? (
                    <Iconify icon="eva:eye-fill" />
                  ) : (
                    <Iconify icon="eva:eye-off-fill" />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <TextField
          required
          id="password"
          name="password"
          label="Yeni Şifre"
          type={showNewPassword ? 'text' : 'password'}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowNewPassword(!showNewPassword)}
                  edge="end"
                >
                  {showNewPassword ? (
                    <Iconify icon="eva:eye-fill" />
                  ) : (
                    <Iconify icon="eva:eye-off-fill" />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />

        <TextField
          required
          id="confirmPassword"
          name="confirmPassword"
          label="Yeni Şifre Tekrar"
          type={showConfirmPassword ? 'text' : 'password'}
          value={confirmPassword}
          onChange={(e) => {
            setConfirmPassword(e.target.value);
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <IconButton
                  onClick={() => setShowConfirmPassword(!showConfirmPassword)}
                  edge="end"
                >
                  {showConfirmPassword ? (
                    <Iconify icon="eva:eye-fill" />
                  ) : (
                    <Iconify icon="eva:eye-off-fill" />
                  )}
                </IconButton>
              </InputAdornment>
            ),
          }}
        />
        <Button
          variant="contained"
          disabled={
            oldPassword === '' || password === '' || confirmPassword === ''
          }
          color="primary"
          onClick={handleUpdatePassword}
        >
          Kaydet
        </Button>
      </Stack>
    </div>
  );
}

export default ProfilePage;
