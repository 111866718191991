import axios from 'axios';
import history from '../custom-routes/history';
import tokenService from './TokenService';
import '../utils/loading.css';

const API_URL = process.env.REACT_APP_API_URL;
const api = axios.create({ baseURL: API_URL });

api.interceptors.request.use(
  (config) => {
    document.body.classList.add('loading');
    // eslint-disable-next-line dot-notation
    config.headers['Accept'] = 'application/json, text/plain, /';
    config.headers['Authorization'] = `Bearer ${tokenService.getUser()?.token}`;
    config.headers['Content-Type'] = 'application/json';
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    document.body.classList.remove('loading');
    return response;
  },
  (error) => {
    const originalRequest = error.config;

    if (
      error?.response?.status === 401 &&
      originalRequest.url !== `${API_URL}/users/login`
    ) {
      history.push('/login');
      return Promise.reject(error);
    }
    return Promise.reject(error);
  }
);

export default api;
