import React from 'react';
import { Helmet } from 'react-helmet-async';
import { concat, filter } from 'lodash';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import { useSelector } from 'react-redux';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  Popover,
  TableRow,
  MenuItem,
  TableBody,
  TableCell,
  Container,
  Typography,
  Select,
  TableContainer,
  TablePagination,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TableHead,
  IconButton,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import ClearIcon from '@mui/icons-material/Clear';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead } from '../sections/@dashboard/user';
import api from 'src/services/BaseService';

export default function TechnicalSupportRequests() {
  const [filter, setFilter] = useState();
  const filteredData = [
    {
      kullaniciAdi: 'john_doe',
      platformIsmi: 'Web',
      konu: 'Kullanıcı Girişi',
      detay: 'Kullanıcı girişi ekranında problem yaşanıyor.',
      olusturulmaTarihi: '2022-01-01',
      tamamlanmaTarihi: '2022-01-05',
      durum: 'Tamamlandı',
    },
    {
      kullaniciAdi: 'alice_smith',
      platformIsmi: 'Mobil',
      konu: 'Bildirim Sorunu',
      detay: 'Mobil uygulamada bildirim gelmiyor.',
      olusturulmaTarihi: '2022-01-02',
      tamamlanmaTarihi: '2022-01-05',
      durum: 'Devam Ediyor',
    },
    {
      kullaniciAdi: 'bob_jones',
      platformIsmi: 'Web',
      konu: 'Sepet İşlemleri',
      detay: 'Sepete ürün eklerken hata alıyorum.',
      olusturulmaTarihi: '2022-01-03',
      tamamlanmaTarihi: '2022-01-05',
      durum: 'Devam Ediyor',
    },
    {
      kullaniciAdi: 'emma_davis',
      platformIsmi: 'Mobil',
      konu: 'Arama Sorunu',
      detay: 'Arama sonuçları beklenenden farklı çıkıyor.',
      olusturulmaTarihi: '2022-01-04',
      tamamlanmaTarihi: '2022-01-05',
      durum: 'Devam Ediyor',
    },
    {
      kullaniciAdi: 'will_smith',
      platformIsmi: 'Web',
      konu: 'Ödeme Sorunu',
      detay: 'Ödeme ekranında sık sık hata alıyorum.',
      olusturulmaTarihi: '2022-01-05',
      tamamlanmaTarihi: '2022-01-05',
      durum: 'Devam Ediyor',
    },
    {
      kullaniciAdi: 'olivia_johnson',
      platformIsmi: 'Mobil',
      konu: 'Giriş Ekranı Tasarımı',
      detay: 'Giriş ekranı tasarımı beklentileri karşılamıyor.',
      olusturulmaTarihi: '2022-01-06',
      tamamlanmaTarihi: '2022-01-08',
      durum: 'Tamamlandı',
    },
    {
      kullaniciAdi: 'james_miller',
      platformIsmi: 'Web',
      konu: 'Ürün Detay Sayfası',
      detay: 'Ürün detay sayfasında resimler görünmüyor.',
      olusturulmaTarihi: '2022-01-07',
      tamamlanmaTarihi: '2022-01-05',
      durum: 'Devam Ediyor',
    },
    {
      kullaniciAdi: 'ava_wilson',
      platformIsmi: 'Mobil',
      konu: 'Sipariş Onayı',
      detay: 'Sipariş onay sayfasında hata alıyorum.',
      olusturulmaTarihi: '2022-01-08',
      tamamlanmaTarihi: '2022-01-10',
      durum: 'Tamamlandı',
    },
    {
      kullaniciAdi: 'logan_anderson',
      platformIsmi: 'Web',
      konu: 'Arama Filtreleri',
      detay: 'Arama sonuçlarını filtreleme seçenekleri ekleyin.',
      olusturulmaTarihi: '2022-01-09',
      tamamlanmaTarihi: '2022-01-05',
      durum: 'Devam Ediyor',
    },
    {
      kullaniciAdi: 'mia_thompson',
      platformIsmi: 'Mobil',
      konu: 'Giriş Sorunu',
      detay: 'Giriş yaparken sürekli hata alıyorum.',
      olusturulmaTarihi: '2022-01-10',
      tamamlanmaTarihi: '2022-01-05',
      durum: 'Devam Ediyor',
    },
    {
      kullaniciAdi: 'henry_carter',
      platformIsmi: 'Web',
      konu: 'Ürün İnceleme',
      detay: 'Ürün inceleme sayfasında problem var.',
      olusturulmaTarihi: '2022-01-11',
      tamamlanmaTarihi: '2022-01-05',
      durum: 'Devam Ediyor',
    },
    {
      kullaniciAdi: 'zoe_jackson',
      platformIsmi: 'Mobil',
      konu: 'Sepet İşlemleri',
      detay: 'Sepetteki ürünleri silemiyorum.',
      olusturulmaTarihi: '2022-01-12',
      tamamlanmaTarihi: '2022-01-05',
      durum: 'Devam Ediyor',
    },
    {
      kullaniciAdi: 'ethan_morris',
      platformIsmi: 'Web',
      konu: 'Ödeme Hatası',
      detay: 'Ödeme yaparken hata alıyorum.',
      olusturulmaTarihi: '2022-01-13',
      tamamlanmaTarihi: '2022-01-05',
      durum: 'Devam Ediyor',
    },
    {
      kullaniciAdi: 'oliver_clark',
      platformIsmi: 'Mobil',
      konu: 'Bildirim Ayarları',
      detay: 'Bildirim ayarlarını yapılandırmak istiyorum.',
      olusturulmaTarihi: '2022-01-14',
      tamamlanmaTarihi: '2022-01-05',
      durum: 'Devam Ediyor',
    },
    {
      kullaniciAdi: 'amelia_moore',
      platformIsmi: 'Web',
      konu: 'Ürün İnceleme',
      detay: 'Ürün inceleme puanları güncellenmiyor.',
      olusturulmaTarihi: '2022-01-15',
      tamamlanmaTarihi: '2022-01-05',
      durum: 'Devam Ediyor',
    },
    {
      kullaniciAdi: 'michael_hall',
      platformIsmi: 'Mobil',
      konu: 'Arama Performansı',
      detay: 'Arama sonuçları çok yavaş geliyor.',
      olusturulmaTarihi: '2022-01-16',
      tamamlanmaTarihi: '2022-01-18',
      durum: 'Tamamlandı',
    },
    {
      kullaniciAdi: 'lily_baker',
      platformIsmi: 'Web',
      konu: 'Sepet Sorunu',
      detay: 'Sepetimdeki ürünler kayboluyor.',
      olusturulmaTarihi: '2022-01-17',
      tamamlanmaTarihi: '2022-01-05',
      durum: 'Devam Ediyor',
    },
    {
      kullaniciAdi: 'owen_walker',
      platformIsmi: 'Mobil',
      konu: 'Hesap Bilgileri',
      detay: 'Hesap bilgilerimi güncelleyemiyorum.',
      olusturulmaTarihi: '2022-01-18',
      tamamlanmaTarihi: '2022-01-05',
      durum: 'Devam Ediyor',
    },
    {
      kullaniciAdi: 'sophia_cook',
      platformIsmi: 'Web',
      konu: 'Ürün Fiyatları',
      detay: 'Ürün fiyatları güncellenmiyor.',
      olusturulmaTarihi: '2022-01-19',
      tamamlanmaTarihi: '2022-01-05',
      durum: 'Devam Ediyor',
    },
    {
      kullaniciAdi: 'noah_brooks',
      platformIsmi: 'Mobil',
      konu: 'Sipariş Takibi',
      detay: 'Siparişimi takip edemiyorum.',
      olusturulmaTarihi: '2022-01-20',
      tamamlanmaTarihi: '2022-01-22',
      durum: 'Tamamlandı',
    },
  ];

  return (
    <>
      <Helmet>
        <title> Teknik Destek Talepleri | Mall Logistics Panel </title>
      </Helmet>

      <Stack style={{ paddingInline: '25px' }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Teknik Destek Talepleri
          </Typography>
        </Stack>

        <Card>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            paddingY={2}
            paddingX={1}
          >
            <Button
              sx={{ marginRight: '8px' }}
              variant="contained"
              color="info"
              // onClick={() => getFilteredOrderList()}
            >
              Devam Edenler
            </Button>

            <Button
              sx={{ marginRight: '8px' }}
              variant="contained"
              color="warning"
              // onClick={() => clearFilters()}
            >
              Bekleyenler
            </Button>
            <Button
              sx={{ marginRight: '8px' }}
              variant="contained"
              color="secondary"
              // onClick={() => clearFilters()}
            >
              Cevap Bekleyenler
            </Button>
            <Button
              sx={{ marginRight: '8px' }}
              variant="contained"
              color="success"
              // onClick={() => clearFilters()}
            >
              Tamamlananlar
            </Button>
            <Button
              sx={{ marginRight: '8px' }}
              variant="contained"
              color="error"
              // onClick={() => clearFilters()}
            >
              İptal Edilenler
            </Button>
          </Stack>

          <Scrollbar sx={{ marginTop: '25px' }}>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell align="left">#</TableCell>
                    <TableCell align="left">Talep Eden Kullanıcı</TableCell>
                    <TableCell align="left">Platform</TableCell>
                    <TableCell align="left">Konu</TableCell>
                    <TableCell align="left">Detay</TableCell>
                    <TableCell align="left">Oluşturulma Tarihi</TableCell>
                    <TableCell align="left">Tamamlanma Tarihi</TableCell>
                    <TableCell align="left">Durum</TableCell>
                    <TableCell align="left">İşlem</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {filteredData.map((item, index) => (
                    <TableRow key={index}>
                      <TableCell align="left">{index + 1}</TableCell>
                      <TableCell align="left">{item.kullaniciAdi}</TableCell>
                      <TableCell align="left">{item.platformIsmi}</TableCell>
                      <TableCell align="left">{item.konu}</TableCell>
                      <TableCell align="left">{item.detay}</TableCell>
                      <TableCell align="left">
                        {item.olusturulmaTarihi}
                      </TableCell>
                      <TableCell align="left">
                        {item.tamamlanmaTarihi}
                      </TableCell>
                      <TableCell align="left">{item.durum}</TableCell>
                      <TableCell align="left">
                        <IconButton color="primary">
                          <CheckIcon />
                        </IconButton>
                        <IconButton color="primary">
                          <ClearIcon />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Scrollbar>

          {/* <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={orderList.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          /> */}
        </Card>
      </Stack>
    </>
  );
}
