import React from 'react';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';
import moment from 'moment';
import api from 'src/services/BaseService';
import CircularProgress from '@mui/material/CircularProgress';
import {
  Button,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Grid,
  Container,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import './FinishedJobsDetailPage.css';

import { StatusList } from 'src/types/statusTypes';
import { tokenService } from 'src/services';
import { toast } from 'react-toastify';

export default function FinishedJobsDetailPage() {
  const [barcode, setBarcode] = useState();
  const location = useLocation();
  const [data, setData] = useState(null);
  const [porterList, setPorterList] = useState([]);
  const [selectedPorter, setSelectedPorter] = useState(null);
  // const data = location.state.data;
  const { id } = useParams();

  const getPorterList = () => {
    const mallID = localStorage.getItem('mallID');
    api
      .post('users/get-users', {
        type: 'porter',
        mallID: mallID,
      })
      .then((response) => {
        if (response.data.status) {
          setPorterList(response.data.result);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        // console.log(error);
        toast.error(error.message);
      });
  };

  const cancelPost = () => {
    api
      .post('posts/change-status', [{ postID: id, status: 2 }])
      .then((res) => {
        console.log(res.data);
        if (res.data.status) {
          toast.success('İptal işlemi başarıyla tamamlandı.');
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else toast.error('İş iptal edilirken bir hata meydana geldi.');
      })
      .catch((err) => {
        console.log(err);
        toast.error('İş iptal edilirken bir hata meydana geldi.');
      });
  };

  const cancelTransaction = (transactionID) => {
    api
      .post('transactions/change-status', [
        { transactionID: transactionID, status: 2 },
      ])
      .then((res) => {
        console.log(res.data);
        if (res.data.status) cancelPost();
        else toast.error('İş iptal edilirken bir hata meydana geldi.');
      })
      .catch((err) => {
        console.log(err);
        toast.error('İş iptal edilirken bir hata meydana geldi.');
      });
  };

  const cancel = () => {
    api
      .post('transactions/get', {
        postID: id,
      })
      .then((res) => {
        console.log(res.data);
        const transactionID = res.data.result[0].transactionID;
        if (res.data.status) cancelTransaction(transactionID);
        else cancelPost();
      })
      .catch((err) => {
        console.log(err);
        cancelPost();
      });
  };

  useEffect(() => {
    api
      .post('posts/get', {
        barcode: id,
        withLogs: true,
      })
      .then((response) => {
        if (response.data.status) {
          console.log(response.data.result);
          setData(response.data.result[0]);
        } else {
          setData(null);
        }
      })
      .catch((error) => {
        setData(null);
      });
    getPorterList();
  }, []);

  const formatDate = (date) => {
    const formattedDate = moment.unix(date).format('DD.MM.YYYY - HH:mm');
    return formattedDate;
  };

  const calculateAndFormatDeliveryTime = (
    createdDateTime,
    completedDateTime
  ) => {
    const createdMoment = moment.unix(
      data && data.on_porter_date_time !== null
        ? data.on_porter_date_time
        : createdDateTime
    );
    const completedMoment = moment.unix(completedDateTime);
    const duration = moment.duration(completedMoment.diff(createdMoment));
    const hours = duration.asHours();

    if (hours < 1) {
      const minutes = Math.round(hours * 60);
      return `${minutes} dakika`;
    } else {
      const roundedHours = Math.floor(hours);
      const remainingMinutes = Math.round((hours - roundedHours) * 60);
      if (remainingMinutes > 0) {
        return `${roundedHours} saat ${remainingMinutes} dakika`;
      } else {
        return `${roundedHours} saat`;
      }
    }
  };

  const getStatusLabel = (statusValue) => {
    const status = StatusList.find((item) => item.id === +statusValue);
    return status ? status.label : '';
  };

  return (
    <>
      <Helmet>
        <title> Gönderi Detay | Mall Logistics Panel </title>
      </Helmet>
      {data === null ? (
        <></>
      ) : (
        <Container style={{ marginBottom: 30 }}>
          {(data.status === '9' || data.status === '21') && (
            <div
              onClick={handlePrintClick}
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                marginBlock: 15,
                cursor: 'pointer',
              }}
            >
              <Print fontSize="large" />
            </div>
          )}

          <Grid container spacing={2}>
            <Grid item xs={6} sm={7}>
              <div className="paper">
                <h6
                  style={{
                    textAlign: 'center',
                    color: '#DA3831',
                    fontWeight: 'bold',
                    marginBottom: 35,
                    marginTop: 30,
                  }}
                >
                  {data.title}
                </h6>
                <div
                  style={{
                    width: '100%',
                    justifyContent: 'center',
                    display: 'flex',
                  }}
                ></div>
                <div className="customRow">
                  <text style={{ fontSize: 16, fontWeight: '700' }}>ID:</text>
                  <text>{data.id}</text>
                </div>
                <div className="divider" />
                <div className="customRow">
                  <text style={{ fontSize: 16, fontWeight: '700' }}>
                    Barkod:
                  </text>
                  <text>{data.barcode}</text>
                </div>
                <div className="divider" />
                <div className="customRow">
                  <text style={{ fontSize: 16, fontWeight: '700' }}>
                    Kargo Türü:
                  </text>
                  <text>Paket</text>
                </div>
                <div className="divider" />
                <div className="customRow">
                  <text style={{ fontSize: 16, fontWeight: '700' }}>
                    Paket Adedi:
                  </text>
                  <text>{data.orderInventory}</text>
                </div>
                <div className="divider" />
                <div className="customRow">
                  <text style={{ fontSize: 16, fontWeight: '700' }}>
                    Çıkış - Varış Noktası:
                  </text>
                  <text>
                    {data.from.title} → {data.to.title}
                  </text>
                </div>
                <div className="divider" />
                {data.takenName && (
                  <>
                    <div className="customRow">
                      <text style={{ fontSize: 16, fontWeight: '700' }}>
                        Gönderen:
                      </text>
                      <text>{data.takenName}</text>
                    </div>
                    <div className="divider" />
                  </>
                )}
                {/* {data.willTakenBy !== ' ' && (
                  <>
                    {' '}
                    <div className="customRow">
                      <text style={{ fontSize: 16, fontWeight: '700' }}>
                        Alıcı:
                      </text>
                      <text>{data.willTakenBy}</text>
                    </div>
                    <div className="divider" />
                  </>
                )} */}
                {data.phone !== null && (
                  <>
                    {' '}
                    <div className="customRow">
                      <text style={{ fontSize: 16, fontWeight: '700' }}>
                        Alıcı Telefon No:
                      </text>
                      <text>{data.phone?.slice(0, -7) + '*******'}</text>
                    </div>
                    <div className="divider" />
                  </>
                )}
                {/* <div className="customRow">
                  <text style={{ fontSize: 16, fontWeight: '700' }}>
                    Teslim Edilecek İl:
                  </text>
                  <text>{data.sentToCity}</text>
                </div>
                <div className="divider" />
                <div className="customRow">
                  <text style={{ fontSize: 16, fontWeight: '700' }}>
                    Teslim Edilecek İlçe:
                  </text>
                  <text>{data.sentToRegion}</text>
                </div>
                <div className="divider" />
                <div className="customRow">
                  <text style={{ fontSize: 16, fontWeight: '700' }}>
                    Teslim Edilecek Easy Point Noktası:
                  </text>
                  <text>{data.pointTitle}</text>
                </div>{' '}
                <div className="divider" /> */}
                {data.shipmentFirm && (
                  <>
                    <div className="customRow">
                      <text style={{ fontSize: 16, fontWeight: '700' }}>
                        Kargo Firması:
                      </text>
                      <text>{data.shipmentFirm}</text>
                    </div>
                    <div className="divider" />
                  </>
                )}
                {data.shipmentNo && (
                  <>
                    {' '}
                    <div className="customRow">
                      <text style={{ fontSize: 16, fontWeight: '700' }}>
                        Kargo Takip No:
                      </text>
                      <text>{data.shipmentNo}</text>
                    </div>
                    <div className="divider" />
                  </>
                )}
                {data.apiUserType === 'mall-logistics' ? (
                  <>
                    <div className="customRow">
                      <text style={{ fontSize: 16, fontWeight: '700' }}>
                        Oluşturan Kullanıcı:
                      </text>
                      <text>
                        {data.createdByName + ' ' + data.createdByLastname}
                      </text>
                    </div>
                    <div className="divider" />
                  </>
                ) : (
                  <>
                    <div className="customRow">
                      <text style={{ fontSize: 16, fontWeight: '700' }}>
                        Oluşturan Kullanıcı:
                      </text>
                      <text>{data.additionalNote}</text>
                    </div>
                    <div className="divider" />
                  </>
                )}
                {data.porterID && (
                  <>
                    <div className="customRow">
                      <text style={{ fontSize: 16, fontWeight: '700' }}>
                        Taşıyan Kullanıcı:
                      </text>
                      <text>{data.porterNameLastname}</text>
                    </div>
                    <div className="divider" />
                  </>
                )}
                <div className="customRow">
                  <text style={{ fontSize: 16, fontWeight: '700' }}>
                    İş Emri Oluşturma Tarihi:
                  </text>
                  <text>{formatDate(data.createdDateTime)}</text>
                </div>
                <div className="divider" />
                {data.status === '12' &&
                  tokenService.getUser()?.accountCategory !== 'store' && (
                    <>
                      <div
                        className="customRow"
                        style={{ display: 'flex', alignItems: 'center' }}
                      >
                        <text style={{ fontSize: 16, fontWeight: '700' }}>
                          Porter:
                        </text>
                        <FormControl sx={{ width: 300 }} margin="normal">
                          <InputLabel>Porter</InputLabel>
                          <Select
                            label="Porter"
                            value={selectedPorter}
                            onChange={(e) => setSelectedPorter(e.target.value)}
                            name="porter"
                            disabled={porterList?.length === 0}
                          >
                            {porterList?.map((porter, index) => (
                              <MenuItem key={index} value={porter.id}>
                                {porter.name} {porter.lastname}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </div>
                      <div className="divider" />
                    </>
                  )}
                {data.on_porter_date_time !== null && (
                  <>
                    <div className="customRow">
                      <text style={{ fontSize: 16, fontWeight: '700' }}>
                        Zimmete Alınma Tarihi:
                      </text>
                      <text>{formatDate(data.on_porter_date_time)}</text>
                    </div>
                    <div className="divider" />
                  </>
                )}
                {data.status === '1' && (
                  <>
                    <div className="customRow">
                      <text style={{ fontSize: 16, fontWeight: '700' }}>
                        Tamamlanma Tarihi:
                      </text>
                      <text>{formatDate(data?.completedDateTime)}</text>
                    </div>
                    <div className="divider" />
                    <div className="customRow">
                      <text style={{ fontSize: 16, fontWeight: '700' }}>
                        Taşıma Süresi:
                      </text>
                      <text>
                        {calculateAndFormatDeliveryTime(
                          data.createdDateTime,
                          data.completedDateTime
                        )}
                      </text>
                    </div>
                    <div className="divider" />

                  </>
                )}
                <div className="customRow">
                  <text style={{ fontSize: 16, fontWeight: '700' }}>
                    İş Emri Durumu:
                  </text>

                  <text
                    style={{
                      backgroundColor: 'rgb(77 177 88)',
                      color: 'white',
                      padding: 3,
                      borderRadius: 5,
                      paddingInline: 10,
                    }}
                  >
                    {getStatusLabel(data.status)}
                  </text>
                </div>
                {(data.status === '3' ||
                  data.status === '5' ||
                  data.status === '12') &&
                  tokenService.getUser()?.accountCategory !== 'store' && (
                    <>
                      <div className="divider" />
                      <div className="customRow">
                        <text style={{ fontSize: 16, fontWeight: '700' }}>
                          Teslim
                        </text>

                        <Button
                          style={{
                            backgroundColor: '#DA3831',
                            border: 'none',
                            fontWeight: 600,
                            color: 'white',
                            marginLeft: 20,
                          }}
                        >
                          {data.status === '3' && 'Teslim Al'}
                          {data.status === '5' && 'Porter Zimmetine Ata'}
                          {data.status === '12' && 'Porter Ata'}
                        </Button>
                      </div>
                    </>
                  )}

                {data?.usedVehicles?.length > 0 ?
                  <>
                    <div className="divider" />
                    <div className="customRow">
                      <text style={{ fontSize: 16, fontWeight: '700' }}>
                        Toplam Araç Sayısı:
                      </text>
                      <text style={{ textAlign: 'right' }}>
                        {data?.usedVehicles.map(e => (
                          <>
                            <text style={{ textAlign: 'right' }}>{e.transportationTool} {'  →  '} </text>
                            <text style={{ textAlign: 'right' }}>{e.transportationToolCount} Sefer</text><br />

                          </>
                        ))}
                      </text>
                    </div>
                    <div className="divider" />

                  </> :
                  <>
                    <div className="divider" />
                    <div className="customRow">
                      <text style={{ fontSize: 16, fontWeight: '700' }}>
                        Toplam Araç Sayısı:
                      </text>
                      <text>
                      <text style={{ textAlign: 'right' }}>
                        {data?.requestedTransportationTool}{'  →  '}
                      </text>
                      <text style={{ textAlign: 'right' }}>  {data?.requestedTransportationToolCount} Sefer  </text>

                      </text>
                      

                    </div>
                    <div className="divider" />
                  </>
                }

              </div>
            </Grid>

            <Grid item xs={8} sm={5}>
              <div className="paper">
                <h6
                  style={{
                    textAlign: 'center',
                    color: '#DA3831',
                    fontWeight: 'bold',
                    marginBottom: 35,
                    marginTop: 25,
                  }}
                >
                  Gönderi Logları
                </h6>
                {data.logs?.length >= 1 ? (
                  <>
                    {data.logs.map((log, i) => (
                      <>
                        <div
                          style={{
                            backgroundColor: '#FFEFD1',
                            padding: 10,
                            width: '100%',
                            textAlign: 'center',
                            borderRadius: 5,
                            marginBottom: 10,
                          }}
                        >
                          {log}
                        </div>

                        <div className="divider" />
                      </>
                    ))}
                  </>
                ) : (
                  <p>Log bulunmamaktadır.</p>
                )}
              </div>
            </Grid>
          </Grid>

          <Button
            style={{
              backgroundColor: 'orange',
              border: 'none',
              fontWeight: 600,
              color: 'white',
              marginTop: 20,
            }}
          >
            Destek Talebi Oluştur
          </Button>
          {data.status !== '2' &&
            tokenService.getUser()?.frontAuth?.includes('can-cancel') && (
              <Button
                style={{
                  backgroundColor: '#DA3831',
                  border: 'none',
                  fontWeight: 600,
                  color: 'white',
                  marginTop: 20,
                  marginLeft: 10,
                }}
                onClick={cancel}
              >
                İptal Et
              </Button>
            )}
        </Container>
      )}
    </>
  );
}
