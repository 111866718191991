import * as React from 'react';
import Box from '@mui/material/Box';
import Scrollbar from '../components/scrollbar';
import SendIcon from '@mui/icons-material/Send';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { WarningOutlined } from '@mui/icons-material';
import {
  Card,
  Stack,
  Container,
  Typography,
  Button,
  Divider,
  Modal,
  TextField,
  Input,
  InputLabel,
} from '@mui/material';
import { Helmet } from 'react-helmet-async';
import api from 'src/services/BaseService';
import { HotTable } from '@handsontable/react';
import 'handsontable/dist/handsontable.full.min.css';
import * as XLSX from 'xlsx';
import { toast } from 'react-toastify';

export default function FetchBranches() {
  const hotRef = React.useRef(null);
  const storedMallID = localStorage.getItem('mallID');
  const initialRows = Array.from({ length: 50 }, (_, index) => ({
    id: index + 1,
    mallID: localStorage.getItem('mallID'),
    title: '',
    legalTitle: '',
    taxNo: '',
    taxAdministration: '',
    city: '',
    region: '',
    country: '',
  }));
  const columnHeaders = [
    'No',
    'AVM ID',
    'Mağaza Adı',
    'Ticari Ünvan',
    'Vergi No',
    'Vergi İdaresi',
    'İl',
    'İlçe',
    'Ülke',
  ];

  const columnSettings = [
    {
      readOnly: true,
      width: 50,
      className: 'htCenter htMiddle',
    },
    {
      readOnly: true,
      strict: false,
      visibleRows: 50,
      className: 'htCenter htMiddle',
      width: 250,
    },
    {
      strict: false,
      className: 'htCenter htMiddle',
      width: 250,
    },
    {
      strict: false,
      className: 'htCenter htMiddle',
      width: 250,
    },
    {
      strict: false,
      className: 'htCenter htMiddle',
      width: 150,
    },
    // {
    //   strict: false,
    //   className: 'htCenter htMiddle',
    //   width: 150,
    // },
    // {
    //   strict: false,
    //   className: 'htCenter htMiddle',
    //   width: 150,
    // },
    // {
    //   strict: false,
    //   className: 'htCenter htMiddle',
    //   width: 150,
    // },
    // {
    //   strict: false,
    //   className: 'htCenter htMiddle',
    //   width: 150,
    // },
  ];
  const [isSuccessModalOpen, setSuccessModalOpen] = React.useState(false);
  const [successMessage, setSuccessMessage] = React.useState('');
  const [tableData, setTableData] = React.useState();
  const [failedRowsCount, setFailedRowsCount] = React.useState();

  const handleCreate = () => {
    const table = hotRef.current.hotInstance.getData();
    // console.log(table);
    const nonEmptyRows = table
      .filter((row) => row.slice(1, 5).every((cell) => cell !== ''))
      .map((row) => {
        return {
          mallID: row[1],
          title: row[2],
          legalTitle: row[3],
          taxNo: row[4],
          // taxAdministration: row[5],
          // city: row[6],
          // region: row[7],
          // country: row[8],
        };
      })
      .filter(Boolean);

    // console.log('Non-empty rows:', nonEmptyRows);
    if (nonEmptyRows?.length > 0) {
      api
        .post('flow/mass-branch-import', nonEmptyRows)
        .then((response) => {
          // console.log(response.data);
          const numJobsCreated = nonEmptyRows.length;
          if (response.data.status === true) {
            const data = hotRef.current.hotInstance.getData();
            const newData = data.map((row) => {
              const matchingRow = nonEmptyRows.find(
                (nonEmptyRow) => nonEmptyRow?.index === row[0]
              );
              if (matchingRow) {
                return [row[0], '', '', '', '', ''];
              }
              return row;
            });
            const filledRowsCount = newData.filter((row) =>
              row.slice(1).some((cell) => cell !== '')
            ).length;
            openSuccessModal(
              `Toplam ${numJobsCreated} adet mağaza oluştu.`,
              filledRowsCount
            );
            setTableData(newData);
          } else {
            openSuccessModal('Bir hata oluştu', true);
          }
        })
        .catch((error) => {
          // console.log(error);
          toast.error('Mağazalar aktarılırken bir hata oluştu');
        });
    } else {
      openSuccessModal('Lütfen boş alan bırakmayınız', true);
    }
  };

  const handlePreCreateValidation = () => {
    const table = hotRef.current.hotInstance.getData();

    const invalidRows = table.filter((row) => {
      const magazaAdi = row[2];
      const kurumsalAdi = row[3];
      const vergiNo = row[4];

      if (
        (magazaAdi || kurumsalAdi || vergiNo) &&
        !(magazaAdi && kurumsalAdi && vergiNo)
      ) {
        return true;
      }

      return false;
    });

    if (invalidRows.length > 0) {
      toast.error(
        'Mağaza Adı, Ticari Ünvan ve Vergi No aynı satırda eksiksiz doldurulmalıdır.'
      );
    } else {
      handleCreate();
    }
  };

  const openSuccessModal = (message, count) => {
    setSuccessMessage(message);
    setFailedRowsCount(count);
    setSuccessModalOpen(true);
  };

  const handleFileSelect = (e) => {
    let fileTypes = [
      'application/vnd.ms-excel',
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      'text/csv',
    ];
    let selectedFile = e.target.files[0];
    if (selectedFile) {
      if (selectedFile && fileTypes.includes(selectedFile.type)) {
        let reader = new FileReader();
        reader.readAsArrayBuffer(selectedFile);
        reader.onload = (e) => {
          const workbook = XLSX.read(e.target.result, { type: 'buffer' });
          const worksheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[worksheetName];
          const data = XLSX.utils.sheet_to_json(worksheet);

          if (data.length > 0) {
            const columnMappings = {
              No: 'id',
              'AVM ID': 'mallID',
              'Mağaza Adı': 'title',
              'Ticari Ünvan': 'legalTitle',
              'Vergi No': 'taxNo',
              // 'Vergi İdaresi': 'taxAdministration',
              // İl: 'city',
              // İlçe: 'region',
              // Ülke: 'country',
            };

            const mappedData = data.map((row, index) => {
              const newRow = [];
              columnHeaders.forEach((header) => {
                if (header === 'AVM ID') {
                  newRow.push(storedMallID);
                } else if (header === 'No') {
                  newRow.push(index + 1);
                } else {
                  const columnName = columnMappings[header];
                  newRow.push(row[columnName] || '');
                }
              });
              return newRow;
            });

            setTableData(mappedData);
          } else {
            toast.error('Excel dosyası boş');
          }
        };
      } else {
        toast.error('Lütfen Excel dosyası seçiniz');
      }
    } else {
      toast.error('Lütfen Excel dosyası seçiniz');
    }
  };

  React.useEffect(() => {
    if (hotRef.current) {
      const hotInstance = hotRef.current.hotInstance;
      hotInstance.updateSettings({
        data: initialRows.map((row) => [
          row.id,
          row.mallID,
          row.title,
          row.legalTitle,
          row.taxNo,
          // row.taxAdministration,
          // row.city,
          // row.region,
          // row.country,
        ]),
        columns: columnSettings,
      });
    }
  }, [hotRef]);

  return (
    <>
      <Helmet>
        <title> AVM Mağaza Aktarımı| Mall Logistics Panel </title>
      </Helmet>

      <Stack style={{ paddingInline: '25px' }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            AVM Mağaza Aktarımı
          </Typography>

          <div>
            <Button
              onClick={() => {
                handlePreCreateValidation();
              }}
              variant="contained"
              endIcon={<SendIcon />}
              sx={{ marginRight: '12px' }}
            >
              Mağazaları Aktar
            </Button>
            <Button
              component="label"
              variant="contained"
              //   endIcon={<SendIcon />}
              sx={{ marginRight: '12px' }}
            >
              Excel'den Aktar
              <Input
                type="file"
                accept=".xlsx, .xls"
                style={{ display: 'none' }}
                onChange={handleFileSelect}
              />
            </Button>
          </div>
        </Stack>

        <Card>
          <Scrollbar>
            <Box sx={{ width: '100%' }}>
              <HotTable
                ref={hotRef}
                rowHeights={35}
                licenseKey="non-commercial-and-evaluation"
                data={tableData}
                persistentState={true}
                colHeaders={columnHeaders}
                columns={columnSettings}
                stretchH="all"
              />
            </Box>
          </Scrollbar>
        </Card>

        <Modal
          open={isSuccessModalOpen}
          onClose={() => {
            setSuccessModalOpen(false);
            // window.location.reload(false);
          }}
        >
          <Box
            sx={{
              position: 'absolute',
              width: 400,
              bgcolor: 'background.paper',
              border: '2px solid #000',
              boxShadow: 24,
              p: 4,
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              textAlign: 'center',
            }}
          >
            {successMessage.includes('Lütfen boş alan bırakmayınız') ||
            successMessage.includes('Bir hata oluştu') ? (
              <WarningOutlined sx={{ color: 'orange', fontSize: 64 }} />
            ) : (
              <CheckCircleIcon sx={{ color: 'green', fontSize: 64 }} />
            )}
            <Typography variant="h6">{successMessage}</Typography>
            {/* {failedRowsCount > 0 && (
              <>
                <Divider sx={{ my: 2, backgroundColor: 'gray' }} />{' '}
                <Typography variant="h6">
                  Uyarı: {failedRowsCount} satırınızın verileri doldurulmadığı
                  için bu satırlarda iş oluşturulamadı.
                </Typography>
              </>
            )} */}
            <Button
              onClick={() => {
                setSuccessModalOpen(false);
                // window.location.reload(false);
                if (!successMessage.includes('Lütfen boş alan bırakmayınız')) {
                }
              }}
            >
              Tamam
            </Button>
          </Box>
        </Modal>
      </Stack>
    </>
  );
}
