import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
// @mui
import {
  Stack,
  IconButton,
  InputAdornment,
  TextField,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Link,
  Typography,
} from '@mui/material';
import { LoadingButton } from '@mui/lab';
// components
import Iconify from '../../../components/iconify';
import api from 'src/services/BaseService';
import Services from 'src/services/Services';
import { useDispatch } from 'react-redux';
import { updateCustomerValue } from 'src/store/customer';

// ----------------------------------------------------------------------

export default function LoginForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState(false);
  const [error, setError] = useState(false);
  const [html, setHtml] = useState('');
  const [isChecked, setChecked] = useState(false);
  const [token, setToken] = useState('');

  // Services
  function login(user) {
    setLoading(true);
    Services.Login(user.username, user.password)
      .then((response) => {
        const data = response?.data?.result;
        if (response.data.status) {
          localStorage.setItem(
            'expired',
            JSON.stringify(response.data.result.expired)
          );
          if (
            response.data.result.type === 'porter' &&
            response.data.result.frontAuth?.length === 1 &&
            response.data.result.frontAuth[0] === 'operations'
          ) {
            toast.error('Panele giriş izniniz bulunmamaktadır.');
          } else {
            if (response?.data?.result?.type !== 'superadmin')
              localStorage.setItem(
                'user',
                JSON.stringify(response.data.result)
              );
            if (response?.data?.result?.accountCategory === 'store') {
              localStorage.setItem(
                'branchID',
                response?.data?.result?.authLocations?.branch[0]?.branchID
              );
              localStorage.setItem(
                'easypointID',
                response?.data?.result?.authLocations?.branch[0]?.mall[0]
                  ?.points?.malkabul?.id
              );
              dispatch(
                updateCustomerValue({
                  id: response?.data?.result?.authLocations?.branch[0]
                    ?.branchID,
                  title:
                    response?.data?.result?.authLocations?.branch[0]?.title,
                })
              );
              localStorage.setItem(
                'mallsTransportationTools',
                JSON.stringify(response?.data?.result?.mallsTransportationTools)
              );
            } else if (
              response?.data?.result?.accountCategory === 'mall-logistics'
            ) {
              localStorage.setItem(
                'mallID',
                response?.data?.result?.authLocations?.mall[0]?.id
              );
              dispatch(
                updateCustomerValue({
                  id: response?.data?.result?.authLocations?.mall[0]?.id,
                  title: response?.data?.result?.authLocations?.mall[0]?.title,
                })
              );
              localStorage.setItem(
                'easypointID',
                response?.data?.result?.authLocations?.mall[0]?.points?.malkabul
                  ?.id
              );
              localStorage.setItem(
                'mallsTransportationTools',
                JSON.stringify(response?.data?.result?.mallsTransportationTools)
              );
            } else if (response?.data?.result?.type === 'superadmin') {
              let malls = [];
              Services.GetMallsForSuperadminLogin(token)
                .then((res) => {
                  if (res.data.status) {
                    malls = res.data.result;
                    data.authLocations = {
                      ...data.authLocations,
                      mall: malls,
                    };
                    data.frontAuth = [
                      'operations',
                      'manager',
                      'technical-support-manager',
                      'technical-support-staff',
                    ];
                    data.accountCategory = 'mall-logistics';
                    localStorage.setItem(
                      'mallID',
                      data?.authLocations?.mall[0]?.id
                    );
                    dispatch(
                      updateCustomerValue({
                        id: data?.authLocations?.mall[0]?.id,
                        title: data?.authLocations?.mall[0]?.title,
                      })
                    );
                    localStorage.setItem('user', JSON.stringify(data));
                    navigate('/dashboard');
                  } else {
                    toast.error(response.data.message);
                  }
                })
                .catch((error) => {
                  toast.error(error.message);
                });
            } else {
              localStorage.setItem(
                'mallID',
                response?.data?.result?.authLocations?.mall[0]?.id
              );
              dispatch(
                updateCustomerValue({
                  id: response?.data?.result?.authLocations?.mall[0]?.id,
                  title: response?.data?.result?.authLocations?.mall[0]?.title,
                })
              );
            }
            localStorage.setItem(
              'tokenForService',
              response?.data?.result?.token
            );
            if (data?.type !== 'superadmin') navigate('/dashboard');
          }
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      })
      .finally(() => setLoading(false));
  }

  const isEmail = (email) =>
    /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);

  const handleClick = (event) => {
    event.preventDefault();
    login({
      username: event.target.elements.email.value,
      password: event.target.elements.password.value,
    });
  };

  const checkboxChange = () => {
    if (isChecked) {
      setChecked(false);
    } else {
      setModal(true);
    }
  };

  useEffect(() => {
    Services.GetMLToken().then((res) => {
      if (res.data.status) {
        setToken(res.data.result.token);
        Services.GetAgreement(res.data.result.token, 'aydinlatma-metni').then(
          (response) => {
            setHtml(response.data.result.description);
          }
        );
      }
    });
  }, []);

  return (
    <>
      <form id="login-form" onSubmit={handleClick}>
        <Stack spacing={3}>
          <TextField
            required
            id="email"
            name="email"
            label="E-postanızı/Kullanıcı Adınızı Giriniz"
            autoComplete="off"
          />

          <TextField
            required
            id="password"
            name="password"
            label="Şifrenizi girin"
            type={showPassword ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword(!showPassword)}
                    edge="end"
                  >
                    <Iconify
                      icon={showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'}
                    />
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
        </Stack>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          sx={{ my: 2 }}
        >
          <div>
            <Checkbox
              checked={isChecked}
              value={isChecked}
              onChange={checkboxChange}
            />
            <label
              style={{ textDecorationLine: 'underline', color: '#103996' }}
              onClick={() => setModal(true)}
            >
              Aydınlatma Metni
            </label>
          </div>

          <Typography
            onClick={() => navigate('/forgot-password')}
            sx={{ color: '#103996', cursor: 'pointer' }}
          >
            Şifremi Unuttum
          </Typography>
        </Stack>

        <LoadingButton
          disabled={loading || !isChecked}
          fullWidth
          size="large"
          type="submit"
          variant="contained"
          loading={loading}
        >
          Giriş Yap
        </LoadingButton>
      </form>

      <Dialog open={modal} onClose={() => setModal(false)}>
        <DialogTitle>Aydınlatma Metni</DialogTitle>
        <DialogContent>
          <DialogContentText dangerouslySetInnerHTML={{ __html: html }} />
        </DialogContent>
        <DialogActions>
          <Button
            sx={{ backgroundColor: 'green', color: 'white' }}
            onClick={() => {
              setChecked(true);
              setModal(false);
            }}
          >
            Okudum, Anladım, Onaylıyorum
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
