import React, { useState, useEffect } from 'react';
import {
  Modal,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Box,
  Grid,
  Typography,
  ButtonBase,
  IconButton,
  InputAdornment,
  Radio,
  Backdrop,
  CircularProgress,
  Switch,
  Stack,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Alert,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CompleteButton from '../shared/CompleteButton';
import CancelButton from '../shared/CancelButton';
import api from 'src/services/BaseService';
import { toast } from 'react-toastify';
import Services from 'src/services/Services';
import { isAfter } from 'date-fns';
import Iconify from '../iconify/Iconify';

const AddEditPorterModal = ({
  openCreateModal,
  onCreateModal,
  isEdit,
  setIsModalEdit,
  editData,
  pageRefresh,
  malls,
}) => {
  const parsedUser = JSON.parse(localStorage.getItem('user'));
  const [dialogOpen, setDialogOpen] = useState(false);
  const [isBackdropOpen, setIsBackdropOpen] = useState(false);
  const [getirDialogOpen, setGetirDialogOpen] = useState(false);
  const [getirMessage, setGetirMessage] = useState('');
  const [isResetPassButtonDisabled, setIsResetPassButtonDisabled] =
    useState(false);
  const [selectedBranchId, setSelectedBranchId] = useState(null);
  const [branchList, setBranchList] = useState([]);
  const [showPassword, setShowPassword] = useState(false);
  const [mallIDForAPI, setMallIDForAPI] = useState('');
  const [isLoaded, setIsLoaded] = useState(false);
  const [getirID, setGetirID] = useState('');
  const [showResultMessage, setShowResultMessage] = useState(false);
  const [resultMessage, setResultMessage] = useState('');
  const [formData, setFormData] = useState({
    isOperator: false,
    isAdmin: false,
    name: '',
    lastname: '',
    phoneNumber: '',
    mall: [],
    muhaberatUnit: [],
    porterCode: '',
    password: '',
    status: false,
  });

  const handleSwitchChange = () => {
    const { name, value } = event.target;
    // userSetStatus();
    if (name === 'status') {
      setFormData((prevState) => ({
        ...prevState,
        [name]: !formData.status,
      }));
    } else {
      setFormData((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
  };

  useEffect(() => {
    if (isEdit && editData) {
      let isOperator = editData.frontAuth.includes('operations');
      let isAdmin = editData.frontAuth.includes('manager');
      let status = editData.status === '1';
      let mallIDs = editData.authLocations.mall || [];
      let allowedPoints = editData.allowedPoints || [];

      let branches = [];
      mallIDs.forEach((mallID) => {
        let mall = malls.find((mall) => mall.id === mallID);
        if (mall) {
          branches = branches.concat(mall.offices);
        }
      });

      setBranchList(branches);

      let selectedBranch = allowedPoints.find(
        (point) => point.isFirstResponsible
      );
      let selectedBranchId = selectedBranch ? selectedBranch.id : '';

      setFormData({
        isOperator,
        isAdmin,
        name: editData.name,
        lastname: editData.lastname,
        phoneNumber: editData.phone,
        mall: mallIDs,
        muhaberatUnit: allowedPoints.map((point) => point.id),
        porterCode: editData.username,
        password: editData.password,
        status,
      });
      setSelectedBranchId(selectedBranchId);
      setMallIDForAPI(branches.find(branch => branch.id === selectedBranchId).mallID || '');
    }

    // Kullanıcıyı yükleme işlemi tamamlandı
    setIsLoaded(true);
  }, [isEdit, editData, malls]);

  const handleGetirRegister = () => {
    setIsBackdropOpen(true);
    api
      .post('flow/getir-register', {
        userID: editData.id,
      })
      .then((response) => {
        if (response.data) {
          if (response.data.status === true) {
            setGetirID(response.data.result.getirID);
            setGetirMessage("Getir'e yekilendirme başarıyla tamamlandı!");
            setIsBackdropOpen(false);
            handleGetirDialogOpen();
          } else if (response.data.status === false) {
            setGetirMessage("Getir'e yetkilendirirken bir hata oluştu!");
            setIsBackdropOpen(false);
            handleGetirDialogOpen();
          }
          editData.getirID = response.data.result.getirID;
          pageRefresh();
        } else {
          // toast.error(response.data.message);
        }
      })
      .catch((error) => {
        // console.log(error);
        // toast.error(error.message);
      });
  };

  const handleCopyPassword = () => {
    navigator.clipboard
      .writeText(formData.password)
      .then(() => {
        // // console.log('Şifre kopyalandı:', formData.password);
      })
      .catch((err) => {
        console.error('Şifre kopyalanırken bir hata oluştu:', err);
      });
  };

  const handleCopyGetirCode = () => {
    navigator.clipboard
      .writeText(editData.getirID)
      .then(() => {
        // // console.log('Şifre kopyalandı:', formData.password);
      })
      .catch((err) => {
        console.error('Şifre kopyalanırken bir hata oluştu:', err);
      });
  };

  const handleDialogOpen = () => {
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
  };

  const handleGetirDialogOpen = () => {
    setGetirDialogOpen(true);
  };

  const handleGetirDialogClose = () => {
    setGetirDialogOpen(false);
  };

  const handleResetPassword = () => {
    setIsBackdropOpen(true);
    setIsResetPassButtonDisabled(true);
    api
      .post('flow/user-forced-reset-password', {
        userID: editData.id,
      })
      .then((response) => {
        if (response.data) {
          setFormData({
            ...formData,
            password: response.data.result,
          });
          setIsResetPassButtonDisabled(false);
          setIsBackdropOpen(false);
          handleDialogOpen();
          pageRefresh();
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData({
      ...formData,
      [name]: checked,
    });
  };

  const handleTextFieldChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleMallChange = async (e) => {
    const { name, value } = e.target;
    const previousSelection = formData[name] || [];
    const mergedSelection = [
      ...previousSelection,
      ...value.filter((item) => !previousSelection.includes(item)),
    ];

    setFormData({
      ...formData,
      [name]: mergedSelection,
    });

    const selectedMalls = malls.filter((mall) =>
      mergedSelection.includes(mall.id)
    );
    let updatedBranchList = [];
    for (let i = 0; i < selectedMalls.length; i++) {
      const mall = selectedMalls[i];
      const offices = mall.offices;
      for (let j = 0; j < offices.length; j++) {
        updatedBranchList = updatedBranchList.concat(offices[j]);
      }
    }
    setBranchList(updatedBranchList);
  };

  const handleMuhaberatUnitChange = (e) => {
    const { name, value } = e.target;
    const previousSelection = formData[name] || [];
    const mergedSelection = [
      ...previousSelection,
      ...value.filter((item) => !previousSelection.includes(item)),
    ];

    setFormData({
      ...formData,
      [name]: mergedSelection,
    });
  };

  const allFieldsAreValid = () => {
    const errorTextArr = [];
    const {
      isOperator,
      isAdmin,
      name,
      lastname,
      phoneNumber,
      mall,
      muhaberatUnit,
      password,
    } = formData;
    const isFormFilled =
      (isOperator || isAdmin) &&
      name &&
      lastname &&
      phoneNumber.length === 10 &&
      mall.length > 0 &&
      muhaberatUnit.length > 0 &&
      password &&
      selectedBranchId &&
      password.includes(' ') === false;
    const atLeastOneBranchSelectedPerMall = mall.every((mallId) => {
      const branchesForMall = branchList.filter(
        (branch) => branch.mallID === mallId
      );
      return branchesForMall.some((branch) =>
        muhaberatUnit.includes(branch.id)
      );
    });

    if (isFormFilled && atLeastOneBranchSelectedPerMall) {
      return false;
    } else {
      if (isOperator === false && isAdmin === false) {
        errorTextArr.push('En az bir yetki seçiniz');
      }
      if (!name) {
        errorTextArr.push('Porter adı giriniz');
      }
      if (!lastname) {
        errorTextArr.push('Porter soyadı giriniz');
      }
      if (phoneNumber.length < 10) {
        errorTextArr.push('Telefon numarası giriniz');
      }
      if (mall.length === 0) {
        errorTextArr.push('En az 1 tane AVM seçiniz');
      }
      if (muhaberatUnit.length === 0) {
        errorTextArr.push('En az 1 tane birim seçiniz');
      }
      if (!password) {
        errorTextArr.push('Şifre giriniz');
      }
      if (!selectedBranchId) {
        errorTextArr.push('Ana yetki birimi seçiniz');
      }
      if (password.includes(' ')) {
        errorTextArr.push('Şifreden boşluk karakterini siliniz');
      }
      if (atLeastOneBranchSelectedPerMall === false) {
        errorTextArr.push('Seçilen her AVM için en az 1 tane birim seçiniz');
      }
      let errMsg = '';
      for (let i = 0; i < errorTextArr.length; i++) {
        if (i === length - 1) {
          errMsg += errorTextArr[i];
        } else {
          errMsg += errorTextArr[i] + ', ';
        }
      }
      return true;
    }
  };

  const userSetStatus = () => {
    if (formData?.status === false) {
      Services.UserSetPassive(parsedUser.token, editData.id)
        .then((res) => {
          if (res.data.status) {
            setResultMessage('Porter başarıyla güncellendi!');
            setShowResultMessage(true);
            setIsBackdropOpen(true);
          } else {
            setResultMessage('Porter güncellenirken bir sorunla karşılaşıldı!');
            setShowResultMessage(true);
            setIsBackdropOpen(true);
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    } else {
      Services.UserSetActive(parsedUser.token, editData.id)
        .then((res) => {
          if (res.data.status) {
            setResultMessage('Porter başarıyla güncellendi!');
            setShowResultMessage(true);
            setIsBackdropOpen(true);
          } else {
            setResultMessage('Porter güncellenirken bir sorunla karşılaşıldı!');
            setShowResultMessage(true);
            setIsBackdropOpen(true);
          }
        })
        .catch((err) => {
          // console.log(err);
        });
    }
  };

  const handleUpdate = () => {
    setIsBackdropOpen(true);
    let checkIsAdminator = false;
    let frontAuthArr = [];

    if (formData.isAdmin) {
      checkIsAdminator = true;
      frontAuthArr.push('manager');
    }

    if (formData.isOperator) {
      frontAuthArr.push('operations');
    }

    const muhaberatUnitObjects = formData.muhaberatUnit.map((unitId) => {
      return {
        id: unitId,
        isFirstResponsible: unitId === selectedBranchId,
      };
    });
    const mallObject = { mall: formData.mall };
    api
      .post('/users/update-profile', {
        userID: editData.id,
        name: formData.name,
        lastname: formData.lastname,
        phone: formData.phoneNumber,
        password: formData.password,
        isAdministrator: checkIsAdminator,
        mallID: mallIDForAPI,
        type: 'porter',
        allowedPoints: muhaberatUnitObjects,
        frontAuth: frontAuthArr,
        accountCategory: 'mall-logistics',
        authLocations: mallObject,
        status: "0",
      })
      .then((response) => {
        if (response.data) {
          userSetStatus();
        } else {
          setResultMessage('Porter güncellenirken bir sorunla karşılaşıldı!');
          setShowResultMessage(true);
          setIsBackdropOpen(true);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  };

  const handleCloseMessage = () => {
    if (isEdit) {
      setIsModalEdit(false);
    }
    setShowResultMessage(false);
    onCreateModal();
    pageRefresh();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsBackdropOpen(true);
    let checkIsAdminator = false;
    let frontAuthArr = [];

    if (formData.isAdmin) {
      checkIsAdminator = true;
      frontAuthArr.push('manager');
    }

    if (formData.isOperator) {
      frontAuthArr.push('operations');
    }

    const muhaberatUnitObjects = formData.muhaberatUnit.map((unitId) => {
      return {
        id: unitId,
        isFirstResponsible: unitId === selectedBranchId,
      };
    });
    const mallObject = { mall: formData.mall };
    api
      .post('/flow/users-create-employee', {
        name: formData.name,
        lastname: formData.lastname,
        phone: formData.phoneNumber,
        username: 'default',
        password: formData.password,
        isAdministrator: checkIsAdminator,
        mallID: mallIDForAPI,
        type: 'porter',
        allowedPoints: muhaberatUnitObjects,
        frontAuth: frontAuthArr,
        accountCategory: 'mall-logistics',
        authLocations: mallObject,
      })
      .then((response) => {
        if (response.data) {
          setResultMessage('Porter başarıyla eklendi!');
          setShowResultMessage(true);
          setIsBackdropOpen(true);
        } else {
          setResultMessage('Porter eklenirken bir sorunla karşılaşıldı!');
          setShowResultMessage(true);
          setIsBackdropOpen(true);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });

    setIsModalEdit(false);
  };

  const getMallTitleByID = (mallId) => {
    const mall = malls.find((mall) => mall.id === mallId);
    return mall ? mall.title : null;
  };

  const getBranchTitleByID = (branchId) => {
    const branch = branchList.find((branch) => branch.id === branchId);
    return branch ? branch.title : null;
  };

  const handleRemoveMall = (mallId) => {
    const updatedMalls = formData.mall.filter((id) => id !== mallId);

    const updatedMuhaberatUnit = formData.muhaberatUnit.filter((branchId) => {
      const branch = branchList.find((branch) => branch.id === branchId);
      return branch && branch.mallID !== mallId;
    });

    if (updatedMuhaberatUnit.includes(selectedBranchId) === false) {
      setSelectedBranchId('');
    }

    setFormData({
      ...formData,
      mall: updatedMalls,
      muhaberatUnit: updatedMuhaberatUnit,
    });

    const updatedBranchList = branchList.filter((branch) =>
      updatedMalls.includes(branch.mallID)
    );

    setBranchList(updatedBranchList);
  };

  const handleRemoveBranch = (branchId) => {
    const updatedBranches = formData.muhaberatUnit.filter(
      (id) => id !== branchId
    );

    if (branchId === selectedBranchId) {
      setSelectedBranchId('');
    }
    setFormData({
      ...formData,
      muhaberatUnit: updatedBranches,
    });
  };

  const handleSelectBranch = (branchId) => {
    setSelectedBranchId(branchId);
    const selectedBranchMall = branchList.find(
      (object) => object.id === branchId
    );
    setMallIDForAPI(selectedBranchMall.mallID);
  };

  const handleCloseModal = () => {
    onCreateModal(false);
    setIsModalEdit(false);
  };

  if (!isLoaded) {
    return null;
  } else {
    return (
      <Modal open={openCreateModal}>
        <>
          <Backdrop sx={{ color: '#fff', zIndex: 1 }} open={isBackdropOpen}>
            <CircularProgress color="inherit" />
          </Backdrop>
          <Box
            sx={{
              position: 'absolute',
              top: '50%',
              left: '50%',
              transform: 'translate(-50%, -50%)',
              bgcolor: 'background.paper',
              boxShadow: 24,
              p: 4,
              width: '90%',
              maxWidth: 930,
              maxHeight: '100%',
              borderRadius: 2,
              overflow: 'auto',
              paddingBottom: 8,
              paddingTop: 8,
            }}
          >
            <Dialog
              open={showResultMessage}
              onClose={handleCloseMessage}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              {isEdit === true ? (
                <DialogTitle id="alert-dialog-title">
                  {'Porter Düzenleme'}
                </DialogTitle>
              ) : (
                <DialogTitle id="alert-dialog-title">
                  {'Porter Ekleme'}
                </DialogTitle>
              )}
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {resultMessage}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseMessage}>Tamam</Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={getirDialogOpen}
              onClose={handleGetirDialogClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {'Getir Yetkilendirmesi'}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  {getirMessage}
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleGetirDialogClose}>Tamam</Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={dialogOpen}
              onClose={handleDialogClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">{'Yeni Şifre'}</DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Yenilen şifreyi aşağıdan kopyalayabilirsiniz.
                </DialogContentText>
                <br />
                <DialogContentText
                  disabled
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    borderRadius: 1,
                  }}
                  id="alert-dialog-description"
                >
                  <TextField
                    disabled
                    fullWidth
                    type="text"
                    label="Şifre"
                    name="password"
                    value={formData.password}
                    onChange={handleTextFieldChange}
                    margin="normal"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Button onClick={handleCopyPassword}>
                            <ContentCopyIcon color="action" />
                          </Button>
                        </InputAdornment>
                      ),
                    }}
                  />
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleDialogClose}>Kapat</Button>
              </DialogActions>
            </Dialog>
            <form onSubmit={handleSubmit}>
              {!isEdit && (
                <div
                  style={{
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    marginBottom: '15px',
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: 'Inter',
                      fontSize: '24px',
                      fontWeight: '600',
                      color: '#000000',
                    }}
                    gutterBottom
                  >
                    Porter Ekleme Formu
                  </Typography>
                </div>
              )}

              {isEdit === false ? (
                <>
                  <Typography
                    sx={{
                      fontFamily: 'Inter',
                      fontSize: '18px',
                      fontWeight: '600',
                      color: '#374151',
                    }}
                    gutterBottom
                  >
                    Porter Ekle
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: 'Inter',
                      fontSize: '14px',
                      fontWeight: '400',
                      color: '#6B7280',
                    }}
                    gutterBottom
                  >
                    Lütfen listeye eklemek istediğiniz kullanıcı bilgilerini
                    doldurun.
                  </Typography>
                </>
              ) : (
                <>
                  <Typography
                    sx={{
                      fontFamily: 'Inter',
                      fontSize: '18px',
                      fontWeight: '600',
                      color: '#374151',
                    }}
                    gutterBottom
                  >
                    Porter Bilgilerini Düzenle
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: 'Inter',
                      fontSize: '14px',
                      fontWeight: '400',
                      color: '#6B7280',
                    }}
                    gutterBottom
                  >
                    Lütfen düzenlemek istediğiniz kullanıcı bilgilerini
                    güncelleyin.
                  </Typography>
                </>
              )}

              {isEdit && (
                <div
                  style={{
                    width: '100%',
                    height: '1px',
                    backgroundColor: '#EAECF0',
                    marginTop: '20px',
                    marginBottom: '15px',
                  }}
                />
              )}

              {isEdit && (
                <Stack
                  width="100%"
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                >
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: '600',
                        fontSize: '14px',
                        color: '#1570EF',
                        fontFamily: 'Inter',
                        marginRight: '5px',
                      }}
                    >
                      {editData.name} {editData.lastname}
                    </Typography>
                    <Typography
                      sx={{
                        fontWeight: '500',
                        fontSize: '14px',
                        fontFamily: 'Inter',
                        marginRight: '5px',
                      }}
                    >
                      adlı kullanıcının yetkisini düzenleyin.
                    </Typography>
                  </div>
                  <div
                    style={{
                      flexDirection: 'row',
                      display: 'flex',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                    }}
                  >
                    <Typography
                      sx={{
                        fontWeight: '500',
                        fontSize: '14px',
                        color: '#6B7280',
                        fontFamily: 'Inter',
                        marginRight: '5px',
                      }}
                    >
                      Kullanıcı Durumu:
                    </Typography>
                    {formData.status ? (
                      <Typography
                        sx={{
                          fontWeight: '500',
                          fontSize: '14px',
                          color: '#079455',
                          fontFamily: 'Inter',
                        }}
                      >
                        Aktif
                      </Typography>
                    ) : (
                      <Typography
                        sx={{
                          fontWeight: '500',
                          fontSize: '14px',
                          color: '#D92D20',
                          fontFamily: 'Inter',
                        }}
                      >
                        Pasif
                      </Typography>
                    )}

                    <Switch
                      checked={formData.status}
                      onChange={handleSwitchChange}
                      name="status"
                      color="primary"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                  </div>
                </Stack>
              )}

              <div
                style={{
                  width: '100%',
                  height: '1px',
                  backgroundColor: '#EAECF0',
                  marginTop: '20px',
                }}
              />

              <Grid item xs={12} container alignItems="center" spacing={2}>
                <Grid xs={4} item>
                  <Typography
                    sx={{
                      fontFamily: 'Inter',
                      color: '#667085',
                      fontWeight: 600,
                      fontSize: '14px',
                    }}
                  >
                    Kullanıcı Bilgileri
                  </Typography>
                </Grid>
                <Grid xs={4} item>
                  <TextField
                    fullWidth
                    label="Ad*"
                    name="name"
                    value={formData.name}
                    onChange={handleTextFieldChange}
                    margin="normal"
                  />
                </Grid>
                <Grid xs={4} item>
                  <TextField
                    fullWidth
                    label="Soyad*"
                    name="lastname"
                    value={formData.lastname}
                    onChange={handleTextFieldChange}
                    margin="normal"
                  />
                </Grid>
              </Grid>
              <Grid item xs={12} container alignItems="center" spacing={2}>
                <Grid xs={4} item></Grid>
                <Grid xs={8} item>
                  {isEdit === false ? (
                    <>
                      <TextField
                        fullWidth
                        type={showPassword ? 'text' : 'password'}
                        label="Şifre*"
                        name="password"
                        value={formData.password}
                        onChange={handleTextFieldChange}
                        margin="normal"
                        InputProps={{
                          endAdornment: (
                            <InputAdornment position="end">
                              <IconButton
                                onClick={() => setShowPassword(!showPassword)}
                                edge="end"
                              >
                                <Iconify
                                  icon={
                                    showPassword
                                      ? 'eva:eye-fill'
                                      : 'eva:eye-off-fill'
                                  }
                                />
                              </IconButton>
                            </InputAdornment>
                          ),
                        }}
                      />
                      {formData.password.includes(' ') ? (
                        <Alert severity="error">
                          Şifre "boşluk" karakteri içeremez!
                        </Alert>
                      ) : null}
                    </>
                  ) : (
                    <CompleteButton
                      label={'Şifreyi Sıfırla'}
                      disabled={isResetPassButtonDisabled}
                      onClick={() => handleResetPassword()}
                      sx={{
                        mt: 2,
                        ml: 'auto',
                        float: 'left',
                        width: '210px  ',
                      }}
                    />
                  )}
                </Grid>
              </Grid>

              <div
                style={{
                  width: '100%',
                  height: '1px',
                  backgroundColor: '#EAECF0',
                  marginTop: '20px',
                  marginBottom: '15px',
                }}
              />

              <Grid item xs={12} container alignItems="center" spacing={2}>
                <Grid xs={4} item>
                  <Typography
                    sx={{
                      fontFamily: 'Inter',
                      color: '#667085',
                      fontWeight: 600,
                      fontSize: '14px',
                    }}
                  >
                    Kullanıcı İletişim
                  </Typography>
                </Grid>
                <Grid xs={8} item>
                  <TextField
                    fullWidth
                    type="number"
                    label="5XXXXXXXXX*"
                    name="phoneNumber"
                    value={formData.phoneNumber}
                    onChange={handleTextFieldChange}
                    margin="normal"
                    onInput={(e) => {
                      let inputValue = e.target.value;
                      if (inputValue < 0) {
                        inputValue = '0';
                      }
                      if (inputValue.startsWith('0')) {
                        inputValue = inputValue.slice(1);
                      }
                      if (inputValue.length > 10) {
                        inputValue = inputValue.slice(0, 10);
                      }
                      if (inputValue !== e.target.value) {
                        e.target.value = inputValue;
                        setFormData({
                          ...formData,
                          phoneNumber: inputValue,
                        });
                      }
                    }}
                  />
                </Grid>
              </Grid>

              <div
                style={{
                  width: '100%',
                  height: '1px',
                  backgroundColor: '#EAECF0',
                  marginTop: '20px',
                  marginBottom: '15px',
                }}
              />

              <Grid item xs={12} container alignItems="center" spacing={2}>
                <Grid xs={4} item>
                  <Typography
                    sx={{
                      fontFamily: 'Inter',
                      color: '#667085',
                      fontWeight: 600,
                      fontSize: '14px',
                    }}
                  >
                    Kullanıcı Konum
                  </Typography>
                </Grid>
                <Grid xs={4} item>
                  <FormControl fullWidth margin="normal">
                    <InputLabel>AVM*</InputLabel>
                    <Select
                      label="AVM*"
                      value={formData.mall}
                      onChange={handleMallChange}
                      name="mall"
                      multiple
                      disabled={malls?.length === 0}
                    >
                      {malls?.map((mall, index) => (
                        <MenuItem key={index} value={mall.id}>
                          {mall.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid xs={4} item>
                  <FormControl fullWidth margin="normal">
                    {formData.mall.length === 0 ? (
                      <InputLabel>
                        Çalıştığı Mal Bölüm (Önce AVM Seçiniz)*
                      </InputLabel>
                    ) : (
                      <InputLabel>Çalıştığı Bölüm*</InputLabel>
                    )}
                    <Select
                      label="Çalıştığı Bölüm*"
                      value={formData.muhaberatUnit}
                      onChange={handleMuhaberatUnitChange}
                      name="muhaberatUnit"
                      multiple
                      disabled={branchList.length === 0}
                    >
                      {branchList.map((branch, index) => (
                        <MenuItem key={index} value={branch.id}>
                          {branch.title}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              {formData.mall.length > 0 && (
                <>
                  <div
                    style={{
                      width: '100%',
                      height: '1px',
                      backgroundColor: '#EAECF0',
                      marginTop: '20px',
                      marginBottom: '20px',
                    }}
                  />
                  <Grid item xs={12} container alignItems="center" spacing={2}>
                    <Grid xs={4} item>
                      <Typography
                        sx={{
                          fontFamily: 'Inter',
                          color: '#667085',
                          fontWeight: 600,
                          fontSize: '14px',
                        }}
                      >
                        AVM'ler
                      </Typography>
                    </Grid>
                    <Grid item xs={8} container alignItems="center" spacing={2}>
                      {formData.mall.map((mallId, index) => (
                        <Grid item key={index} justifyContent="space-between">
                          <Chip
                            label={getMallTitleByID(mallId)}
                            variant="outlined"
                            sx={{ borderRadius: 0 }}
                            onDelete={() => handleRemoveMall(mallId)}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </>
              )}

              {formData.muhaberatUnit.length > 0 && (
                <>
                  <div
                    style={{
                      width: '100%',
                      height: '1px',
                      backgroundColor: '#EAECF0',
                      marginTop: '20px',
                      marginBottom: '15px',
                    }}
                  />

                  <Grid
                    item
                    xs={12}
                    container
                    alignItems="center"
                    spacing={2}
                    sx={{ marginTop: '15px' }}
                  >
                    <Grid xs={4} item>
                      <Typography
                        sx={{
                          fontFamily: 'Inter',
                          color: '#667085',
                          fontWeight: 600,
                          fontSize: '14px',
                        }}
                      >
                        Bölümler
                      </Typography>
                    </Grid>
                    <Grid item xs={8} container alignItems="center" spacing={2}>
                      {formData.muhaberatUnit.map((branchId, index) => {
                        const branchTitle = getBranchTitleByID(branchId);
                        return branchTitle ? (
                          <Grid
                            item
                            xs={6}
                            key={index}
                            justifyContent="space-between"
                            sx={{ marginTop: -2 }}
                          >
                            <FormControlLabel
                              control={
                                <Radio
                                  checked={selectedBranchId === branchId}
                                  onChange={() => handleSelectBranch(branchId)}
                                />
                              }
                            />
                            <Chip
                              label={branchTitle}
                              variant="outlined"
                              sx={{ borderRadius: 0, maxWidth: '200px' }}
                              onDelete={() => handleRemoveBranch(branchId)}
                            />
                          </Grid>
                        ) : null;
                      })}
                    </Grid>
                  </Grid>
                </>
              )}

              <div
                style={{
                  width: '100%',
                  height: '1px',
                  backgroundColor: '#EAECF0',
                  marginTop: '20px',
                  marginBottom: '15px',
                }}
              />

              <Grid item xs={12} container alignItems="center" spacing={2}>
                <Grid xs={4} item>
                  <Typography
                    sx={{
                      fontFamily: 'Inter',
                      color: '#667085',
                      fontWeight: 600,
                      fontSize: '14px',
                    }}
                  >
                    Kullanıcı Yetkisi
                  </Typography>
                </Grid>
                <Grid xs={4} item>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.isOperator}
                        onChange={handleCheckboxChange}
                        name="isOperator"
                      />
                    }
                    label={
                      <ButtonBase
                        onClick={() =>
                          setFormData({
                            ...formData,
                            isOperator: !formData.isOperator,
                          })
                        }
                        sx={{
                          borderRadius: 0,
                          cursor: 'pointer',
                          backgroundColor: 'transparent',
                        }}
                      >
                        <Typography>Operasyon</Typography>
                      </ButtonBase>
                    }
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={formData.isAdmin}
                        onChange={handleCheckboxChange}
                        name="isAdmin"
                      />
                    }
                    label={
                      <ButtonBase
                        onClick={() =>
                          setFormData({
                            ...formData,
                            isAdmin: !formData.isAdmin,
                          })
                        }
                        sx={{
                          borderRadius: 0,
                          cursor: 'pointer',
                          backgroundColor: 'transparent',
                        }}
                      >
                        <Typography>Yönetici</Typography>
                      </ButtonBase>
                    }
                  />
                </Grid>
              </Grid>

              {isEdit && (
                <>
                  <div
                    style={{
                      width: '100%',
                      height: '1px',
                      backgroundColor: '#EAECF0',
                      marginTop: '20px',
                      marginBottom: '15px',
                    }}
                  />

                  <Grid item xs={12} container alignItems="center" spacing={2}>
                    <Grid xs={4} item>
                      <Typography
                        sx={{
                          fontFamily: 'Inter',
                          color: '#667085',
                          fontWeight: 600,
                          fontSize: '14px',
                        }}
                      >
                        Porter Bilgileri
                      </Typography>
                    </Grid>
                    <Grid xs={6} item>
                      <TextField
                        fullWidth
                        label="Porter Kodu*"
                        name="porterCode"
                        value={formData.porterCode}
                        margin="normal"
                        disabled
                      />
                    </Grid>
                  </Grid>

                  <div
                    style={{
                      width: '100%',
                      height: '1px',
                      backgroundColor: '#EAECF0',
                      marginTop: '20px',
                      marginBottom: '15px',
                    }}
                  />

                  <Grid item xs={12} container alignItems="center" spacing={2}>
                    <Grid xs={4} item>
                      <Typography
                        sx={{
                          fontFamily: 'Inter',
                          color: '#667085',
                          fontWeight: 600,
                          fontSize: '14px',
                        }}
                      >
                        Getir Kayıt / Kod
                      </Typography>
                    </Grid>
                    <Grid xs={6} item>
                      {editData.getirID !== null && editData.getirID !== '' ? (
                        <TextField
                          fullWidth
                          label="Getir Kodu*"
                          name="getirCode"
                          value={editData.getirID}
                          margin="normal"
                          disabled
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <Button onClick={handleCopyGetirCode}>
                                  <ContentCopyIcon color="action" />
                                </Button>
                              </InputAdornment>
                            ),
                          }}
                        />
                      ) : (
                        <Button
                          onClick={() => handleGetirRegister()}
                          style={{
                            color: '#FBD30C',
                            backgroundColor: '#5C3CBB',
                            width: '250px',
                            height: '46px',
                            borderRadius: '5px',
                          }}
                        >
                          Getir'e Kaydet
                        </Button>
                      )}
                    </Grid>
                  </Grid>

                  <div
                    style={{
                      width: '100%',
                      height: '1px',
                      backgroundColor: '#EAECF0',
                      marginTop: '20px',
                      marginBottom: '15px',
                    }}
                  />
                </>
              )}
              <div
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'flex',
                  marginTop: '25px',
                }}
              >
                <CancelButton
                  onClick={() => handleCloseModal()}
                  label={'İptal'}
                  sx={{ marginRight: '24px' }}
                />

                {isEdit === true ? (
                  <CompleteButton
                    label={'Güncelle'}
                    disabled={allFieldsAreValid()}
                    onClick={() => handleUpdate()}
                    sx={{ width: '250px' }}
                  />
                ) : (
                  <CompleteButton
                    label={'Kullanıcı Ekle'}
                    disabled={allFieldsAreValid()}
                    btnType={'save'}
                    type="submit"
                    sx={{ width: '250px' }}
                  />
                )}
              </div>
            </form>
          </Box>
        </>
      </Modal>
    );
  }
};

export default AddEditPorterModal;
