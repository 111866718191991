import React from 'react'
import EditableDataTable from 'src/components/modals/EditableDataTable/EditableDataTable'

export default function DataTableTest() {

    const headers = [
        { "name": "İsim", "type": "text" },
        { "name": "Yaş", "type": "number" },
        { "name": "Şehir", "type": "select", "options": ["Istanbul", "Ankara", "Izmir", "Bursa","Adana","Antalya","Konya","Gaziantep","Samsun","Eskişehir"] },
        { "name": "Meslek", "type": "text" },
        { "name": "Doğum Tarihi", "type": "date" },
        { "name": "Aktif", "type": "boolean" }
      ]
      
      

    const data = [
        { "id": 1, "İsim": "Ali", "Yaş": 25, "Şehir": "Istanbul", "Meslek": "Mühendis", "Doğum Tarihi": "1997-05-10", "Aktif": true },
        { "id": 2, "İsim": "Ayşe", "Yaş": 30, "Şehir": "Ankara", "Meslek": "Doktor", "Doğum Tarihi": "1993-03-15", "Aktif": false },
        { "id": 3, "İsim": "Ahmet", "Yaş": 28, "Şehir": "Izmir", "Meslek": "Öğretmen", "Doğum Tarihi": "1995-07-20", "Aktif": true },
        { "id": 4, "İsim": "Fatma", "Yaş": 35, "Şehir": "Bursa", "Meslek": "Hemşire", "Doğum Tarihi": "1989-12-10", "Aktif": true },
        { "id": 5, "İsim": "Mehmet", "Yaş": 40, "Şehir": "Adana", "Meslek": "Mimar", "Doğum Tarihi": "1983-04-25", "Aktif": false },
        { "id": 6, "İsim": "Elif", "Yaş": 22, "Şehir": "Antalya", "Meslek": "Grafiker", "Doğum Tarihi": "2001-08-30", "Aktif": true },
        { "id": 7, "İsim": "Mustafa", "Yaş": 33, "Şehir": "Konya", "Meslek": "Avukat", "Doğum Tarihi": "1990-02-18", "Aktif": true },
        { "id": 8, "İsim": "Zeynep", "Yaş": 27, "Şehir": "Gaziantep", "Meslek": "Psikolog", "Doğum Tarihi": "1996-01-22", "Aktif": false },
        { "id": 9, "İsim": "Kerem", "Yaş": 31, "Şehir": "Samsun", "Meslek": "Eczacı", "Doğum Tarihi": "1992-11-05", "Aktif": true },
        { "id": 10, "İsim": "Hülya", "Yaş": 29, "Şehir": "Eskişehir", "Meslek": "İşletmeci", "Doğum Tarihi": "1994-06-12", "Aktif": false }
      ]
      
      


    return (
        <EditableDataTable data={data} headers={headers} showNoColumn={true} minimumRowsCount={35}/>
    )
}
