import { Helmet } from 'react-helmet-async';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
// @mui
import {
  Card,
  Stack,
  Button,
  Box,
  Checkbox,
  MenuItem,
  Typography,
  TextField,
  Modal,
  FormControl,
  Select,
  OutlinedInput,
  Divider,
  Tooltip,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
// components
import Iconify from '../components/iconify';
// sections
import api from 'src/services/BaseService';
import { tokenService } from 'src/services';
import CreateWorkOrderModal from 'src/components/modals/CreateWorkOrderModal';
import DeliveryModal from 'src/components/modals/DeliveryModal';
import AddEditPersonelModal from 'src/components/modals/AddEditPersonelModal';
import './muhaberatOutput.css';
import AddEntityButton from 'src/components/shared/AddEntityButton';
import EditButton from 'src/components/shared/EditButton';
import ClearButton from 'src/components/shared/ClearButton';
import FilterButton from 'src/components/shared/FilterButton';
import ExportToExcelButton from 'src/components/shared/ExportToExcelButton';
import DynamicTable from 'src/components/shared/DynamicTable';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import CustomFilter from 'src/components/filter/CustomFilter';
import FilterViewButton from 'src/components/shared/FilterViewButton';
// ----------------------------------------------------------------------

const directions = [
  {
    value: 'all',
    label: 'Tümü',
  },
  {
    value: 'MallLogisticsToCustomer',
    label: 'Muhaberattan Müşteriye',
  },
  {
    value: 'CustomerToMallLogistics',
    label: 'Müşteriden Muhaberata',
  },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const StatusList = [
  { label: 'Şubede', id: 5 },
  { label: 'Tamamlandı', id: 1 },
];

export default function MuhaberatOutput() {
  const customer = useSelector((state) => state.customerReducer);
  const [isBackdropOpen, setIsBackdropOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [selected, setSelected] = useState([]);
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectedDirection, setSelectedDirection] = useState({
    value: 'all',
    label: 'Tümü',
  });
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [originalData, setOriginalData] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [selectedCargoStatus, setSelectedCargoStatus] = useState([]);
  const [locations, setLocations] = useState([]);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [firm, setFirm] = useState('');
  const [barcode, setBarcode] = useState('');
  const [cargo, setCargo] = useState('');
  const [tower, setTower] = useState('');
  const [office, setOffice] = useState('');
  const [tableHead, setTableHead] = useState([]);

  const [handoverModal, setHandoverModal] = useState(false);
  const [selectedOrder, setSelectedOrder] = useState('');
  const [addPersonelModalOpen, setAddPersonelModalOpen] = useState(false);
  const [showAddPersonelModal, setShowAddPersonelModal] = useState(false);
  const [personelList, setPersonelList] = useState([]);

  const [confirmCode, setConfirmCode] = useState(null);
  const [isSuccessModalOpen, setSuccessModalOpen] = useState(false);
  const [isModalEdit, setIsModalEdit] = useState(false);
  const [recieverNameLastname, setRecieverNameLastname] = useState('');
  const [open, setOpen] = useState(false);
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [showPopUpError, setShowPopUpError] = useState(false);

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [filterModal, setFilterModal] = useState(false);
  const isMobile = useMediaQuery('(max-width:768px)');
  const handleModal = () => {
    setFilterModal(true);
  };

  const handleCloseModal = () => {
    setFilterModal(false);
  };

  const TABLE_HEAD = [
    {
      id: 'checkbox', // Checkbox sütununun id'si
      label: '', // Başlık boş bırakılabilir
      alignRight: false,
      format: (value, row) => {
        // Checkbox'ı row.status değerine göre göster
        if (row.status === '5') {
          return (
            <Checkbox
              checked={selected.indexOf(row.id) !== -1}
              onChange={(e) => handleCheckboxChange(e, row.id, row)}
            />
          );
        }
        return null;
      },
      sx: { width: 50 }, // Genişlik ayarı
    },
    { id: 'no', label: 'No', alignRight: false, sx: { width: 10 } },
    { id: 'barcode', label: 'Barkod', alignRight: false },
    {
      id: 'title',
      label: 'Kişi',
      alignRight: false,
      sortBy: 'dataJSON.customerName',
      format: (value, row) => {
        return row.dataJSON?.customerName;
      },
    },
    { id: 'branchTitle', label: 'Firma', alignRight: false },
    {
      id: 'tower',
      label: 'Kule',
      alignRight: false,
      format: (value, row) => {
        return row.dataJSON?.tower;
      },
      sortBy: 'dataJSON.tower',
    },
    {
      id: 'office',
      label: 'Daire',
      alignRight: false,
      format: (value, row) => {
        return row.dataJSON?.office;
      },
      sortBy: 'dataJSON.office',
    },
    {
      id: 'corporationID',
      label: 'Firma ID',
      alignRight: false,
      format: (value, row) => {
        return row.customer?.corporationID;
      },
      sortBy: 'customer.corporationID',
    },
    {
      id: 'status',
      label: 'Kargo Durumu',
      alignRight: false,
      format: (value, row) => {
        if (row.status === '5') {
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'left',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <span
                style={{
                  backgroundColor: 'rgba(247, 169, 55, 0.36)',
                  padding: '7px',
                  borderRadius: '5px',
                }}
              >
                Şubede
              </span>
            </div>
          );
        } else {
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'left',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <span
                style={{
                  backgroundColor: 'rgba(10, 127, 50, 0.24)',
                  padding: '7px',
                  borderRadius: '5px',
                }}
              >
                Teslim Edildi
              </span>
            </div>
          );
        }
      },
    },
    {
      id: 'createdDateTime',
      label: 'Oluşturulma Tarihi',
      alignRight: false,
      format: (value) => moment.unix(value).format('DD/MM/YYYY HH:mm'),
    },
    {
      id: 'shipmentFirm',
      label: 'Kargo Firması',
      sortBy: 'dataJSON.shipmentFirm',
      alignRight: false,
      format: (value, row) => {
        return row.dataJSON?.shipmentFirm;
      },
    },
    {
      id: 'operation',
      label: '',
      alignRight: false,
      format: (value, row) => {
        if (row.status === '5')
          return (
            <EditButton onClick={() => handleOpenDeliveryModal(row.id, row)} />
          );
      },
      sx: { width: 100 },
    },
  ];

  useEffect(() => {
    const handleOnlineStatus = () => setIsOnline(navigator.onLine);

    window.addEventListener('online', handleOnlineStatus);
    window.addEventListener('offline', handleOnlineStatus);

    return () => {
      window.removeEventListener('online', handleOnlineStatus);
      window.removeEventListener('offline', handleOnlineStatus);
    };
  }, []);

  useEffect(() => {
    const mallID = localStorage.getItem('mallID');

    if (mallID === '61973122632916808660') {
      setShowAddPersonelModal(true);
    } else {
      setShowAddPersonelModal(false);
    }

    getFilteredOrderList();
  }, [customer]);

  const getFilteredOrderList = () => {
    let filteredOrderList = originalData;
    if (
      barcode ||
      firm ||
      cargo ||
      tower ||
      office ||
      selectedCargoStatus.length > 0 ||
      startDate ||
      endDate ||
      filterName ||
      selectedDirection.value !== 'all'
    ) {
      filteredOrderList = originalData.filter((item) => {
        const filterNameMatch = filterName
          ? item.dataJSON.customerName
            .toLowerCase()
            .includes(filterName.toLowerCase())
          : true;
        const barcodeMatch = barcode
          ? item.barcode.toLowerCase().includes(barcode.toLowerCase())
          : true;
        const firmMatch = firm
          ? item.branchTitle.toLowerCase().includes(firm.toLowerCase())
          : true;
        const cargoMatch = cargo
          ? item.dataJSON.shipmentFirm
            .toLowerCase()
            .includes(cargo.toLowerCase())
          : true;
        const towerMatch = tower
          ? item.dataJSON?.tower?.toLowerCase().includes(tower.toLowerCase())
          : true;
        const officeMatch = office
          ? item.dataJSON?.office?.toLowerCase().includes(office.toLowerCase())
          : true;

        const statusMatch =
          selectedCargoStatus.length > 0
            ? selectedCargoStatus.some(
              (status) =>
                status.id === Number(item.status) || status.id === item.status
            )
            : true;

        const createdDateMatch =
          startDate && endDate
            ? moment
              .unix(item.createdDateTime)
              .isBetween(
                moment(startDate).startOf('day'),
                moment(endDate).endOf('day'),
                null,
                '[]'
              )
            : true;
        const directionMatch =
          selectedDirection.value !== 'all'
            ? item.dataJSON.direction === selectedDirection.value
            : true;

        return (
          barcodeMatch &&
          firmMatch &&
          cargoMatch &&
          towerMatch &&
          officeMatch &&
          statusMatch &&
          createdDateMatch &&
          directionMatch &&
          filterNameMatch
        );
      });
    } else {
      filteredOrderList = originalData;
    }

    setOrderList(filteredOrderList);
  };

  const clearFilters = () => {
    setOrderList(originalData);
    setBarcode('');
    setFirm('');
    setCargo('');
    setTower('');
    setOffice('');
    setSelectedCargoStatus([]);
    setSelectedDirection({ value: 'all', label: 'Tümü' });
    setStartDate(null);
    setEndDate(null);
    setFilterName('');
  };

  const handleChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedCargoStatus(
      typeof value === 'string' ? value.split(',') : value
    );
  };

  const changeDirection = (event) => {
    const {
      target: { value },
    } = event;
    const selectedDir = directions.find((dir) => dir.value === value) || {
      value: 'all',
      label: 'Tümü',
    };
    setSelectedDirection(selectedDir);
  };

  const handleCheckboxChange = (event, id, object) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [...selected];
    let newSelectedItems = [...selectedItems];
    if (selectedIndex === -1) {
      newSelected.push(id);
      newSelectedItems.push(object);
    } else {
      newSelected.splice(selectedIndex, 1);
      const itemIndex = newSelectedItems.findIndex((item) => item.id === id);
      newSelectedItems.splice(itemIndex, 1);
    }
    setSelected(newSelected);
    setSelectedItems(newSelectedItems);
    console.log('newSelectedItems', newSelectedItems);
  };

  const selectAll = () => {
    const pageStartIndex = page * rowsPerPage;
    const pageEndIndex = pageStartIndex + rowsPerPage;

    const selectedItems = orderList
      // .slice(pageStartIndex, pageEndIndex)
      .filter((row) => row.status === '5')
      .map((row) => row);

    const selectedIds = orderList
      // .slice(pageStartIndex, pageEndIndex)
      .filter((row) => row.status === '5')
      .map((row) => row.id);

    setSelectedItems(selectedItems);
    setSelected(selectedIds);
    // setSelectedItems(selectedIds);
    console.log('selectedIds', selectedIds);
  };

  function exportToExcel(list) {
    let temp1 = list?.map((x, index) => {
      let a = {
        id: index + 1,
        customer: x.dataJSON.customerName || '',
        barcode: x.barcode || '',
        willTakenBy: recieverNameLastname || '',
        lobbyDelivery: '',
        amount: x.orderInventory || '',
        shipmentFirm: x.dataJSON.shipmentFirm || '',
        // companyName: x.dataJSON.companyName,
        // takingDelivery: x.handover.name + ' ' + x.handover.lastname,

        // createdDateTime: moment(new Date(Number(x.createdDateTime))).format(
        //   'DD/MM/YYYY'
        // ),
      };
      return a;
    });
    api
      .post('excel/export', {
        type: 'excel',
        content: temp1,
        header: {
          NO: 'number',
          'İSİM SOYİSİM': 'string',
          // 'Firma/Kişi': 'string',
          BARKOD: 'string',
          'TESLİM ALAN': 'string',
          // 'Oluşturulma Tarihi': 'string',
          'LOBİ TESLİMATI': 'string',
          MİKTAR: 'string',
          'KARGO İSMİ': 'string',
        },
        sheetTitle: 'İşler',
      })
      .then((response) => {
        if (response.data.status) {
          try {
            const newWindow = window.open(response.data.result, '_blank');
            if (
              !newWindow ||
              newWindow.closed ||
              typeof newWindow.closed == 'undefined'
            ) {
              setShowPopUpError(true);
              throw new Error(
                'Pop-up engellendi. Lütfen pop-up engelleyicisini devre dışı bırakın.'
              );
            }
          } catch (e) {
            setShowPopUpError(true);
          }
        } else {
          toast.error("EXCEL'e aktarılırken bir hata meydana geldi.");
        }
      })
      .catch((error) => {
        toast.error("EXCEL'e aktarılırken bir hata meydana geldi.");
      });
  }

  const createPDF = () => {
    const table = objectsToTable(selectedItems);
    const nonEmptyRows = table
      .slice(1)
      .map((row, index) => {
        const customerInfo = row[31] || {};
        const barcode = customerInfo.barcode || '';
        const customerName = customerInfo.customerName || '';
        const shipmentFirm = customerInfo.shipmentFirm || '';
        const count = customerInfo.orderInventory || '';
        // console.log("Row:", row[16], row[31])
        return [
          index + 1, // No
          customerName, // Müşteri Adı Soyadı
          barcode, // Barkod
          recieverNameLastname || '', // Teslim Alan
          '', // Lobi Teslimatı
          count, // Miktar
          shipmentFirm, // Kargo İsmi
        ];
      })
      .filter(Boolean);
    if (nonEmptyRows?.length > 0) {
      const headerText = `Zorlu Center - ${moment().format(
        'DD.MM.YYYY HH:mm'
      )} - ${nonEmptyRows.length} Adet`;
      const docDefinition = {
        content: [
          // {text: 'Belge No: 1565743286', style: 'header' },
          { text: headerText, style: 'header' },
          {
            table: {
              headerRows: 1,
              widths: ['auto', 'auto', 'auto', 'auto', 'auto', 'auto', 'auto'],
              body: [
                [
                  'No',
                  'İsim Soyisim',
                  'Barkod',
                  'Teslim Alan',
                  'Lobi Teslimatı',
                  'Miktar',
                  'Kargo İsmi',
                ],
                ...nonEmptyRows,
              ],
              alignment: 'center',
              margin: ['auto'],
            },
          },
        ],
        styles: {
          header: {
            fontSize: 18,
            bold: true,
            margin: [0, 0, 0, 10],
            alignment: 'center',
          },
        },
      };
      const pdfDocGenerator = pdfMake.createPdf(docDefinition);
      pdfDocGenerator.open();
      pdfDocGenerator.getBlob((blob) => {
        const url = URL.createObjectURL(blob);
        const a = document.createElement('a');
        a.href = url;
        a.download = `Muhaberat_${moment().format('DD-MM-YYYY_HH:mm')}.pdf`;
        a.click();
        URL.revokeObjectURL(url);
      });
    }
  };

  function objectsToTable(objectArray) {
    if (objectArray.length === 0) return []; // Eğer dizi boşsa boş bir tablo döndür

    // Objelerin tüm özelliklerini alarak sütun başlıklarını oluştur
    const columnHeaders = Object.keys(objectArray[0]);

    // Tablo başlığı (sütun başlıkları)
    const table = [columnHeaders];

    // Her objeyi döngü ile işle ve tabloya ekle
    objectArray.forEach((obj) => {
      const row = columnHeaders.map((header) => obj[header]);
      table.push(row);
    });

    return table;
  }

  useEffect(() => {
    setTableHead(TABLE_HEAD);
    api
      .post('flow/concierge-get-users', {
        metaTags: ['Zorlu Center'],
      })
      .then((response) => {
        if (response.data.status) {
          setPersonelList(response.data.result);
        } else {
          setPersonelList([]);
        }
      })
      .catch((error) => {
        console.log(error);
        setPersonelList([]);
      });

    api
      .post('posts/get', {
        type: 'post-office',
        mallID: customer?.customer?.id,
        status: StatusList.filter((x) => x.id != 0).map((x) => x.id),
        limit: 20000,
      })
      .then((response) => {
        if (response.data.status) {
          const data = response.data.result.filter(
            (item) =>
              item.dataJSON.direction === 'MallLogisticsToCustomer' ||
              item.dataJSON.direction === 'CustomerToMallLogistics'
          );
          setOriginalData(data);
          setOrderList(data);
        } else {
          toast.error('Veri bulunamadı.');
          setOriginalData([]);
          setOrderList([]);
        }
      })
      .catch(() => {
        toast.error('Veriler çekilemedi, bağlantınızı kontrol ediniz.');
        setOriginalData([]);
        setOrderList([]);
      });
  }, [customer]);

  const handleOpenAddPersonelModal = () => {
    setAddPersonelModalOpen(true);
    setIsModalEdit(false);
  };

  const handleOpenDeliveryModal = (id, object) => {
    const selectedIndex = selected.indexOf(id);
    let newSelected = [...selected];
    let newSelectedItems = [...selectedItems];
    if (selectedIndex === -1) {
      newSelected.push(id);
      newSelectedItems.push(object);
    } else {
      newSelected.splice(selectedIndex, 1);
      const itemIndex = newSelectedItems.findIndex((item) => item.id === id);
      newSelectedItems.splice(itemIndex, 1);
    }

    setSelected(newSelected);
    setSelectedItems(newSelectedItems);
    setHandoverModal(true);
  };

  const handleCloseDeliveryModal = () => {
    setSelected([]);
    setSelectedItems([]);
    setHandoverModal(false);
  };

  const handleBack = () => {
    setSelectedOrder(null);
  };

  const completeDeliver = (code) => {
    setIsBackdropOpen(true);
    const selectedObjects = selected?.map((item) => {
      return { postID: item, status: 1 };
    });
    if (code === confirmCode) {
      // change status
      api.post('posts/change-status', selectedObjects).then((response) => {
        if (response.data.status) {
          setIsBackdropOpen(false);
          setHandoverModal(false);
          getFilteredOrderList();
          setSuccessModalOpen(true);
        } else {
          setIsBackdropOpen(false);
        }
      });
    } else {
      setIsBackdropOpen(false);
      toast.error('Doğrulama kodu hatalıdır. Lütfen tekrar deneyin.');
    }
  };

  const handleDeliver = (name, lastname, phone, note) => {
    setRecieverNameLastname(name + ' ' + lastname);
    api
      .post('handover/malllogistics/create', {
        postID: selected,
        message: `${selected?.length} adet gönderiyi teslim alabilmeniz için {code} kodunu yetkiliye veriniz`,
        name: name,
        lastname: lastname,
        phone: phone,
        note: note,
        sentHandoverMail: true,
      })
      .then((response) => {
        setConfirmCode(response.data.result.code);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <>
      <Helmet>
        <title> Muhaberat Çıkış | Mall Logistics Panel </title>
      </Helmet>

      <Stack style={{ paddingInline: '25px' }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Muhaberat Çıkış
          </Typography>
          {['store:manageroperations', 'store:operations'].includes(
            tokenService.getUser()?.accountCategory +
            ':' +
            tokenService
              .getUser()
              ?.frontAuth?.map((x) => x)
              .join('')
          ) && (
              <Button
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
                onClick={() => setOpenCreateModal(true)}
              >
                İş Emri Oluştur
              </Button>
            )}

          {showAddPersonelModal ? (
            <Stack
              direction={isMobile ? 'column' : 'row'}
              alignItems="end"
              justifyContent="flex-start"
              paddingY={2}
              paddingX={1}
              spacing={2}
            >
              <Button
                sx={{
                  marginInline: '8px',
                  fontSize: 14,
                  fontFamily: 'Inter',
                  fontWeight: 400,
                  letterSpacing: 0.4,
                  height: 40,
                  borderRadius: '5px',
                  padding: '8px 16px 8px 16px',
                  gap: '6px',
                }}
                variant="contained"
                color="primary"
                component={Link}
                to="/dashboard/muhaberat-output-users"
              >
                KULE ÇALIŞANI LİSTESİ
              </Button>
              <AddEntityButton
                label={'Kule Çalışanı Ekle'}
                onClick={() => handleOpenAddPersonelModal()}
              />
              <ExportToExcelButton
                setShowPopUpError={setShowPopUpError}
                showPopUpError={showPopUpError}
                onClick={() => exportToExcel(orderList)}
                sx={{ marginLeft: '7px' }}
              />
            </Stack>
          ) : null}

        </Stack>

        <Stack
          direction="row"
          justifyContent={isMobile ? 'space-between' : 'end'}
          mb={5}
        >
          {isMobile ? (
            <FilterViewButton variant="contained" onClick={handleModal} />
          ) : null}
        </Stack>
        <Card>

          <CustomFilter
            modalType={'drawer'}
            filterModal={filterModal}
            handleCloseModal={handleCloseModal}
          >
            {/* Filtreleme Satırı 1 */}
            <Stack
              direction="column"
              alignItems="center"
              justifyContent="flex-start"
              spacing={2}
              padding={2}
              mb={2}
            >
              <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                <TextField
                  label="Barkod"
                  variant="outlined"
                  value={barcode}
                  onChange={(e) => setBarcode(e.target.value)}
                  sx={{ flex: 1 }}
                />
              </FormControl>

              <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                <TextField
                  label="Firma"
                  variant="outlined"
                  value={firm}
                  onChange={(e) => setFirm(e.target.value)}
                  sx={{ flex: 1 }}
                />
              </FormControl>

              <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                <TextField
                  label="Kargo Firması"
                  variant="outlined"
                  value={cargo}
                  onChange={(e) => setCargo(e.target.value)}
                  sx={{ flex: 1 }}
                />
              </FormControl>
              <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                <TextField
                  label="Kule"
                  variant="outlined"
                  value={tower}
                  onChange={(e) => setTower(e.target.value)}
                  sx={{ flex: 1 }}
                />
              </FormControl>
              <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                <TextField
                  label="Daire"
                  variant="outlined"
                  value={office}
                  onChange={(e) => setOffice(e.target.value)}
                  sx={{ flex: 1 }}
                />
              </FormControl>

            </Stack>

            {/* Filtreleme Satırı 2 */}
            <Stack
              direction="column"
              alignItems="center"
              justifyContent="flex-start"
              spacing={2}
              paddingX={2}
              mb={2}

            >
              <Stack direction="row" spacing={1}>
                <FormControl sx={{ flex: 1, minWidth: '49%' }}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      label="Başlangıç Tarihi"
                      value={startDate}
                      onChange={(newValue) => setStartDate(newValue)}
                      sx={{ flex: 1 }}
                    />
                  </LocalizationProvider>
                </FormControl>
                <FormControl sx={{ flex: 1, minWidth: '49%' }}>
                  <LocalizationProvider dateAdapter={AdapterMoment}>
                    <DatePicker
                      label="Bitiş Tarihi"
                      value={endDate}
                      onChange={(newValue) => setEndDate(newValue)}
                      sx={{ flex: 1 }}
                    />
                  </LocalizationProvider>
                </FormControl>
              </Stack>

              <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                <TextField
                  label="Kişi"
                  variant="outlined"
                  value={filterName}
                  onChange={(e) => setFilterName(e.target.value)}
                  sx={{ flex: 1 }}
                />
              </FormControl>

              <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                <Select
                  multiple
                  displayEmpty
                  value={selectedCargoStatus}
                  onChange={handleChange}
                  input={<OutlinedInput />}
                  renderValue={(selected) => {
                    if (selected.length === 0) {
                      return <em>Kargo Durumu</em>;
                    }

                    return selected.map((x) => x?.label).join(', ');
                  }}
                  MenuProps={MenuProps}
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  {StatusList.map((x) => (
                    <MenuItem key={x.id} value={x}>
                      {x.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                <Select
                  displayEmpty
                  value={selectedDirection.value}
                  onChange={changeDirection}
                  input={<OutlinedInput />}
                  renderValue={(selected) => {
                    if (selected === 'all') {
                      return <em>Tümü</em>;
                    }
                    return directions.find((dir) => dir.value === selected)
                      ?.label;
                  }}
                  MenuProps={MenuProps}
                  inputProps={{ 'aria-label': 'Without label' }}
                >
                  {directions.map((x, index) => (
                    <MenuItem key={index} value={x.value}>
                      {x.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Stack>

            {/* Divider */}
            <Divider sx={{ width: '100%', mb: 2 }} />

            {/* Butonlar */}
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              mb={2}
              paddingX={2}
            >


              <Stack direction="row" spacing={2}>
                <ClearButton onClick={clearFilters} />
                <FilterButton onClick={getFilteredOrderList} />
              </Stack>
            </Stack>


          </CustomFilter>


          <DynamicTable
            data={orderList}
            headers={TABLE_HEAD}
            initialOrder={'createdDateTime'}
            initalOrderDirection={'desc'}
            isOrdered={false}
            handleCheckboxChange={handleCheckboxChange}
            selected={selected}
            isMobile={isMobile}
            handleModal={handleModal}
            handleCloseModal={handleCloseModal}
          >
            <Stack direction="row" spacing={2}>
              <Button variant="contained" color="primary" onClick={selectAll}>
                Hepsini Seç
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  setSelected([]);
                  setSelectedItems([]);
                }}
              >
                Hepsini Kaldır
              </Button>
            </Stack>
          </DynamicTable>

        </Card>
      </Stack>

      {addPersonelModalOpen && (
        <AddEditPersonelModal
          open={addPersonelModalOpen}
          onClose={() => setAddPersonelModalOpen(false)}
          isEdit={isModalEdit}
        />
      )}

      {openCreateModal && (
        <CreateWorkOrderModal
          openCreateModal={openCreateModal}
          onCreateModal={() => setOpenCreateModal(false)}
          customer={customer}
          locations={locations}
        />
      )}

      {handoverModal && (
        <DeliveryModal
          open={handoverModal}
          count={selectedItems?.length}
          onClose={handleCloseDeliveryModal}
          onBack={handleBack}
          onDeliver={handleDeliver}
          onComplete={completeDeliver}
          selectedItems={selectedItems}
          onAddPersonel={() => {
            setSelected([]);
            setSelectedItems([]);
            setHandoverModal(false);
            handleOpenAddPersonelModal();
          }}
          isLoading={isBackdropOpen}
        />
      )}

      {selected?.length > 0 && (
        <Box className="bottom-center-container">
          <Button
            variant="contained"
            color="primary"
            className="bottom-center-button"
            onClick={() => setHandoverModal(true)}
          >
            ({selected?.length}) Teslim Et
          </Button>
        </Box>
      )}

      <Modal
        open={isSuccessModalOpen}
        onClose={() => {
          setSuccessModalOpen(false);
          window.location.reload();
        }}
      >
        <Box
          sx={{
            position: 'absolute',
            width: 700,
            height: 350,
            bgcolor: 'background.paper',
            border: '2px solid #000',
            boxShadow: 24,
            p: 4,
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            textAlign: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <CheckCircleIcon sx={{ color: 'green', fontSize: 64 }} />
          <Typography variant="h6">
            {selected?.length} adet gönderi başarıyla teslim edildi.
          </Typography>
          <Typography>
            Aşağıdaki seçenekler ile seçiminizi, Excel veya PDF formatında
            indirebilirsiniz.
          </Typography>
          <Tooltip title="Dosya indirme hakkında bilgi için tıklayın">
            <IconButton
              size="small"
              sx={{
                color: 'gray',
                marginLeft: '8px',
                animation: 'pulse 2s infinite',
                '@keyframes pulse': {
                  '0%': {
                    transform: 'scale(1)',
                    color: 'gray',
                  },
                  '50%': {
                    transform: 'scale(1.1)',
                    color: '#088AB2', // Açık mavi
                  },
                  '100%': {
                    transform: 'scale(1)',
                    color: 'gray',
                  },
                },
              }}
              onClick={handleOpen}
            >
              <HelpOutlineIcon />
            </IconButton>
          </Tooltip>

          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-title"
            aria-describedby="modal-description"
          >
            <Box
              sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: 800,
                bgcolor: 'background.paper',
                borderRadius: '10px',
                boxShadow: 24,
                p: 4,
                justifyContent: 'flex-end',
                display: 'flex',
                flexDirection: 'column',
              }}
            >
              <h3 id="modal-title">Dosya İndirme Uyarısı</h3>
              <img
                src={require('../../src/assets/images/popUpError.png')}
                alt="Pop-up Error"
                style={{
                  display: 'block',
                  margin: '20px auto',
                  maxWidth: '100%',
                  height: 'auto',
                }}
              />
              <p id="modal-description">
                Eğer tarayıcınızda görseldeki işareti görüyorsanız, gösterilen
                ikona tıklayarak tarayıcınızdan, bu sayfa için{' '}
                <b>"Pop-up'lar ve yönlendirmeler"</b> seçeneğine izin vermeniz
                gerekmektedir. Aksi durumda dosya indirme işlemlerinize engel
                olacaktır.
              </p>
              <Button onClick={handleClose} variant="contained" color="primary">
                Tamam
              </Button>
            </Box>
          </Modal>
          <Button
            sx={{
              width: 500,
            }}
            variant="contained"
            color="info"
            onClick={() => createPDF(selectedItems)}
          >
            PDF'e Aktar
          </Button>
          <Button
            sx={{
              width: 500,
            }}
            variant="contained"
            color="info"
            onClick={() => exportToExcel(selectedItems)}
            disabled={!isOnline} // Disable if offline
          >
            {isOnline
              ? 'Excele Aktar'
              : "Excel'e aktar (İnternet bağlantısı gerekmektedir.)"}
          </Button>
          <Button
            sx={{
              width: 500,
            }}
            onClick={() => {
              setSuccessModalOpen(false);
              window.location.reload();
            }}
          >
            Tamam
          </Button>
        </Box>
      </Modal>
    </>
  );
}
