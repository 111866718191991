import React from 'react';
import { Button } from '@mui/material';

const CompleteButton = ({ onClick, label, btnType, disabled, type, sx }) => {
    return (
        <>
            <Button
                type={type}
                disabled={disabled}
                sx={{
                    backgroundColor: btnType === 'save' ? '#079455' : '#1570EF',
                    fontSize: 14,
                    fontFamily: 'Inter',
                    fontWeight: 400,
                    letterSpacing: 0.4,
                    height: 46,
                    borderRadius: '5px',
                    padding: '8px 16px 8px 16px',
                    gap: '6px',
                    '&:hover': btnType === 'save' && {
                        backgroundColor: '#067041',
                    },
                    ...sx 
                }}
                variant="contained"
                onClick={onClick}
            >
                {label.toLocaleUpperCase('tr-TR')}
            </Button >
        </>
    );
};

export default CompleteButton;
