import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { useSelector } from 'react-redux';
import { saveAs } from 'file-saver';
// @mui
import {
  Card,
  CircularProgress,
  Stack,
  Typography,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  useMediaQuery
} from '@mui/material';
// components
import Iconify from '../components/iconify';
// sections
import AddEditPointModal from 'src/components/modals/AddEditPointModal';
import { UserListHead } from '../sections/@dashboard/user';
import api from 'src/services/BaseService';
import './muhaberatOutput.css';
// shared
import AddEntityButton from 'src/components/shared/AddEntityButton';
import ClearButton from 'src/components/shared/ClearButton';
import FilterButton from 'src/components/shared/FilterButton';
import EditButton from 'src/components/shared/EditButton';
import HorizontalDivider from 'src/components/shared/HorizontalDivider';
import DynamicTable from 'src/components/shared/DynamicTable';
import CustomFilter from 'src/components/filter/CustomFilter';
import FilterViewButton from 'src/components/shared/FilterViewButton';
// ----------------------------------------------------------------------



const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  if (query) {
    return filter(
      array,
      (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
    );
  }
  return stabilizedThis?.map((el) => el[0]);
}

export default function PointsPage() {
  const customer = useSelector((state) => state.customerReducer);
  const [open, setOpen] = useState(null);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [selected, setSelected] = useState([]);
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [allDatas, setAllDatas] = useState([]);
  const [orderList, setOrderList] = useState([]);
  const [firm, setFirm] = useState('');
  const [addEditPointOpen, setAddEditPointOpen] = useState(false);
  const [isModalEdit, setIsModalEdit] = useState(false);
  const [editData, setEditData] = useState();
  const [originalData, setOriginalData] = useState();
  const [title, setTitle] = useState('');
  const [branchCode, setBranchCode] = useState('');
  const [isAmazon, setIsAmazon] = useState('');
  const [isTrendyol, setIsTrendyol] = useState('');
  const [isHepsiburada, setIsHepsiburada] = useState('');
  const [city, setCity] = useState('');
  const [region, setRegion] = useState('');
  const [isActive, setIsActive] = useState('');
  const [malls, setMalls] = useState([]);
  const [userList, setUserList] = useState([]);
  const [isMuhaberat, setIsMuhaberat] = useState('');
  const [isMalKabul, setIsMalKabul] = useState('');
  const [qr, setQr] = useState('');
  const [selectedPoint, setSelectedPoint] = useState(null);
  const [loading, setLoading] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const isMobile = useMediaQuery('(max-width:768px)');
  const handleModal = () => {
    setFilterModal(true);
  };

  const handleCloseModal = () => {
    setFilterModal(false);
  };

  const TABLE_HEAD = [
    { id: 'title', label: 'Başlık', alignRight: false },
    { id: 'branchCode', label: 'Şube Kodu', alignRight: false },
    {
      id: 'pointTags', label: 'Nokta Tagleri', alignRight: false, format: (value, row) => {
        return (
          <>
            {row.isMalKabul === '1' && (
              <span
                style={{
                  backgroundColor: '#F3F4F6',
                  padding: '4px 12px 4px 10px',
                  borderRadius: '5px',
                  textAlign: 'center',
                  border: '1px solid #D0D5DD',
                  color: '#6B7280',
                  fontSize: 14,
                  fontWeight: 500,
                  fontFamily: 'Inter',
                  marginRight: '6px',
                }}
              >
                Mal Kabul
              </span>
            )}
            {row.isMuhaberat === '1' && (
              <span
                style={{
                  backgroundColor: '#172955',
                  padding: '4px 12px 4px 10px',
                  borderRadius: '5px',
                  textAlign: 'center',
                  border: '1px solid #172955',
                  color: '#fff',
                  fontSize: 14,
                  fontWeight: 500,
                  fontFamily: 'Inter',
                  marginRight: '6px',
                }}
              >
                Muhaberat
              </span>
            )}
          </>
        );
      }

    },
    { id: 'cityAndRegion', label: 'İl/İlçe', alignRight: false, format: (value, row) => row.city + '/' + row.district },
    {
      id: 'state', label: 'Durum', alignRight: false, format: (value, row) => {
        return row.status === '1' ? (
          <span
            style={{
              backgroundColor: '#ECFDF3',
              padding: '4px 12px 4px 10px',
              borderRadius: '5px',
              textAlign: 'center',
              border: '1px solid #ABEFC6',
              color: '#067647',
              fontSize: 14,
              fontWeight: 500,
              fontFamily: 'Inter',
              marginRight: '6px',
            }}
          >
            ● Aktif
          </span>
        ) : (
          <span
            style={{
              backgroundColor: '#F9FAFB',
              padding: '4px 12px 4px 10px',
              borderRadius: '5px',
              textAlign: 'center',
              border: '1px solid #EAECF0',
              color: '#344054',
              fontSize: 14,
              fontWeight: 500,
              fontFamily: 'Inter',
              marginRight: '6px',
            }}
          >
            ● Pasif
          </span>
        )
      }
    },
    {
      id: 'qrIcon', label: '', alignRight: false, format: (value, row) =>
        <Iconify
          style={{
            color: '#98A2B3',
            width: 22,
            height: 22,
            cursor: 'pointer',
          }}
          icon="bi:qr-code"
          onClick={() => handleQRCodeClick(row)}
        />
    },
    { id: 'editButton', label: '', alignRight: false, format: (value, row) => <EditButton onClick={() => handleEdit(row)} /> },
  ];


  const handleOpenAddEditPointModal = () => {
    setAddEditPointOpen(true);
    setIsModalEdit(false);
  };

  const handleCloseAddEditPointModal = () => {
    setAddEditPointOpen(false);
    setEditData();
    getPoints();
  };

  const handleEdit = (rowData) => {
    setEditData(rowData);
    setAddEditPointOpen(true);
    setIsModalEdit(true);
  };

  const handleDownload = () => {
    saveAs(qr, `${selectedPoint.title}_qr.jpg`);
  };

  const handleQRCodeClick = (point) => {
    setLoading(true);
    getQR(point.id);
    setSelectedPoint(point);
  };

  const handleCloseDialog = () => {
    setOpen(false);
    setQr('');
  };

  const getFilteredPointList = () => {
    let filteredPointList;
    if (
      title ||
      branchCode ||
      isMalKabul ||
      isMuhaberat ||
      city ||
      region ||
      isActive
    ) {
      filteredPointList = originalData.filter((item) => {
        const titleMatch = title
          ? item.title.toLowerCase().includes(title.toLowerCase())
          : true;
        const branchCodeMatch = branchCode
          ? item.branchCode.toLowerCase().includes(branchCode.toLowerCase())
          : true;
        const isMalKabulMatch = isMalKabul
          ? item.isMalKabul === isMalKabul
          : true;
        const isMuhaberatMatch = isMuhaberat
          ? item.isMuhaberat === isMuhaberat
          : true;
        const regionMatch = region
          ? item.region
            .toLowerCase()
            .normalize('NFKD')
            .replace(/[\u0300-\u036F]/g, '')
            .includes(
              region
                .toLowerCase()
                .normalize('NFKD')
                .replace(/[\u0300-\u036F]/g, '')
            )
          : true;
        const cityMatch = city
          ? item.city
            .toLowerCase()
            .normalize('NFKD')
            .replace(/[\u0300-\u036F]/g, '')
            .includes(
              city
                .toLowerCase()
                .normalize('NFKD')
                .replace(/[\u0300-\u036F]/g, '')
            )
          : true;
        const isActiveMatch = isActive ? item.status === isActive : true;
        return (
          titleMatch &&
          branchCodeMatch &&
          isMalKabulMatch &&
          isMuhaberatMatch &&
          cityMatch &&
          regionMatch &&
          isActiveMatch
        );
      });
    } else {
      filteredPointList = originalData;
    }

    setOrderList(filteredPointList);
  };

  const clearFilters = () => {
    setOrderList(originalData);
    setTitle('');
    setBranchCode('');
    setIsAmazon('');
    setIsTrendyol('');
    setIsHepsiburada('');
    setCity('');
    setRegion('');
    setIsActive('');
    setIsMalKabul('');
    setIsMuhaberat('');
  };

  function getQR(pointID) {
    api
      .post('easypoint-centers/get-static-qr', {
        pointID: pointID,
      })
      .then((response) => {
        if (response.data.status) {
          setOpen(true);
          setQr(response.data.result);
        } else {
          toast.error('QR değeri çekilirken bir hata meydana geldi.');
        }
      })
      .catch(() => toast.error('QR değeri çekilirken bir hata meydana geldi.'))
      .finally(() => setLoading(false));
  }

  function getUsers() {
    api
      .post('users/get-users', {
        status: true,
      })
      .then((response) => {
        if (response.data.status) {
          setUserList(response.data.result);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => toast.error(error.message));
  }

  function getPoints() {
    let params = {
      isMuhaberat: true,
      isMalKabul: true,
    };
    api
      .post('flow/get-points', params)
      .then((response) => {
        if (response.data.status) {
          setOrderList(response.data.result);
          setAllDatas(response.data.result);
          setOriginalData(response.data.result);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => toast.error(error.message));
  }

  function getMalls() {
    api
      .post('/malls/get-malls')
      .then((response) => {
        if (response.data.status) {
          setMalls(response.data.result);
        } else {
          console.log(response.data);
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  }

  useEffect(() => {
    getUsers();
    getMalls();
    getPoints();
  }, [customer]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const filterByName = (text) => {
    const name = text.toLowerCase();
    const filteredOrders = allDatas.filter((item) =>
      item.title.toLowerCase().includes(name)
    );
    setFirm(name);
    setOrderList(filteredOrders);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - orderList?.length) : 0;

  const filteredOrders = applySortFilter(
    orderList,
    getComparator(order, orderBy),
    filterName
  );

  const isNotFound = !filteredOrders?.length && !!filterName;

  return (
    <>
      <Helmet>
        <title> Noktalar | Easy Point Panel </title>
      </Helmet>
      <Stack style={{ paddingInline: '25px' }}>

        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Noktalar
          </Typography>

          <AddEntityButton
            onClick={() => handleOpenAddEditPointModal()}
            label={'Nokta Ekle'}
            icon={'ri:map-pin-line'}
          />
        </Stack>

        <Stack
          direction="row"
          justifyContent={isMobile ? 'space-between' : 'end'}
          mb={5}
        >
          {isMobile ? (
            <FilterViewButton variant="contained" onClick={handleModal} />
          ) : null}
        </Stack>
        <Card>

          <CustomFilter
            modalType={'drawer'}
            filterModal={filterModal}
            handleCloseModal={handleCloseModal}
          > <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            paddingY={2}
            paddingX={1}
          >
              <Stack
                mr={1}
                direction="row"
                alignItems="flex-start"
                justifyContent="flex-start"
                sx={{ marginBottom: 2, flexWrap: 'wrap', gap: 2 }}
              >
                <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                  <TextField
                    label="Başlık"
                    variant="outlined"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                    sx={{ flexBasis: '20%', flexGrow: 1 }}
                  />
                </FormControl>
                <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                  <TextField
                    label="Şube Kodu"
                    variant="outlined"
                    value={branchCode}
                    onChange={(e) => setBranchCode(e.target.value)}
                    sx={{ flexBasis: '20%', flexGrow: 1 }}
                  />
                </FormControl>
                <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                  <TextField
                    label="İl"
                    variant="outlined"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    sx={{ flexBasis: '20%', flexGrow: 1 }}
                  />
                </FormControl>
                <FormControl sx={{ flex: 1, minWidth: '100%' }}>
                  <TextField
                    label="İlçe"
                    variant="outlined"
                    value={region}
                    onChange={(e) => setRegion(e.target.value)}
                    sx={{ flexBasis: '20%', flexGrow: 1 }}
                  />
                </FormControl>

                <FormControl
                  variant="outlined"
                  sx={{ flexBasis: '20%', flexGrow: 1, minWidth: 210 }}
                >
                  <InputLabel id="mal-kabul-label">
                    Mal Kabul Noktası Mı?
                  </InputLabel>
                  <Select
                    labelId="mal-kabul-label"
                    id="mal-kabul-select"
                    value={isMalKabul}
                    onChange={(e) => setIsMalKabul(e.target.value)}
                    label="Mal Kabul Noktası Mı?"
                  >
                    <MenuItem value="1">Evet</MenuItem>
                    <MenuItem value="0">Hayır</MenuItem>
                  </Select>
                </FormControl>
                <FormControl
                  variant="outlined"
                  sx={{ flexBasis: '20%', flexGrow: 1, minWidth: 210 }}
                >
                  <InputLabel id="muhaberat-label">
                    Muhaberat Noktası Mı?
                  </InputLabel>
                  <Select
                    labelId="muhaberat-label"
                    id="muhaberat-select"
                    value={isMuhaberat}
                    onChange={(e) => setIsMuhaberat(e.target.value)}
                    label="Muhaberat Noktası Mı?"
                  >
                    <MenuItem value="1">Evet</MenuItem>
                    <MenuItem value="0">Hayır</MenuItem>
                  </Select>
                </FormControl>
                <FormControl
                  variant="outlined"
                  sx={{ flexBasis: '20%', flexGrow: 1, minWidth: 150 }}
                >
                  <InputLabel id="durum-label">Durum</InputLabel>
                  <Select
                    labelId="durum-label"
                    id="durum-select"
                    value={isActive}
                    onChange={(e) => setIsActive(e.target.value)}
                    label="Durum"
                  >
                    <MenuItem value="1">Aktif</MenuItem>
                    <MenuItem value="0">Pasif</MenuItem>
                  </Select>
                </FormControl>
              </Stack>
            </Stack>
            <HorizontalDivider />
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              paddingY={2}
              paddingX={1}
              spacing={2}
            >
              <ClearButton onClick={() => clearFilters()} />
              <FilterButton onClick={() => getFilteredPointList()} />
            </Stack>
          </CustomFilter>



          <DynamicTable
            data={orderList}
            headers={TABLE_HEAD}
            initialOrder={'createdDateTime'}
            initalOrderDirection={'desc'}
            isOrdered={true}
            isMobile={isMobile}
            handleModal={handleModal}
            handleCloseModal={handleCloseModal}

          />
        </Card>

      </Stack>
      {addEditPointOpen && (
        <AddEditPointModal
          open={addEditPointOpen}
          onClose={handleCloseAddEditPointModal}
          isEdit={isModalEdit}
          editData={editData}
          setEditData={setEditData}
          malls={malls}
          userList={userList}
        />
      )}
      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle>{selectedPoint?.title}</DialogTitle>
        <DialogContent
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            flexDirection: 'column',
            minHeight: '200px',
          }}
        >
          {loading ? (
            <CircularProgress size={24} color="inherit" />
          ) : (
            <img src={qr} alt="QR Code" style={{ width: '100%' }} />
          )}
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            onClick={handleDownload}
            style={{ maxWidth: 500 }}
          >
            İndir
          </Button>
          <Button onClick={handleCloseDialog}>Kapat</Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
