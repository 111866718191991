import { Helmet } from 'react-helmet-async';
import { filter } from 'lodash';
import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { useSelector } from 'react-redux';
// @mui
import {
  Card,
  Table,
  Stack,
  Paper,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TextField,
  TableContainer,
  TablePagination,
} from '@mui/material';
// components
import Iconify from '../components/iconify';
import Scrollbar from '../components/scrollbar';
// sections
import { UserListHead } from '../sections/@dashboard/user';
import api from 'src/services/BaseService';
import { tokenService } from 'src/services';
import CreateWorkOrderModal from 'src/components/modals/CreateWorkOrderModal';
import AddEditPersonelModal from 'src/components/modals/AddEditPersonelModal';
import './muhaberatOutput.css';
import AddEntityButton from 'src/components/shared/AddEntityButton';
import ClearButton from 'src/components/shared/ClearButton';
import FilterButton from 'src/components/shared/FilterButton';
import EditButton from 'src/components/shared/EditButton';
import DoubleScrollbar from 'react-double-scrollbar';
// ----------------------------------------------------------------------

const USERS_TABLE_HEAD = [
  { id: 'no', label: 'No', alignRight: false },
  { id: 'name', label: 'İsim', alignRight: false },
  { id: 'lastname', label: 'Soyisim', alignRight: false },
  { id: 'email', label: 'E-Mail', alignRight: false },
  { id: 'phone', label: 'Telefon', alignRight: false },
  { id: 'tower', label: 'Kule', alignRight: false },
  { id: '', label: '' },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function applySortFilter(array, comparator, query) {
  const stabilizedThis = array?.map((el, index) => [el, index]);
  if (stabilizedThis) {
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    if (query) {
      return filter(
        array,
        (_user) => _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    }
  }
  return stabilizedThis?.map((el) => el[0]);
}

export default function MuhaberatOutputUsers() {
  const customer = useSelector((state) => state.customerReducer);
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('name');
  const [filterName, setFilterName] = useState('');
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [locations, setLocations] = useState([]);
  const [openCreateModal, setOpenCreateModal] = useState(false);
  const [lastname, setLastname] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [tower, setTower] = useState('');
  const [tableHead, setTableHead] = useState([]);

  const [addPersonelModalOpen, setAddPersonelModalOpen] = useState(false);
  const [showAddPersonelModal, setShowAddPersonelModal] = useState(false);
  const [personelList, setPersonelList] = useState([]);
  const [originalData, setOriginalData] = useState([]);

  const [isModalEdit, setIsModalEdit] = useState(false);
  const [editData, setEditData] = useState({});

  const handleEdit = (rowData) => {
    setEditData(rowData);
    setAddPersonelModalOpen(true);
    setIsModalEdit(true);
  };
  const handleDelete = (rowData) => {
    const userID = rowData.userID;
    deleteUser(userID);
  };

  function deleteUser(userID) {
    api
      .post('flow/concierge-remove-user', {
        userID: userID,
      })
      .then((response) => {
        if (response.data) {
          getUsers();
        } else {
          // console.log(response.data.message);
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  function getUsers() {
    setTableHead(USERS_TABLE_HEAD);
    api
      .post('flow/concierge-get-users', {
        metaTags: ['Zorlu Center'],
      })
      .then((response) => {
        if (response.data) {
          setPersonelList(response.data.result);
          setOriginalData(response.data.result);
        } else {
          // console.log(response.data.message);
        }
      })
      .catch((error) => {
        // console.log(error);
      });
  }

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    const mallID = localStorage.getItem('mallID');
    if (mallID === '61973122632916808660') {
      setShowAddPersonelModal(true);
    } else {
      setShowAddPersonelModal(false);
    }
  }, [customer]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const getFilteredPersonelList = () => {
    let filteredPersonelList;

    if (name || lastname || email || tower) {
      filteredPersonelList = originalData.filter((item) => {
        const nameMatch = name
          ? item.name.toLowerCase().includes(name.toLowerCase())
          : true;
        const lastnameMatch = lastname
          ? item.lastname.toLowerCase().includes(lastname.toLowerCase())
          : true;
        const emailMatch = email
          ? item.email.toLowerCase().includes(email.toLowerCase())
          : true;
        const towerMatch = tower
          ? item.metaTags.some(
            (tagItem) =>
              tagItem.tag.toLowerCase().includes(tower) &&
              tagItem.tag !== 'Zorlu Center'
          )
          : true;
        return nameMatch && lastnameMatch && emailMatch && towerMatch;
      });
    } else {
      filteredPersonelList = originalData;
    }

    setPersonelList(filteredPersonelList);
  };

  const clearFilters = () => {
    setPersonelList(originalData);
    setName('');
    setLastname('');
    setEmail('');
    setTower('');
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setPage(0);
    setRowsPerPage(parseInt(event.target.value, 10));
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - personelList.length) : 0;

  const filteredPersonels = applySortFilter(
    personelList,
    getComparator(order, orderBy),
    filterName
  );

  let isNotFound = false;

  if (filteredPersonels) {
    isNotFound = !filteredPersonels.length && !!filterName;
  }

  const handleCloseAddPersonelModal = () => {
    setAddPersonelModalOpen(false);
    getUsers();
  };

  const handleOpenAddPersonelModal = () => {
    setAddPersonelModalOpen(true);
    setIsModalEdit(false);
  };

  return (
    <>
      <Helmet>
        <title> Muhaberat Çıkış | Mall Logistics Panel </title>
      </Helmet>

      <Stack style={{ paddingInline: '25px' }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Muhaberat Çıkış
          </Typography>
          {['store:manageroperations', 'store:operations'].includes(
            tokenService.getUser()?.accountCategory +
            ':' +
            tokenService
              .getUser()
              ?.frontAuth?.map((x) => x)
              .join('')
          ) && (
              <Button
                variant="contained"
                startIcon={<Iconify icon="eva:plus-fill" />}
                onClick={() => setOpenCreateModal(true)}
              >
                İş Emri Oluştur
              </Button>
            )}

          {showAddPersonelModal ? (
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="flex-end"
              paddingY={2}
              paddingX={1}
            >
              <Button
                sx={{
                  marginInline: '8px', fontSize: 14,
                  fontFamily: 'Inter',
                  fontWeight: 400,
                  letterSpacing: 0.4,
                  height: 40,
                  borderRadius: '5px',
                  padding: '8px 16px 8px 16px',
                  gap: '6px',
                }}
                variant="contained"
                color="primary"
                component={Link}
                to="/dashboard/muhaberat-output"
              >
                MUHABERAT LİSTESİ
              </Button>
              <AddEntityButton label={"Kule Çalışanı Ekle"} onClick={() => handleOpenAddPersonelModal()} />
            </Stack>
          ) : null}
        </Stack>

        <Card>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            paddingY={2}
            paddingX={1}
          >
            <TextField
              label="İsim"
              variant="outlined"
              value={name}
              onChange={(e) => setName(e.target.value)}
              sx={{ marginRight: '8px' }}
            />
            <TextField
              label="Soyisim"
              variant="outlined"
              value={lastname}
              onChange={(e) => setLastname(e.target.value)}
              sx={{ marginRight: '8px' }}
            />
            <TextField
              label="E-Mail"
              variant="outlined"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              sx={{ marginRight: '8px' }}
            />
            <TextField
              label="Kule"
              variant="outlined"
              value={tower}
              onChange={(e) => setTower(e.target.value)}
              sx={{ marginRight: '8px' }}
            />
            <div
              style={{
                height: '35px',
                width: '1px',
                backgroundColor: 'rgba(107, 114, 128, 0.7)',
                margin: '0 15px',
              }}
            ></div>
            <ClearButton onClick={() => clearFilters()} />
            <FilterButton onClick={() => getFilteredPersonelList()} />
          </Stack>

          <div
            style={{
              marginBottom: 20,
              flexDirection: 'row',
              justifyContent: 'space-between',
              width: '100%',
              display: 'flex',
            }}
          >

          </div>
          {filteredPersonels ? (
            <>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                component="div"
                count={personelList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
              <DoubleScrollbar>
                <TableContainer sx={{ minWidth: 1590, overflowX: 'hidden', width: '100%' }}>
                  <Table>
                    <UserListHead
                      order={order}
                      orderBy={orderBy}
                      headLabel={tableHead}
                      rowCount={personelList?.length}
                      onRequestSort={handleRequestSort}
                    />
                    <TableBody>
                      {filteredPersonels
                        .slice(
                          page * rowsPerPage,
                          page * rowsPerPage + rowsPerPage
                        )
                        .map((row, index) => {
                          const { id, name, lastname, email, phone } = row;
                          const towers = row.metaTags;
                          let towersString = '';
                          if (towers.length > 0) {
                            towersString = row.metaTags
                              .filter((item) => item.tag !== 'Zorlu Center')
                              .map((item) => item.tag)
                              .join(', ');
                          }
                          return (
                            <TableRow hover key={index} tabIndex={-1}>
                              <TableCell align="left">{index + 1}</TableCell>
                              <TableCell align="left">{name}</TableCell>
                              <TableCell align="left">{lastname}</TableCell>
                              <TableCell align="left">{email}</TableCell>
                              <TableCell align="left">{phone}</TableCell>
                              <TableCell align="left" style={{ width: '25%' }}>
                                {towersString}
                              </TableCell>
                              <TableCell align="right">
                                <EditButton onClick={() => handleEdit(row)} />
                                <Iconify style={{ color: 'red', width: 22, height: 22, cursor: 'pointer', marginLeft: '10px' }} icon="material-symbols:delete-outline" onClick={() => handleDelete(row)} />
                              </TableCell>
                            </TableRow>
                          );
                        })}
                      {emptyRows > 0 && (
                        <TableRow style={{ height: 53 * emptyRows }}>
                          <TableCell colSpan={6} />
                        </TableRow>
                      )}
                    </TableBody>
                    {isNotFound && (
                      <TableBody>
                        <TableRow>
                          <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                            <Paper
                              sx={{
                                textAlign: 'center',
                              }}
                            >
                              <Typography variant="h6" paragraph>
                                Not found
                              </Typography>

                              <Typography variant="body2">
                                No results found for &nbsp;
                                <strong>&quot;{filterName}&quot;</strong>.
                                <br /> Try checking for typos or using complete
                                words.
                              </Typography>
                            </Paper>
                          </TableCell>
                        </TableRow>
                      </TableBody>
                    )}
                  </Table>
                </TableContainer>
              </DoubleScrollbar>
              <TablePagination
                rowsPerPageOptions={[10, 25, 50, 100]}
                component="div"
                count={personelList.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </>
          ) : (
            <></>
          )}
        </Card>
      </Stack>

      {addPersonelModalOpen && (
        <AddEditPersonelModal
          open={addPersonelModalOpen}
          onClose={handleCloseAddPersonelModal}
          isEdit={isModalEdit}
          editData={editData}
        />
      )}

      {openCreateModal && (
        <CreateWorkOrderModal
          openCreateModal={openCreateModal}
          onCreateModal={() => setOpenCreateModal(false)}
          customer={customer}
          locations={locations}
        />
      )}
    </>
  );
}
