import React, { useEffect, useState } from 'react';
import './EditableDataTable.css';
import { TableCell, TableRow, TableHead, TableBody, Table, TableContainer, Paper, Button, Checkbox, TextField, Select, MenuItem } from '@mui/material';

const EditableDataTable = ({ headers, data, showNoColumn, minimumRowsCount }) => {
  // Tablo için minimum satır sayısı


  // Boş bir veri seti oluşturma
  const createEmptyRows = () => {
    // Verilen data varsa, No sütununu ekleyerek devam ettir
    let initialNo = 1;
    const initialRows = data && data.length > 0 ? data.map((row, index) => ({
      No: row.No !== undefined ? row.No : initialNo++,
      ...row,
    })) : [];

    // Eksik satırları oluştur
    const emptyRowCount = Math.max(minimumRowsCount - initialRows.length, 0);
    const emptyRows = Array.from({ length: emptyRowCount }, (_, index) => ({
      No: initialRows.length + index + 1,
      ...headers.reduce((acc, header) => {
        if (header.name !== 'No') {
          acc[header.name] = '';
        }
        return acc;
      }, {})
    }));
    return initialRows.concat(emptyRows);
  };

  // Tablo verilerini state olarak tutma
  const [rows, setRows] = useState(createEmptyRows());

  useEffect(() => {
    setRows(createEmptyRows());
  }, [data, headers]);

  // Yeni satır ekleme işlemi
  const addRow = () => {
    const newNo = rows.length > 0 ? rows[rows.length - 1].No + 1 : 1;

    const newRowWithNo = {
      No: newNo,
      ...headers.reduce((acc, header) => {
        if (header.name !== 'No') {
          acc[header.name] = '';
        }
        return acc;
      }, {})
    };

    const updatedRows = [...rows, newRowWithNo];
    setRows(updatedRows);
  };

  // Satır güncelleme işlemi
  const updateRow = (index, field, value) => {
    const updatedRows = [...rows];
    updatedRows[index][field] = value;
    setRows(updatedRows);
  };

  // Satır silme işlemi
  const deleteRow = (index) => {
    const updatedRows = [...rows];
    updatedRows.splice(index, 1);

    // No değerlerini güncelle
    updatedRows.forEach((row, idx) => {
      row.No = idx + 1;
    });

    setRows(updatedRows);
  };

  // Input alanları için render fonksiyonu
  const renderInputField = (header, value, onChange) => {
    const fieldValue = value !== undefined ? value : '';

    if (header.type === 'select') {
      return (
        <Select
          value={fieldValue}
          onChange={(e) => onChange(e.target.value)}
          fullWidth
          displayEmpty
          inputProps={{
            'aria-label': header.name,
          }}
          variant="outlined"
          size='small'
          sx={{ marginTop: "3px" }}
        >
          {header.options.map((option, index) => (
            <MenuItem key={index} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      );
    } else if (header.type === 'date') {
      return (
        <TextField
          type="date"
          value={fieldValue}
          onChange={(e) => onChange(e.target.value)}
          fullWidth
          margin="dense"
          size="small"
          variant="outlined"
        />
      );
    } else if (header.type === 'number') {
      return (
        <TextField
          type="number"
          value={fieldValue}
          onChange={(e) => onChange(e.target.value)}
          fullWidth
          margin="dense"
          size="small"
          variant="outlined"
        />
      );
    } else if (header.type === 'boolean') {
      return (
        <Checkbox
          checked={fieldValue}
          onChange={(e) => onChange(e.target.checked)}
          size="small"
        />
      );
    } else {
      return (
        <TextField
          type="text"
          value={fieldValue}
          onChange={(e) => onChange(e.target.value)}
          fullWidth
          margin="dense"
          size="small"
          variant="outlined"
        />
      );
    }
  };

  return (
    <div className="editable-data-table-container">
      <Button variant="contained" onClick={addRow}>Satır Ekle</Button>

      <TableContainer component={Paper} style={{ maxHeight: "75vh", marginTop: '2%' }}>
        <Table stickyHeader>
          <TableHead>
            <TableRow>
              {showNoColumn && <TableCell>No</TableCell>}
              {headers.map((header, index) => (
                <TableCell key={index}>{header.name}</TableCell>
              ))}
              <TableCell>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map((row, index) => (
              <TableRow key={index}>
                {showNoColumn && <TableCell>{row.No}</TableCell>}
                {headers.map((header, cellIndex) => (
                  <TableCell key={cellIndex}>
                    {renderInputField(header, row[header.name], (value) => updateRow(index, header.name, value))}
                  </TableCell>
                ))}
                <TableCell>
                  <Button variant="contained" onClick={() => deleteRow(index)}>Sil</Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );

};

export default EditableDataTable;
