import React, { useState } from 'react';
import {
  IconButton,
  InputAdornment,
  Grid,
  Typography,
  Checkbox,
  FormControlLabel,
  Stack,
  Button,
  Backdrop,
  CircularProgress,
  DialogTitle,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import Iconify from '../iconify/Iconify';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import TextField from '@mui/material/TextField';
import api from 'src/services/BaseService';

const AddUserForStoreModal = ({ open, onClose, branchID, refreshPage, mallID }) => {
  const [showResultMessage, setShowResultMessage] = useState(false);
  const [resultMessage, setResultMessage] = useState('');
  const [isBackdropOpen, setIsBackdropOpen] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [sendInfoViaEmail, setSendInfoViaEmail] = useState(false);
  const [sendInfoViaSMS, setSendInfoViaSMS] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    lastname: '',
    username: '',
    email: '',
    phone: '',
    password: '',
  });

  const allFieldsAreValid = () => {
    if (
      formData.name !== '' &&
      formData.lastname !== '' &&
      formData.userName !== '' &&
      formData.email !== '' &&
      formData.phone !== '' &&
      formData.password !== ''
    ) {
      return true;
    } else return false;

  };


  const handleCloseMessage = () => {
    setShowResultMessage(false);
    setFormData({});
    onClose();
  };

  const createUser = () => {
    setIsBackdropOpen(true);
    api
      .post('/flow/user-create', {
        mallID: mallID,
        corporationID: '',
        branchID: branchID,
        email: formData.email,
        phone: formData.phone,
        name: formData.name,
        lastname: formData.lastname,
        password: formData.password,
        username: formData.username,
        type: 'corporational',
        isAdministrator: true,
        accountCategory: 'store',
        frontAuth: ['operations', 'manager'],
        authLocations: {
          branch: [branchID],
          mallID: [mallID]
        },
      })
      .then((response) => {
        // console.log(response.data, "Add User Result")
        if (response.data.status) {
          setResultMessage('Kullanıcı başarıyla eklendi!');
          setShowResultMessage(true);
          setIsBackdropOpen(false);
          refreshPage(response.data.result);
          // console.log("Başarıyla eklendi",response.data)
        } else {
          setResultMessage('Kullanıcı eklenirken bir sorunla karşılaşıldı!');
          setShowResultMessage(true);
          // toast.error(response.data.message);
        }
      })
      .catch((error) => {
        // console.log(error);
        // toast.error(error.message);
      });
  };

  const handleEmailCheckboxChange = (event) => {
    setSendInfoViaEmail(event.target.checked);
  };

  const handleSMSCheckboxChange = (event) => {
    setSendInfoViaSMS(event.target.checked);
  };

  const handleTextFieldChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleClose = () => {
    setFormData({});
    onClose();
  };

  return (
    <>
      <Dialog open={open} onClose={handleClose} maxWidth="800px">
        <Backdrop sx={{ color: '#fff', zIndex: 2 }} open={isBackdropOpen}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <DialogContent>
          <Typography sx={{ color: '#6B7280', fontWeight: 600, fontSize: 18 }}>
            Kullanıcı Ekle
          </Typography>
          <div
            style={{
              height: '1px',
              backgroundColor: '#EAECF0',
              marginBottom: '32px',
              marginTop: '15px',
              width: '726px',
            }}
          ></div>
          <Grid xs={16} container alignItems="flex-end" spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Sorumlu Kişi Adı*"
                name="name"
                value={formData.name}
                onChange={handleTextFieldChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Sorumlu Kişi Soyadı*"
                name="lastname"
                value={formData.lastname}
                onChange={handleTextFieldChange}
                margin="normal"
              />
            </Grid>
          </Grid>
          <Grid xs={16} container alignItems="flex-end" spacing={2}>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Kullanıcı Adı*"
                name="username"
                value={formData.username}
                onChange={handleTextFieldChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                type={showPassword ? 'text' : 'password'}
                label="Kullanıcı Şifresi*"
                name="password"
                value={formData.password}
                onChange={handleTextFieldChange}
                margin="normal"
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <IconButton
                        onClick={() => setShowPassword(!showPassword)}
                        edge="end"
                      >
                        <Iconify
                          icon={
                            showPassword ? 'eva:eye-fill' : 'eva:eye-off-fill'
                          }
                        />
                      </IconButton>
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
          </Grid>
          <Typography sx={{ fontSize: 12, fontWeight: 400, color: '#9CA3AF' }}>
            Kaydettiğiniz kullanıcı yönetici olarak oluşturulacaktır.
          </Typography>
          <Grid
            xs={16}
            container
            alignItems="flex-end"
            marginTop="5px"
            spacing={2}
          >
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="E-Mail*"
                name="email"
                value={formData.email}
                onChange={handleTextFieldChange}
                margin="normal"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                label="Telefon*"
                type="number"
                name="phone"
                value={formData.phone}
                onChange={(e) =>
                  setFormData({
                    ...formData,
                    phone: e.target.value,
                  })
                }
                margin="normal"
                onInput={(e) => {
                  let inputValue = e.target.value;
                  if (inputValue < 0) {
                    inputValue = '0';
                  }
                  // Eğer ilk karakter 0 ise ve giriş uzunluğu 1'den fazlaysa, ilk karakteri sil
                  if (inputValue.startsWith('0')) {
                    inputValue = inputValue.slice(1);
                  }
                  // Maksimum 10 karakter sınırlaması
                  if (inputValue.length > 10) {
                    inputValue = inputValue.slice(0, 10);
                  }
                  // Giriş değeri değiştiyse, yeni değeri güncelle
                  if (inputValue !== e.target.value) {
                    e.target.value = inputValue;
                    // State'i güncelle
                    setFormData({
                      ...formData,
                      phone: e.target.value,
                    });
                  }
                }}
              />
            </Grid>
          </Grid>
          <div
            style={{
              height: '1px',
              backgroundColor: '#EAECF0',
              marginBottom: '32px',
              marginTop: '40px',
              width: '710px',
              marginLeft: '8px',
            }}
          ></div>
          <div style={{ flexDirection: 'column', display: 'flex' }}>
            <FormControlLabel
              control={
                <Checkbox
                  checked={sendInfoViaEmail}
                  onChange={handleEmailCheckboxChange}
                />
              }
              label="Giriş bilgileri e-mail adresinize gönderilsin."
            />
            <FormControlLabel
              control={
                <Checkbox
                  checked={sendInfoViaSMS}
                  onChange={handleSMSCheckboxChange}
                />
              }
              label="Giriş bilgileri sms ile gönderilsin."
            />
          </div>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="center"
            mb={5}
          >
            <Button
              color="primary"
              onClick={onClose}
              variant="outlined"
              sx={{
                color: '#000',
                borderColor: '#ccc',
                '&:hover': { borderColor: '#999' },
                marginTop: '32px',
                width: '241px',
                height: '46px',
                marginRight: '25px',
                fontSize: 12,
                fontWeight: 500,
              }}
            >
              İŞLEMİ İPTAL ET
            </Button>
            <Button
              disabled={!allFieldsAreValid()}
              color="primary"
              onClick={createUser}
              variant="contained"
              sx={{
                marginTop: '32px',
                width: '241px',
                height: '46px',
                backgroundColor: '#1570EF',
                fontSize: 12,
                fontWeight: 500,
              }}
            >
              KULLANICIYI KAYDET
            </Button>
          </Stack>
        </DialogContent>
      </Dialog>

      <Dialog
        open={showResultMessage}
        onClose={handleCloseMessage}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{'Kullanıcı Ekleme'}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {resultMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMessage}>Tamam</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddUserForStoreModal;
