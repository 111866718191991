import React, { useState, useEffect } from 'react';
import { Backdrop, Box, CircularProgress, FormControl, Grid, Modal, Stack, Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContentText from '@mui/material/DialogContentText';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import api from 'src/services/BaseService';
import CancelButton from '../shared/CancelButton';
import CompleteButton from '../shared/CompleteButton';

const AddEditPersonelModal = ({ open, onClose, isEdit, editData }) => {
  const [name, setName] = useState('');
  const [lastname, setLastname] = useState('');
  const [phone, setPhone] = useState('');
  const [email, setEmail] = useState('');
  const [selectedMallTitle, setSelectedMallTitle] = useState('');
  const [isBackdropOpen, setIsBackdropOpen] = useState(true);
  const [selectedTowers, setSelectedTowers] = useState([]);
  const [showResultMessage, setShowResultMessage] = useState(false);
  const [resultMessage, setResultMessage] = useState('');

  const towerList = [
    'R1',
    'R2',
    'R3',
    'T0',
    'T1',
    'T2',
    'T3',
    'T4',
    'T5',
    'OF0',
    'OF1',
    'OF2',
    'OF3',
    'OF4',
    'OF5',
  ];

  useEffect(() => {
    if (isEdit === true) {
      const editDataTowersObj = editData.metaTags?.filter(
        (item) => item.tag !== 'Zorlu Center'
      );
      const editDataTowers = [];
      for (let i = 0; i < editDataTowersObj.length; i++) {
        editDataTowers.push(editDataTowersObj[i].tag);
      }
      setSelectedTowers(editDataTowers);
      setName(editData.name);
      setLastname(editData.lastname);
      setPhone(editData.phone);
      setEmail(editData.email);
    }

    const mallID = localStorage.getItem('mallID');
    api
      .post('/malls/get-malls', {
        withOffices: true,
      })
      .then((response) => {
        if (response.data) {
          const selectedMall = response.data.result.filter(
            (item) => item.id === mallID
          )[0];
          setSelectedMallTitle(selectedMall.title);
          // console.log(selectedMall.title)
          setIsBackdropOpen(false);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        // console.log(error);
        toast.error(error.message);
      });
  }, []);

  const allFieldsAreValid = () => {
    if (
      name !== '' &&
      lastname !== '' &&
      phone !== '' &&
      email !== '' &&
      selectedTowers.length > 0
    ) {
      return true;
    } else return false;
  };

  const handleTowerChange = (event) => {
    const {
      target: { value },
    } = event;
    setSelectedTowers(typeof value === 'string' ? value.split(',') : value);
  };

  const handlePhoneNumberChange = (event) => {
    const inputValue = event.target.value;
    let numericValue = inputValue.replace(/[^0-9]/g, '');
    setPhone(numericValue);
  };

  const handleSave = () => {
    setIsBackdropOpen(true);
    // console.log(isEdit,"isEdit")
    if (isEdit === false) {
      let metaTagArr = [selectedMallTitle];
      const selectedTowersObjArr = towerList.filter((item) =>
        selectedTowers.includes(item)
      );
      for (let i = 0; i < selectedTowersObjArr.length; i++) {
        metaTagArr.push(selectedTowersObjArr[i]);
      }
      api
        .post('/flow/concierge-create-user', {
          name: name,
          lastname: lastname,
          phone: phone,
          email: email,
          metaTags: metaTagArr,
        })
        .then((response) => {
          if (response.data.status === true) {
            // console.log(response.data)
            setResultMessage('Çalışan başarıyla eklendi!');
            setShowResultMessage(true);
          } else {
            setResultMessage('Çalışan eklenirken bir sorunla karşılaşıldı!');
            setShowResultMessage(true);
            // toast.error(response.data.message);
          }
        })
        .catch((error) => {
          // console.log(error);
          // toast.error(error.message);
        });

      //  console.log("Gönderilen Veri",
      //   {
      //     name: name,
      //     lastname: lastname,
      //     phone: phone,
      //     email: email,
      //     metaTags: metaTagArr
      //   }
      // )
    } else {
      const metaTagArr = selectedTowers;
      metaTagArr.push('Zorlu Center');
      api
        .post('/flow/concierge-update-user', {
          userID: editData.userID,
          name: name,
          lastname: lastname,
          phone: phone,
          email: email,
          metaTags: metaTagArr,
        })
        .then((response) => {
          if (response.data.status === true) {
            setResultMessage('Çalışan bilgileri başarıyla güncellendi!');
            setShowResultMessage(true);
          } else {
            setResultMessage(
              'Çalışan güncellenirken bir sorunla karşılaşıldı!'
            );
            setShowResultMessage(true);
          }
        })
        .catch((error) => {
          // console.log(error);
        });
    }
  };

  const handleCloseMessage = () => {
    setShowResultMessage(false);
    setIsBackdropOpen(false);
    setName('');
    setLastname('');
    setPhone('');
    setEmail('');
    setSelectedTowers([]);
    onClose();
  };

  const handleClose = () => {
    setName('');
    setLastname('');
    setPhone('');
    setEmail('');
    setSelectedTowers([]);
    setIsBackdropOpen(false);
    onClose();
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <>
          <Backdrop sx={{ color: '#fff', zIndex: 2 }} open={isBackdropOpen}>
            <CircularProgress color="inherit" />
          </Backdrop>


          <Box sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            backgroundColor: 'white',
            boxShadow: 24,
            p: 4,
            width: '90%',
            maxWidth: 930,
            maxHeight: '100%',
            borderRadius: 2,
            overflow: 'auto',
            paddingBottom: 8,
            paddingTop: 8,
          }}>
            <>
              {!isEdit && (
                <div
                  style={{
                    width: '100%',
                    alignItems: 'center',
                    justifyContent: 'center',
                    display: 'flex',
                    marginBottom: '15px',
                  }}
                >
                  <Typography
                    sx={{
                      fontFamily: 'Inter',
                      fontSize: '24px',
                      fontWeight: '600',
                      color: '#000000',
                    }}
                  >
                    Kule Çalışanı Ekleme Formu
                  </Typography>
                </div>
              )}

              {isEdit === false ? (
                <>
                  <Typography
                    sx={{
                      fontFamily: 'Inter',
                      fontSize: '18px',
                      fontWeight: '600',
                      color: '#374151',
                    }}
                    gutterBottom
                  >
                    Kule Çalışanı Ekle
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: 'Inter',
                      fontSize: '14px',
                      fontWeight: '400',
                      color: '#6B7280',
                    }}
                    gutterBottom
                  >
                    Lütfen listeye eklemek istediğiniz kullanıcı bilgilerini
                    doldurun.
                  </Typography>
                </>
              ) : (
                <>
                  <Typography
                    sx={{
                      fontFamily: 'Inter',
                      fontSize: '18px',
                      fontWeight: '600',
                      color: '#374151',
                    }}
                    gutterBottom
                  >
                    Kullanıcı Bilgilerini Düzenle
                  </Typography>
                  <Typography
                    sx={{
                      fontFamily: 'Inter',
                      fontSize: '14px',
                      fontWeight: '400',
                      color: '#6B7280',
                    }}
                    gutterBottom
                  >
                    Lütfen düzenlemek istediğiniz kullanıcı bilgilerini
                    güncelleyin.
                  </Typography>
                </>
              )}
              {isEdit && (
                <>
                  <div
                    style={{
                      width: '100%',
                      height: '1px',
                      backgroundColor: '#EAECF0',
                      marginTop: '20px',
                      marginBottom: '15px',
                    }}
                  />

                  <Stack
                    width="100%"
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                  >
                    <div
                      style={{
                        flexDirection: 'row',
                        display: 'flex',
                        justifyContent: 'flex-end',
                        alignItems: 'center',
                      }}
                    >
                      <Typography
                        sx={{
                          fontWeight: '600',
                          fontSize: '14px',
                          color: '#1570EF',
                          fontFamily: 'Inter',
                          marginRight: '5px',
                        }}
                      >
                        {name} {lastname}
                      </Typography>
                      <Typography
                        sx={{
                          fontWeight: '500',
                          fontSize: '14px',
                          fontFamily: 'Inter',
                          marginRight: '5px',
                        }}
                      >
                        adlı kullanıcının bilgilerini düzenleyin.
                      </Typography>
                    </div>
                  </Stack>
                </>
              )}
              <div
                style={{
                  width: '100%',
                  height: '1px',
                  backgroundColor: '#EAECF0',
                  marginTop: '20px',
                  marginBottom: '35px',
                }}
              />


              <Grid item xs={12} container alignItems="center" spacing={2}>
                <Grid xs={4} item>
                  <Typography
                    sx={{
                      fontFamily: 'Inter',
                      color: '#667085',
                      fontWeight: 600,
                      fontSize: '14px',
                    }}
                  >
                    Kullanıcı Bilgileri
                  </Typography>
                </Grid>
                <Grid xs={4} item>
                  <TextField
                    fullWidth
                    label="Ad"
                    variant="outlined"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                </Grid>
                <Grid xs={4} item>
                  <TextField
                    fullWidth
                    label="Soyad"
                    variant="outlined"
                    value={lastname}
                    onChange={(e) => setLastname(e.target.value)}
                  />
                </Grid>
              </Grid>
              <div
                style={{
                  width: '100%',
                  height: '1px',
                  backgroundColor: '#EAECF0',
                  marginTop: '20px',
                  marginBottom: '15px',
                }}
              />
              <Grid item xs={12} container alignItems="center" spacing={2}>
                <Grid xs={4} item>
                  <Typography
                    sx={{
                      fontFamily: 'Inter',
                      color: '#667085',
                      fontWeight: 600,
                      fontSize: '14px',
                    }}
                  >
                    Kullanıcı İletişim
                  </Typography>
                </Grid>
                <Grid xs={4} item>
                  <TextField
                    type="email"
                    fullWidth
                    label="E-Mail"
                    variant="outlined"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Grid>
                <Grid xs={4} item>
                  <TextField
                    fullWidth
                    label="Telefon"
                    placeholder="5XXXXXXXXX"
                    variant="outlined"
                    inputProps={{ maxLength: 10 }}
                    value={phone}
                    onChange={handlePhoneNumberChange}
                    onInput={(e) => {
                      let inputValue = e.target.value;
                      // Eğer ilk karakter 0 ise ve giriş uzunluğu 1'den fazlaysa, ilk karakteri sil
                      if (inputValue.startsWith('0')) {
                        inputValue = inputValue.slice(1);
                      }
                      // Maksimum 10 karakter sınırlaması
                      if (inputValue.length > 10) {
                        inputValue = inputValue.slice(0, 10);
                      }
                      // Giriş değeri değiştiyse, yeni değeri güncelle
                      if (inputValue !== e.target.value) {
                        e.target.value = inputValue;
                        // State'i güncelle
                        setPhone(inputValue);
                      }
                    }}
                  />
                </Grid>
              </Grid>
              <div
                style={{
                  width: '100%',
                  height: '1px',
                  backgroundColor: '#EAECF0',
                  marginTop: '20px',
                  marginBottom: '15px',
                }}
              />
              <Grid item xs={12} container alignItems="center" spacing={2}>
                <Grid xs={4} item>
                  <Typography
                    sx={{
                      fontFamily: 'Inter',
                      color: '#667085',
                      fontWeight: 600,
                      fontSize: '14px',
                    }}
                  >
                    Sorumlu Birim
                  </Typography>
                </Grid>
                <Grid xs={8} item>

                  <FormControl sx={{ width: '100%' }}>
                    <InputLabel id="tower-label">Sorumlu Olduğu Birim</InputLabel>
                    <Select
                      labelId="tower-label"
                      multiple
                      value={selectedTowers}
                      onChange={handleTowerChange}
                      name="tower"
                      label="Sorumlu Olduğu Birim"
                    >
                      {towerList.map((tower, index) => (
                        <MenuItem key={index} value={tower}>
                          {tower}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </Grid>
              <div
                style={{
                  width: '100%',
                  height: '1px',
                  backgroundColor: '#EAECF0',
                  marginTop: '20px',
                  marginBottom: '15px',
                }}
              />
              <div
                style={{
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyContent: 'center',
                  display: 'flex',
                  marginTop: '25px',
                }}
              >
                <CancelButton onClick={handleClose} label={'İptal'}
                  sx={{ marginRight: '24px' }} />
                {isEdit ? <CompleteButton disabled={!allFieldsAreValid()} label={'Güncelle'} onClick={handleSave} /> : <CompleteButton disabled={!allFieldsAreValid()} btnType={'save'} label={'Kullanıcı Ekle'} onClick={handleSave} />}


              </div>
            </>
          </Box>

        </>
      </Modal>

      <Dialog
        open={showResultMessage}
        onClose={handleCloseMessage} // Fonksiyon olarak geçirildi
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {isEdit === true ? (
          <DialogTitle id="alert-dialog-title">
            {'Çalışan Düzenleme'}
          </DialogTitle>
        ) : (
          <DialogTitle id="alert-dialog-title">{'Çalışan Ekleme'}</DialogTitle>
        )}
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {resultMessage}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseMessage}>Tamam</Button>{' '}
          {/* Fonksiyon olarak geçirildi */}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddEditPersonelModal;
