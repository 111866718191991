import { Helmet } from 'react-helmet-async';
import React, { useEffect, useState } from 'react';
import {
  Card,
  Stack,
  Container,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Button,
  Menu,
  Fade,
  Grid,
  Box,
  OutlinedInput,
} from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import '../pagesStyles/priceTable.css';
import ReactApexChart from 'react-apexcharts';
import { AppCurrentVisits } from 'src/sections/@dashboard/app';
import { useTheme } from '@mui/material/styles';

const malls = [
  {
    code: 'AA105',
    title: 'İzmir Optimum AVM',
  },
  {
    code: 'AA102',
    title: 'Galataport Office',
  },
  {
    code: 'AA103',
    title: 'Galataport Terminal',
  },
  {
    code: 'AA104',
    title: 'Nata Vega AVM',
  },
];

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

const durations = [1, 2, 4, 6, 12];

const months = [
  'Ocak',
  'Şubat',
  'Mart',
  'Nisan',
  'Mayıs',
  'Haziran',
  'Temmuz',
  'Ağustos',
  'Eylül',
  'Ekim',
  'Kasım',
  'Aralık',
];

export default function PriceCabineTable() {
  const theme = useTheme();

  const [seriesThree] = useState([
    {
      name: 'Yerli Kullanıcı',
      data: [44, 55, 57, 56],
    },
    {
      name: 'Diğer Milletlerden',
      data: [76, 85, 101, 98],
    },
  ]);

  const [optionsThree] = useState({
    chart: {
      type: 'bar',
      height: 350,
    },
    colors: ['#0666F6', 'gray'],
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'rounded',
        borderRadius: 4,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent'],
    },
    xaxis: {
      categories: ['Feb', 'Mar', 'Apr', 'May'],
    },
    yaxis: {
      title: {
        text: '$ (thousands)',
      },
    },
    fill: {
      opacity: 1,
    },
    tooltip: {
      y: {
        formatter: function (val) {
          return '$ ' + val + ' thousands';
        },
      },
    },
  });

  const [seriesTwo] = useState([
    {
      name: 'Desktops',
      data: [10, 41, 35, 51, 49, 62, 69, 91, 148],
    },
    {
      name: 'Laptops',
      data: [20, 30, 45, 51, 62, 65, 70, 80, 90],
    },
  ]);

  const [optionsTwo] = useState({
    chart: {
      height: 350,
      type: 'line',
      zoom: {
        enabled: false,
      },
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight',
    },
    title: {
      text: 'Product Trends by Month',
      align: 'left',
    },
    grid: {
      row: {
        colors: ['#f3f3f3', 'transparent'],
        opacity: 0.5,
      },
    },
    colors: ['#0666F6', '#C893FD'],
    xaxis: {
      categories: [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
      ],
    },
    stroke: {
      curve: 'straight',
      width: 3, // Çizgi kalınlığı
    },
    markers: {
      size: 6, // Marker boyutu
      colors: ['#0666F6', '#C893FD'], // Marker ve dış çizgi rengi (aynı renkler)
      shape: 'circle', // Marker şekli (daire)
    },
  });

  const [series, setSeries] = useState([
    {
      data: ['100%', '80%', '50%', '20%'],
      color: 'gray',
    },
  ]);

  const [options, setOptions] = useState({
    chart: {
      type: 'bar',
      height: 100,
    },

    plotOptions: {
      bar: {
        borderRadius: 4,
        horizontal: true,
      },
    },
    dataLabels: {
      enabled: false,
    },
    xaxis: {
      categories: ['Abd', 'Canada', 'İngiltere', 'Almanya'],
    },
  });

  const [allDatas, setAllDatas] = useState([]);
  const [year, setYear] = useState(new Date().getFullYear());
  const [monthlyData, setMonthlyData] = useState({});

  const getYearOptions = () => {
    const currentYear = new Date().getFullYear();
    const options = [];
    for (let i = 0; i < 5; i++) {
      options.push(currentYear - i);
    }
    return options;
  };

  const [location, setLocation] = React.useState('');
  const handleChange = (event) => {
    // console.log("event", event.target.value);
    setLocation(event.target.value);
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <>
      <Helmet>
        <title> Emanet Dolap Raporu | Mall Logistics Panel </title>
      </Helmet>

      <Stack style={{ paddingInline: '25px' }}>
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={5}
        >
          <Typography variant="h4" gutterBottom>
            Aylık Toplam Fiyat
          </Typography>
        </Stack>

        <Card>
          <Stack
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            paddingY={2}
            paddingX={1}
            spacing={2}
          >
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <FormControl sx={{ minWidth: 250 }}>
                <InputLabel id="demo-select-small-label">Lokasyon</InputLabel>
                <Select
                  sx={{ marginRight: '8px' }}
                  value={location}
                  label="Lokasyon"
                  onChange={handleChange}
                >
                  {malls.map((e) => (
                    <MenuItem key={e.code} value={e.code}>
                      {e.title}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              <DatePicker
                sx={{ marginRight: '8px' }}
                format="DD/MM/YYYY"
                label="Başlangıç Tarihi"
              />
              <DatePicker
                sx={{ marginRight: '8px' }}
                format="DD/MM/YYYY"
                label="Bitiş Tarihi"
              />
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginBottom: 15,
                  flex: 1,
                }}
              >
                <Button
                  style={{
                    fontSize: '20px',
                    color: 'black',
                  }}
                  id="fade-button"
                  aria-controls={open ? 'fade-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  onClick={handleClick}
                >
                  ...
                </Button>
                <Menu
                  id="fade-menu"
                  MenuListProps={{
                    'aria-labelledby': 'fade-button',
                  }}
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  TransitionComponent={Fade}
                >
                  <MenuItem onClick={handleClose}>Profile</MenuItem>
                  <MenuItem onClick={handleClose}>My account</MenuItem>
                  <MenuItem onClick={handleClose}>Logout</MenuItem>
                </Menu>
              </div>
            </LocalizationProvider>
          </Stack>
        </Card>
        <Box sx={{ height: 25 }} />

        <Grid container spacing={2}>
          <Grid item xs={9}>
            <Grid container spacing={2}>
              <Grid item xs={6}>
                <Card>
                  <Grid
                    container
                    marginTop={4}
                    margin={2}
                    alignItems={'center'}
                  >
                    <Grid item xs={10}>
                      <p>Toplam Kiralama Sayısı</p>
                      <h4>29,800</h4>
                    </Grid>

                    <Grid item xs={2}>
                      <img src="/assets/icons/icon-as.png" />
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item xs={12}>
                      <hr style={{ margin: '0' }} />
                    </Grid>
                    <Grid item xs={8} margin={2}>
                      <div style={{ display: 'flex', gap: '7px' }}>
                        <img src="/assets/icons/up-icon.png" />
                        <span className="text-success">18%</span>
                        <span className="text-muted">Geçen Aya göre</span>
                      </div>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card>
                  <Grid
                    container
                    marginTop={4}
                    margin={2}
                    alignItems={'center'}
                  >
                    <Grid item xs={10}>
                      <p>Toplam Kiralama Sayısı</p>
                      <h4>29,800</h4>
                    </Grid>

                    <Grid item xs={2}>
                      <img src="/assets/icons/icon-os.png" />
                    </Grid>
                  </Grid>

                  <Grid container>
                    <Grid item xs={12}>
                      <hr style={{ margin: '0' }} />
                    </Grid>
                    <Grid item xs={8} margin={2}>
                      <div style={{ display: 'flex', gap: '7px' }}>
                        <img src="/assets/icons/up-icon.png" />
                        <span className="text-success">8%</span>
                        <span className="text-muted">Geçen Aya göre</span>
                      </div>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>

              <Grid item xs={12}>
                <Card>
                  <ReactApexChart
                    options={optionsTwo}
                    series={seriesTwo}
                    type="line"
                    height={350}
                  />
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card>
                  <ReactApexChart
                    options={optionsThree}
                    series={seriesThree}
                    type="bar"
                    height={500}
                  />
                </Card>
              </Grid>
              <Grid item xs={6}>
                <Card>
                  <Stack direction="row">
                    <Grid container alignItems={'center'}>
                      <Grid item xs={9} padding={2}>
                        <span className="text-muted fs-sm">İstatistikler</span>
                        <h6>Kullanımda / Müsait</h6>
                      </Grid>

                      <Grid item xs={3}>
                        <span className="text-muted fs-sm">Bu Hafta</span>
                        <h6>2,684</h6>
                      </Grid>

                      <Grid item xs={12}>
                        <AppCurrentVisits
                          className="bt-lr-1"
                          title=""
                          chartData={[
                            { label: 'Kasım', value: 50 },
                            { label: 'Aralık', value: 25 },
                            { label: 'Ocak', value: 22 },
                            { label: 'Şubat', value: 20 },
                          ]}
                          chartColors={[
                            '#4A3AFF',
                            '#0666f6',
                            '#C6D2FD',
                            '#93AAFD',
                          ]}
                        />
                      </Grid>
                    </Grid>
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </Grid>

          <Grid item xs={3}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Card className="p-3">
                  <Stack>
                    <span className="text-muted">İstatistikler</span>
                    <h6>Populer Ülkeler</h6>
                    <ReactApexChart
                      className="country-spec"
                      options={options}
                      series={series}
                      type="bar"
                      height={140}
                    />
                  </Stack>
                </Card>
              </Grid>

              <Grid item xs={12}>
                <Card>
                  <Stack direction="row">
                    <Grid container alignItems={'center'}>
                      <Grid item xs={9} padding={2}>
                        <span className="text-muted fs-sm">İstatistikler</span>
                        <h6>Kullanımda / Müsait</h6>
                      </Grid>

                      <Grid item xs={3}>
                        <span className="text-muted fs-sm">Bu Hafta</span>
                        <h6>2,684</h6>
                      </Grid>

                      <Grid item xs={12}>
                        <AppCurrentVisits
                          className="bt-lr-1"
                          title=""
                          chartData={[
                            { label: 'Müsait', value: 2611 },
                            { label: 'Kullanımda', value: 3421 },
                          ]}
                          chartColors={['#c6d2fd', '#0666f6']}
                        />
                      </Grid>
                    </Grid>
                  </Stack>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Stack>
    </>
  );
}
